import React from 'react';
import './homeInsight.css';
import images from '../../constants/images';
import { Link } from 'react-router-dom';

// Function to get a random element from the array
const getRandomInsight = (insights) => {
  const randomIndex = Math.floor(Math.random() * insights.length);
  return insights[randomIndex];
};

// Function to truncate the title to the first specified number of characters
const truncateTitleByCharacters = (title, charCount) => {
  return title.length > charCount ? title.slice(0, charCount) + "..." : title;
};

const Homeinsight_notheading = () => {
  // Define the array of insights
  const insight = [
    {
      title: "5 Design Trends That Will Revolutionise Your Mobile App in 2024",
      content: "In today's competitive marketplace, standing out from the crowd is crucial. This is where a strong brand identity comes in. It's the foundation of your marketing efforts, shaping how customers perceive your business and influencing their decisions.",
      link: "/5-Design-Trends-That-Will-Revolutionise-Your-Mobile-App-in-2024",
      img:images.Revolutionise_Mobile_app
    },
    {
      title: "The ROI of a Strong Brand Identity: Why Invest in Design (The Marriage of Branding and Marketing)",
      content: "The mobile app landscape is constantly evolving, with new technologies and user expectations shaping how we design and interact with apps. To stay ahead of the curve and create an app that truly resonates with users, it's crucial to understand the design trends that will dominate 2024.",
      link: "/The-ROI-of-a-Strong-Brand-Identity:-Why-Invest-in-Design(The-Marriage-of-Branding-and-Marketing)",
      img:images.Strong_Brand_Identity
    },
    {
      title: "Data-Driven Marketing: Unleashing Analytics for Marketing Automation Powerhouse",
      content: "In today's digital marketing landscape, data is king. But simply collecting data isn't enough. To thrive, businesses need to leverage marketing automation and analytics to transform data into actionable insights that drive impactful campaigns.",
      link: "/Data-Driven-Marketing:-Unleashing-Analytics-for-Marketing-Automation-Powerhouse",
      img:images.Data_Driven_Marketing

    },
    {
      title: "Building a Seamless User Experience: The Unsung Heroes - QA & Testing",
      content: "In today's digital world, first impressions are everything. When it comes to websites, apps, and software, users form an opinion within seconds of interacting with your product.",
      link: "/Building-a-Seamless-User-Experience:-The-Unsung-Heroes-QA-&-Testing",
      img:images.User_Experience
    },
    {
      title: "From Vision to Reality: Unveiling the Software Development Process",
      content: "Have you ever dreamt of a software solution that could revolutionise your business or streamline your daily tasks? Perhaps you have an idea for a mobile app that fills a gap in the market or a web platform that enhances communication and collaboration within your organisation.",
      link: "/From-Vision-to-Reality:-Unveiling-the-Software-Development-Proces",
      img:images.Software_Development_Process
    },
    {
      title: "The Future of Work: A Tech-Powered Transformation of the Business Landscape",
      content: "The future of work is here, and it's driven by a tidal wave of technological advancements. From artificial intelligence (AI) and automation to remote work opportunities and the rise of the gig economy, technology is reshaping the business landscape at an unprecedented pace.",
      link: "/The-Future-of-Work:-A-Tech-Powered-Transformation-of-the-Business-Landscape",
      img:images.Tech_Powered_Transformation
    }
  ];

  // Get a random insight for the main card
  const randomInsight = getRandomInsight(insight);

  // Get random insights for the small cards (ensure they are different from the main card)
  const remainingInsights = insight.filter(item => item !== randomInsight);
  const randomSmallInsight1 = getRandomInsight(remainingInsights);
  const randomSmallInsight2 = getRandomInsight(remainingInsights.filter(item => item !== randomSmallInsight1));

  return (
    <div className='homeinsight_card_div' style={{ marginTop: "2rem" }}>
      <Link to={randomInsight.link} className='homeinsight_card_l1 black_shadow'>

        <div className='HI_l_img_div'> 
          <img src={randomInsight.img} alt="" className='HI_l_img' />
        </div>
        <div className='HI_l_del_div'>
          <div>
            <h3 className='HI_title' style={{ color: "white" }}>{randomInsight.title}</h3>
            <p className='HI_des' style={{ color: "white" }}>{randomInsight.content}</p>
          </div>
          <p className='HI_date' style={{ color: "white" }}>11 March 2023</p>
        </div>

      </Link>
      <div className='homeinsight_card_r1'>
        <Link to={randomSmallInsight1.link} className='HI_r_card_div black_shadow1'>
          <img src={randomSmallInsight1.img} alt="" className='HI_r_img' />
          <div className="text-overlay" style={{ color: "white" }}>
            {truncateTitleByCharacters(randomSmallInsight1.title, 25)}
          </div>
        </Link>
        <Link to={randomSmallInsight2.link} className='HI_r_card_div black_shadow1'>
          <img src={randomSmallInsight2.img} alt="" className='HI_r_img' />
          <div className="text-overlay" style={{ color: "white" }}>
            {truncateTitleByCharacters(randomSmallInsight2.title, 25)}
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Homeinsight_notheading;

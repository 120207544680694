import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const PerformanceMarketing = () => {
    const imagesui = images.Performance_Marketing;
    const hashtagall = [
        { hashtagname: "#PerformanceMarketing" },
        { hashtagname: "#DigitalMarketing" },
        { hashtagname: "#OnlineAdvertising" },
        { hashtagname: "#ROI" },
        { hashtagname: "#ConversionRate" },
        { hashtagname: "#PPC" },
        { hashtagname: "#MarketingStrategy" },
    ];
    const accordions = [
        { question: "What is performance marketing and how does it differ from traditional marketing?", answer: "Performance marketing focuses on measurable results and ROI. You only pay for actions taken, such as clicks, conversions, or leads generated. Traditional marketing may not provide such direct attribution of results." },
        { question: "What performance marketing channels do you offer?", answer: "We have expertise in various channels, including Search Engine Marketing (SEM), Pay-Per-Click (PPC) advertising, Social Media Advertising, Display Advertising, and Affiliate Marketing. We'll recommend the best mix of channels to achieve your specific goals." },
        { question: "How do you optimize landing pages for conversions?", answer: "We design and A/B test landing pages to ensure they are user-friendly, informative, and strategically designed to convert visitors into leads or customers." },
        { question: "What is attribution modeling and why is it important?", answer: "Attribution modeling helps us understand the customer journey and pinpoint which touchpoints (website visits, ads, social media interactions) contribute most to conversions. This allows us to optimize campaigns and allocate budgets effectively." },
        { question: "How will I be kept informed about the performance of my campaigns?", answer: "We provide transparent reporting with key metrics like clicks, impressions, conversions, and ROI. We regularly analyze data and discuss insights with you to ensure your campaigns are on track." },
        { question: "Can you help me scale my successful performance marketing campaigns?", answer: "Absolutely! We use data-driven insights to identify what works best and scale your campaigns for maximum impact and reach." },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            { title: "Campaign Goal Setting & Target Audience Definition", op: "+", content: "Establish clear campaign goals and identify the target audience.", isOpen: false },
            { title: "Performance Marketing Channel Selection & Budget Allocation", op: "+", content: "Choose the best channels and allocate budget effectively.", isOpen: false },
            { title: "Landing Page Optimization & Conversion Tracking", op: "+", content: "Optimize landing pages and set up conversion tracking for accuracy.", isOpen: false },
            { title: "Campaign Creative Development & A/B Testing", op: "+", content: "Create compelling campaign content and conduct A/B testing.", isOpen: false },
            { title: "Campaign Launch & Real-Time Performance Monitoring", op: "+", content: "Launch campaigns and monitor their performance in real-time.", isOpen: false },
            { title: "Data Analysis & Attribution Modeling", op: "+", content: "Analyze campaign data and use attribution modeling to understand impact.", isOpen: false },
            { title: "Campaign Optimization & Budget Adjustments", op: "+", content: "Continuously optimize campaigns and adjust budgets for better results.", isOpen: false },
            { title: "Performance Reporting & Insights", op: "+", content: "Generate detailed performance reports and actionable insights.", isOpen: false },
            { title: "Continuous Performance Improvement", op: "+", content: "Implement strategies for ongoing improvement of campaign performance.", isOpen: false }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Performance Marketing" />
            <div className="paras4"><h1>
                Marketing shouldn't be a guessing game. At Binary Geckos, we're your performance marketing champions. We craft data-driven campaigns that deliver measurable results, maximizing your return on investment (ROI) and ensuring every marketing dollar climbs towards success.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step performance marketing process:" hashtagall={hashtagall} />
            {/* <Service2_Boost /> */}
            <Casestudy />
            <ServiceBranding title1="Beyond the Click: We Climb to Results that Drive ROI."
             title2="Measurable Marketing Success: Binary Geckos Gets You the Results You Need."
             image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default PerformanceMarketing
import React, { useEffect } from 'react'
import Service2Sec3 from '../../container/Service2Sec3/Service2Sec3'
import Service2_Whatwedo from '../../container/Service2Whatwedo/Service2_Whatwedo'
import Casestudy from '../../container/casestudy/Casestudy'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import { useNavigate } from 'react-router-dom'
import images from '../../constants/images'
import Technology_banner from '../../container/Technology_ser_banner/Technology_banner'
// import Service2UiUx1 from '../../container/Service2UIUX1/Service2UiUx1'

const ServiceTechnology = () => {
    const imagesui = images.Technologybanner;
    const accordions = [
        {
            question: "What services does Binary Geckos offer?",
            answer: "Binary Geckos provides a comprehensive range of technology services, including software development, cloud computing, cybersecurity, data analytics, IT consulting, artificial intelligence, machine learning, IoT solutions, blockchain technology, and digital transformation."
        },
        {
            question: " How can Binary Geckos help my business with digital transformation?",
            answer: "Our digital transformation services include modernizing your business processes with the latest technologies, enhancing productivity, and providing change management support to ensure a smooth transition. We tailor our strategies to meet your specific business needs and goals."
        },
        {
            question: " What makes Binary Geckos' cybersecurity solutions different?",
            answer: "Binary Geckos offers comprehensive security assessments, advanced security protocols, continuous monitoring, and support to safeguard your digital assets. Our team stays ahead of emerging threats to provide robust protection for your business."
        },
        {
            question: " How does Binary Geckos approach custom software development?",
            answer: "We use agile development methodologies to deliver custom software solutions tailored to your specific business needs. Our team has expertise in various programming languages and frameworks, ensuring efficient and timely project delivery."
        },
        {
            question: " Can Binary Geckos assist with cloud migration?",
            answer: "Yes, Binary Geckos offers scalable cloud solutions and cloud migration services. We ensure a seamless transition of your existing infrastructure to the cloud, with robust security measures to protect your data in the cloud environment."
        },
        {
            question: " What industries does Binary Geckos serve?",
            answer: "Binary Geckos serves a diverse range of industries, providing technology solutions that are customized to meet the unique needs of each sector. Whether you're in finance, healthcare, manufacturing, retail, or any other industry, we have the expertise to support your business."
        },
    ];

    const navigate = useNavigate();
    const whatwecard = [
        {
            imgSrc: images.service2_card_img, title: "Data & Analytics", des: "We transform data into actionable insights, empowering you to make data-driven decisions and optimize your digital strategy.",
            path: "/DataAnalytics"
        },
        {
            imgSrc: images.service2_card_img, title: "Web Development", des: "We craft beautiful and functional websites that are secure, scalable, and deliver exceptional user experiences.",
            path: "/WebDevelopment"
        },
        {
            imgSrc: images.service2_card_img, title: "Mobile App Development", des: "We build cutting-edge mobile apps that engage your users and drive business growth.",
            path: "/MobileAppDevelopment"
        },
        {
            imgSrc: images.service2_card_img, title: "Quality Assurance & Testing", des: "We ensure your software is flawless and delivers a seamless user experience across all platforms.",
            path: "/QualityAssurance&Testing"
        },
        {
            imgSrc: images.service2_card_img, title: "Software Solutions", des: "We develop custom software solutions that solve your unique business challenges and streamline your operations.",
            path: "/SoftwareSolutions"
        },
    ];
    const handleCardClick = (path) => {
        navigate(path);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Technology_banner />
            <Service2Sec3 titleservice="Binary Geckos offers cutting-edge technology services tailored to optimize your business operations. From software development to cybersecurity and AI, our solutions are crafted to drive efficiency and innovation. Partner with us to unlock your business's full potential in the digital era." />
            <Service2_Whatwedo whatwecard={whatwecard} handleCardClick={handleCardClick} details_whatwedo="Explore Binary Geckos' services for innovative solutions that drive success and foster innovation. We offer more than technology; we shape the future."/>
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Unlocking Insights: Binary Geckos Transforms Data into Action." title2="Data Speaks Volumes: Let Binary Geckos Tell Your Success Story." image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default ServiceTechnology
import React, { useEffect } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Service_4.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'

const Service_4 = () => {
  useEffect(() => {
    window.scrollTo({top: 0,behavior: "smooth"})
   }, [])
  return (
    <div>
        <Service4_banner />
        <div className="paras4"><h1>
        In today's digital landscape, a strong presence isn't enough.  At Binary Geckos, we're your digital marketing strategists, crafting data-driven campaigns that propel your brand to new heights. We climb the digital ladder rung by rung, reaching your target audience, igniting engagement, and driving real results for your business.
          </h1>
        </div>
        <Oursevices_s4 />
        <Service2_Boost />
        <Casestudy />
        <ServiceBranding />
        <ServiceQuestion />

    </div>
  )
}

export default Service_4
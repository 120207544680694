import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./careerlife.css";

import images from '../../constants/images';

const CareerLife = () => {
    const sliderImg = [
        images.life1,
        images.life2,
        images.life3,
        images.life4,
        images.life5,
        images.life6,
        images.life7,
        images.life8,
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4.8,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows:false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
             
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='CL_main_wrapp'>
            {/* desktop view */}
            <div className='CL_base_wrapp'>
                <div className='CL_title1'>
                    <h1>Life at</h1>
                </div>
                <div className='CL_slider_div'>
                    <Slider {...settings} className='CL_slider'>
                        {sliderImg.map((image, index) => (
                            <div key={index} className={`CL_slide ${index % 2 === 0 ? 'top' : 'bottom'}`}>
                                <img src={image} alt={`Image ${index}`} className='CL_slider_image' />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className='CL_title2'> 
                    <h1>Binary Geckos</h1>
                </div>
            </div>
            {/* mobile view */}
            <div className='CL_base_wrapp_mob'>
                <div className='CL_title1'>
                    <h1>Life at <br /> Binary Geckos</h1>
                </div>
                <div className='CL_slider_div'>
                    <Slider {...settings} className='CL_slider'>
                        {sliderImg.map((image, index) => (
                            <div key={index} className={`CL_slide ${index % 2 === 0 ? 'top' : 'bottom'}`}>
                                <img src={image} alt={`Image ${index}`} className='CL_slider_image' />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

        </div>
    );
}

export default CareerLife;

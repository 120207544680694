import React, { useEffect, useRef, useState } from 'react'
import ContactBanner from '../../container/ContactUsBanner/ContactBanner'
import ContactForm from '../../container/ContactUsForm/ContactForm'
import ContactMethod from '../../container/ContactUSMethod/ContactMethod'
import ContactLocation from '../../container/ContactUsLocation/ContactLocation'
import { useGlobalState } from '../../GlobalStateContext'

const ContactUsPage = () => {
  const sectionRef = useRef(null);
  const [scrollToSection, setScrollToSection] = useState(false);

  const { scrollPosition } = useGlobalState();
  useEffect(() => {
    window.scrollTo({top: 0,behavior: "instant"})
   }, [])
   

  // Example of accessing and modifying global state
  
  useEffect(() => {
    // Set scrollToSection to true after component mounts
    setScrollToSection(true);

    console.log('scrollPosition',scrollPosition);
  }, []);

  useEffect(() => {
    // Scroll to the section when scrollToSection becomes true
    if (scrollPosition == 1 && scrollToSection && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollPosition, scrollToSection]);
  return (
    <div>
        <ContactBanner/>
        <div ref={sectionRef}></div>
        <ContactForm scrollPosition={scrollPosition}/>
        <ContactMethod/>
        <ContactLocation/>
    </div>
  )
}

export default ContactUsPage
import React, { useState } from 'react';
import './Casestudy.css';
import images from '../../constants/images';
import { Navigate, useNavigate } from 'react-router-dom';

const Casestudy = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const navigate = useNavigate();

    const handleComingsoon = (path) => {
        navigate(path);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalImage('');
    };
    const handleNavigation = (path) => {
        navigate(path); // Navigate to the specified path
    };

    return (
        <div className="casestudy">
            <div className='Casestudy_main'>
                <h2>Case Study</h2>
                <h1>Explore our case studies to see how we've transformed digital experiences. Discover our design process, impactful results, <br /> & the value we bring to businesses through our successful projects.</h1>
                <div className="cardsection_main">
                    <div className="casecard1" onClick={() => handleNavigation('/USAInternCasestudy')}>
                        <div className="cardtop">
                            <h6>CASE STUDY </h6>
                            <h3>USA INTERN</h3>
                        </div>
                            <img src={images.USAIntern_pro} alt="USA Intern Case Study" />
                        <div className="cardbottom">
                            {/* <h6>CLIENT</h6>*/}
                            <h6>Know More</h6> 
                            <img src={images.service_arrow} className='right_case' alt="" /> 
                        </div>
                    </div>
                    <div className="casecard1" onClick={() => handleComingsoon('/ComingSoon')}>
                        <div className="cardtop">
                            <h6>CASE STUDY </h6>
                            <h3>RAREREZ.COM</h3>
                        </div>
                        <div className="cardbottom">
                            {/* <h6>CLIENT</h6>
                            <h3>XYZ</h3> */}
                            <h6>Know More</h6> 
                            <img src={images.service_arrow} className='right_case' alt="" /> 
                        </div>
                        <img src={images.Rarerez_pro} alt="Rarerez.com Case Study" />
                    </div>
                    <div className="casecard1" onClick={() => handleComingsoon('/ComingSoon')}>
                        <div className="cardtop">
                            <h6>CASE STUDY </h6>
                            <h3>TEKNIKA</h3>
                        </div>
                        <div className="cardbottom">
                            {/* <h6>CLIENT</h6>
                            <h3>XYZ</h3> */}
                            <h6>Know More</h6>
                            <img src={images.service_arrow} className='right_case' alt="" /> 
                        </div>
                        <img src={images.teknika_pro} alt="Teknika Case Study" />
                    </div>
                    <div className="casecard1" onClick={() => handleComingsoon('/ComingSoon')}>
                        <div className="cardtop">
                            <h6>CASE STUDY </h6>
                            <h3>DUSTIT</h3>
                        </div>
                        <div className="cardbottom">
                            {/* <h6>CLIENT</h6>
                            <h3>XYZ</h3> */}
                            <h6>Know More</h6> 
                            <img src={images.service_arrow} className='right_case' alt="" /> 
                        </div>
                        
                        <img src={images.dustitbannercase} alt="Dustit Case Study" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Casestudy;

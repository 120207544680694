import React from 'react'
import "./Footer.css"
import images from '../../constants/images'
import { Link } from 'react-router-dom'

const Footer = () => {
    const openEmailClient = () => {
        window.location.href = 'mailto:info@binarygeckos.com';
    };
    const handlePhoneClick = () => {
        // Navigate to the dial pad with the specified phone number
        window.location.href = "tel:+919037825996";
    };
    const handleLocationClick = () => {
        // Construct the Google Maps URL
        const googleMapsUrl = "https://www.google.com/maps/place/Binary+Geckos/@22.3171819,70.7674985,15z/data=!4m6!3m5!1s0x3959c97ccc37a431:0xa1d9ba543b059bb4!8m2!3d22.3171819!4d70.7674985!16s%2Fg%2F11lkjh2r91?entry=ttu";

        // Redirect to the Google Maps URL
        window.open(googleMapsUrl, '_blank');
    };

    return (
        <div className='footer_main'>
            <div className='footer_base'>
                <div className="footer_row1">
                    <div className="logo_sec">
                        <Link to="/" >
                            <img src={images.bg_logo} alt="" className='footer_logo' />
                        </Link>
                    </div>
                    <div className="stayconnected">
                        <div className="social">
                            <p>Stay Connected</p>
                            <Link to={"https://www.facebook.com/binarygeckos"} target='_blank'>
                                <img src={images.fb} alt="" />
                            </Link>
                            <Link to={"https://www.instagram.com/binarygeckos/"} target='_blank'>
                                <img src={images.twitter} alt="" />
                            </Link>
                            <Link to={"https://www.linkedin.com/company/binary-geckos"} target='_blank'>
                                <img src={images.linkdin} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="footer_row2">
                    <div className="logo_sec2">
                        <div className="emial" onClick={openEmailClient}>
                            <img src={images.mail} alt="" />
                            <p>info@binarygeckos.com</p>
                        </div>
                        <div className="emial" onClick={handlePhoneClick}>
                            <img src={images.calll} alt="" />
                            <p>+91 9037825996</p>
                        </div>
                        <div className="emial" onClick={handleLocationClick}>
                            <img src={images.loc} alt="" />
                            <p>Rajkot, Gujarat</p>
                        </div>
                    </div>
                    <div className="stayconnected2">
                        © 2024 BinaryGeckos. All rights reserved.
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer
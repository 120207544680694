import React from 'react'
import "./Aboutcorevalues.css"
import images from '../../constants/images'
import { Link } from 'react-router-dom'

const AboutCorevalues = () => {
  return (
    <div className='about_coreval_main_wrapp'>
        <div className='about_coreval_base_wrapp'>
            <div className='about_core_title_div'>
                <h1>How We Work</h1>
                <hr />
            </div>
            <div className='about_core_card_wrapp'>
                <div className='about_core_card_div'>
                    <div className='about_core_card1'>
                        <h1>Project Kickoff</h1>
                    </div>
                    <div className='about_core_card1'>
                        <h1>Discover</h1>
                    </div>
                    <div className='about_core_card1'>
                        <h1>Design</h1>
                    </div>
                    <div className='about_core_card1'>
                        <h1>Implementation</h1>
                    </div>
                    <div className='about_core_card1_last'>
                        <Link to="/Projects">
                        <img src={images.about_core} alt="" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutCorevalues
import React, { Suspense, useEffect } from 'react'
import Projects_main from '../../container/Project_anim/Projects_main'
// import Project_slider from '../../container/Project_slider/Project_slider'

const Project_slider = React.lazy(() => import("../../container/Project_slider/Project_slider"))

const Projects = () => {
  useEffect(() => {
    window.scrollTo({top: 0,behavior: "instant"})
   }, [])
   
  return (
    <div>

        <Projects_main />
      <Suspense fallback={<></>}>
        <Project_slider />
      </Suspense> 
    </div>
  )
}

export default Projects
import React, { useState, useEffect, useRef } from 'react';
import './technology_banner.css';
import images from '../../constants/images';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import styles from "./technology_banner.css"

const Technology_banner = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  const bannerRef = useRef(null);
  const nextSectionRef = useRef(null);
  const parallaxRef = useRef(null);


  useEffect(() => {
    const handleParallaxScroll = () => {
      if (parallaxRef.current) {
        const currentOffset = parallaxRef.current.current / parallaxRef.current.space;
        if (currentOffset >= 1 && !isZoomed) {
          setIsZoomed(true);
          setTimeout(() => {
            nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 2000); // Delay before moving to the next section
        }
      }
    };

    const parallaxElement = parallaxRef.current.container.current;
    parallaxElement.addEventListener('scroll', handleParallaxScroll);

    return () => {
      parallaxElement.removeEventListener('scroll', handleParallaxScroll);
    };
  }, [isZoomed]);

  const alignCenter = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

  return (
    <>
      <div className='Design_banner_main_wrapp' ref={bannerRef}>
        <div className='design_blueimg_div'>
          <div className={`d_black_overlay ${isZoomed ? 'zoomed' : ''}`}>
            <div className='design_content_div'>
              <div className='main_wrapp_bd'>
                <div className='bd_main_wrapp'>
                  <Parallax pages={2} className='bd_base_wrapp' ref={parallaxRef}>
                    <ParallaxLayer
                      offset={0}
                      speed={2.5}
                      style={{
                        ...alignCenter,
                        justifyContent: 'center',
                        border: '1px solid black',
                        height: '204px',
                      }}
                      className='bd-parallexLayer-non'
                    >
                      <div className='nonsticky-dream'>
                        <p className='nonsticky-number'>BUILD</p>
                        <br />
                      </div>
                    </ParallaxLayer>
                    <ParallaxLayer
                      offset={1}
                      speed={2.5}
                      style={{ ...alignCenter, justifyContent: 'center', height: '204px' }}
                      className='bd-parallexLayer-non'
                    >
                      <div className='nonsticky-dream'>
                        <p className='nonsticky-number'>TECHNOLOGY</p>
                        <br />
                      </div>
                    </ParallaxLayer>
                  </Parallax>
                </div>
              </div>
              <p className='stickyp'>We helps Startups & business to build a great User experience for the world </p>
            </div>
          </div>
          <img src={images.Design_border} alt='Design border' />
        </div>
      </div>
      <div className='next_section' ref={nextSectionRef}>
        {/* Next section content */}
      </div>
      <div className='Design_banner_main_wrapp_mob'>
          <div className='Design_banner_base_wrapp_mob'>
                      <h1>BUILD</h1>
                      <h1>TECHNOLOGY</h1>
                      <p>We helps Startups & business to build a great User experience for the world</p>
          </div>
      </div>
    </>
  );
};

export default Technology_banner;

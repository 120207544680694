import React from 'react'
import "./ContactBanner.css"

const ContactBanner = () => {
  return (
    <div className='contact_banner_main_wrapp'>
        <div className='contact_banner_base_wrapp'>
            <h3>Stick with us : </h3>
            <h2>Contact Binary Geckos Today <br />
            Your Digital Adventure Starts Here
            </h2>
        </div>
    </div>
  )
}

export default ContactBanner
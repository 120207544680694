import React from 'react'
import "./cs_result.css"
import images from '../../constants/images'

const CS_Result = ({uxout,uxl1,uxl2,uxl3}) => {
  return (
    <div className='cs_result_main_wrapp'>
       
        <div className="cs_result_base_wrapp">
            <h1>Result <span>of work</span></h1>
            <div className='cs_result_content_div'>
                <div className="cs_result_content_left">
                    <div className='cs_result_circle_div'>
                        <img src={images.CS_About} alt="" className='half-circle'/>
                    </div>
                    <div className='cs_result_blk_div'>
                        <h2>UI/UX Outcomes</h2><br />
                        <p>{uxout}</p><br />
                        <ul>
                            <li>{uxl1}</li>
                            <li>{uxl2}</li>
                            <li>{uxl3}</li>
                        </ul>
                    </div>
                </div>
                <div className="cs_result_content_right">
                    <div className='cs_result_blk_div_right'>
                        <div className='cs_result_left_item'>
                            <div className='rs_easy'>Easy to use</div>
                            <div className='rs_user'>User friendly</div>
                            <div className='rs_intuitive'>Intuitive interface</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cs_result_2nd_div'>
                <img src={images.cs_result} alt="" className='cs_result_full_circle'/>
                <img src={images.usa_5th_img} alt="" className='cs_result_mob' />
            </div>
            <div className='cs_result_3rd_div'>
                <div className='rs_new1'>2+ &nbsp; designers worked on the project </div>
                <div className='rs_new2'>50+ &nbsp; screens were created</div>
                <div className='rs_new3'>65+ &nbsp; hours were invested</div>
                <div className='rs_new4'>2+ &nbsp; weeks were spent</div>
                <div className='rs_new5'>10+ &nbsp; flows were built</div>
            </div>
            <div className='result_btm_border'>
            </div>
            <div className='cs_result_thanks'>
                <h1>THANKS</h1>
            </div>
        </div>
    </div>
  )
}

export default CS_Result
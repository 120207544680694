import React from 'react'
import "./CS_userpersonal.css"
import images from '../../constants/images'

const CS_UserPersonal = ({age,name,occupation,frustrations,goals,needs}) => {
  return (
    <div className='cs_userpersonal_main_wrapp'>
        <div className='cs_userpersonal_base_wrapp'>
            <div className='cs_up_cards_div'>
                <div className='cs_up_card_left'>
                    <h2>User <span>persona</span></h2>
                    <div className='cs_up_left'>
                        <div className='cs_up_left_item'>
                            <div className='up_age'>Age : {age}</div>
                            <div className='up_name'>Name : {name}</div>
                            <div className='up_occupation'>Occupation : {occupation} </div>
                        </div>
                    
                        <div className='cs_up_black_div'>
                            <div>
                                <h1>Frustrations</h1>
                                <p>{frustrations}</p>
                            </div>
                            <div>
                                <h1>Goals</h1>
                                <p>{goals}</p>
                            </div>
                            {needs && (
                            <div>
                                <h1>Needs</h1>
                                <p>{needs}</p>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='cs_up_cards_right'>
                    <img src={images.cs_user_img} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default CS_UserPersonal
import React from 'react'
import "./Ready.css"
import { Link } from 'react-router-dom'

const Ready_about = () => {
  return (
    <div className='home_boder_main_wrapp'>
      <div className='borderr'>
          <h1>Ready to evolve with <br />
              Binary Geckos?</h1>
          <p>Let's climb to new heights together!</p>
          <Link to="/ContactUs">
          <button className="contactustoday-button">
              {/* Lets Talk */}
          </button>
          </Link>
      </div>
    </div>
  )
}

export default Ready_about
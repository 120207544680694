import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const GraphicDesign = () => {
    const imagesui = images.Graphic_Design;
    const hashtagall = [
        { hashtagname: "#GraphicDesign" },
        { hashtagname: "#DesignInspiration" },
        { hashtagname: "#CreativeDesign" },
        { hashtagname: "#GraphicDesigner" },
        { hashtagname: "#Artwork" },
        { hashtagname: "#VisualIdentity" },
        { hashtagname: "#Illustration" },
    ];
    // FAQ
    const accordions = [
        { 
            question: "What types of graphic design services do you offer?", 
            answer: "We offer a comprehensive range of graphic design services, including logo design, branding materials, website graphics, social media graphics, presentations, infographics, and more." 
        },
        { 
            question: "Do I need to provide specific design ideas before we start?", 
            answer: "While your input is valuable, we'll also conduct research and brainstorm concepts that align with your brand identity and target audience." 
        },
        { 
            question: "How many revisions are included in the graphic design process?", 
            answer: "We offer a set number of revisions to ensure your satisfaction. We'll work collaboratively to refine the design until it meets your vision." 
        },
        { 
            question: "What file formats will I receive for my graphic design project?", 
            answer: "You'll receive your designs in various file formats suitable for different uses, including vector formats (EPS, AI) for high-resolution printing and web-friendly formats (PNG, JPG) for digital applications." 
        },
        { 
            question: "Can you integrate my graphic design with my brand guidelines?", 
            answer: "Absolutely! We can ensure your graphic design elements adhere to your brand's color palette, typography, and overall visual style." ,
        },
        { 
            question: "How long does the graphic design process take?", 
            answer: "The time frame depends on the project's complexity and revision cycles. However, typical graphic design projects take 1-2 weeks." ,
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Project Brief & Target Audience Analysis", 
                op: "+", 
                content: "We start by gathering a detailed project brief and analyzing your target audience. Understanding your goals and audience helps us tailor the design to effectively communicate your message.", 
                isOpen: false
            },
            {
                title: "Concept Development & Brainstorming", 
                op: "+", 
                content: "Our team engages in creative brainstorming sessions to develop concepts that align with your brand and objectives. We explore various ideas to find the most suitable direction for your project.", 
                isOpen: false
            },
            {
                title: "Mood Board & Visual Style Exploration", 
                op: "+", 
                content: "We create mood boards and explore different visual styles, colors, and typography to establish the overall look and feel of the design. This stage helps set the tone for the project.", 
                isOpen: false
            },
            {
                title: "Design Execution & Iteration",
                 op: "+", 
                 content: "Using the chosen direction, we execute the design while incorporating your feedback along the way. We iterate on the design to ensure it meets your expectations and effectively communicates your message.",
                isOpen: false
            },
            {
                title: "Client Feedback & Refinement", 
                op: "+", 
                content: "We value your input and encourage feedback throughout the process. Your feedback helps us refine the design to ensure it aligns perfectly with your vision and goals.", 
                isOpen: false
            },
            {
                title: "Final Design & File Delivery", 
                op: "+", 
                content: "Once the design is approved, we finalize it and prepare all necessary files for delivery. You'll receive high-quality design files ready for use across various platforms and applications.", 
                isOpen: false
            },
            {
                title: "Project Management & Communication", 
                op: "+", 
                content: "Throughout the project, we maintain clear communication and transparency. Our project management ensures timely delivery and keeps you informed at every stage of the process.", 
                isOpen: false
            },
            
        ])

        const toggleService = (index) => {
            setservice(service.map((service, i) => (
                i === index ? { ...service, isOpen: !service.isOpen,op: service.isOpen ? "+" : "-"  } : service
            )));
        };  

        useEffect(() => {
            window.scrollTo({top: 0,behavior: "instant"})
           }, [])
  return (
    <div>
        <Service4_banner title="Graphic Design"  />
        <div className="paras4"><h1>
        Every great brand has a story to tell. At Binary Geckos, we're your graphic design storytellers. We translate your brand message into compelling visuals that capture attention, ignite emotions, and drive results. 
          </h1>
        </div>
        <Oursevices_s4 service={service} toggleService={toggleService} title="our graphic design odyssey:" hashtagall={hashtagall}/>
        <Service2_Boost />
        <Casestudy />
        <ServiceBranding title1="Beyond the Pixel: We Craft Visuals that Captivate and Convert." 
        title2="See Your Brand Story Come Alive: We Design Graphics that Make an Impact." image={imagesui}/>
        <ServiceQuestion  accordions={accordions}/>

    </div>
  )
}

export default GraphicDesign
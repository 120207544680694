import React from 'react'
import "./careerwhat.css"
import { Link } from 'react-router-dom'

const CareerWhat = ({onViewOpenPositions}) => {
  return (
    <div className='CW_main_wrapp'>
        <div className='CW_base_wrapp'>
            <h1>What it's like to be a Binary Geckos</h1>
            <div className='CW_content_div'>
                <p>We don't just work, we co-create. We believe in fostering a collaborative environment where everyone's voice is heard (even the quiet chirp of a new team member).</p>
                <br />
                <p>We learn as we climb. We're a bunch of curious geckos, constantly seeking new challenges and pushing the boundaries of what's possible.</p>
                <br />
                <p>We celebrate wins (and maybe a few near misses). We work hard, but we also know how to have fun. We believe in celebrating both successes and the lessons learned along the way.</p>
                <br />
                <p>We stick together. We're a close-knit team that supports each other through thick and thin (and maybe the occasional bug infestation in the office... it's a jungle out there!)</p>
            </div>
            
                <button className="clickheretoapply-button" onClick={onViewOpenPositions}>
                    {/* Lets Talk */}
                </button>
            
        </div>
    </div>
  )
}

export default CareerWhat
// JobDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import JobDetailBanner from '../../container/JobDetailBanner/JobDetailBanner';
import JobDetailDescription from '../../container/JobDetailDescription/JobDetailDescription';

const jobDetailsList = [
  {
    id: 'Business-Analyst',
    type: 'Full-Time',
    text: 'Business Analyst',
    location: 'Mumbai, India',
    postedDate: '2024-06-01',
    responsibilities: [
      'Collaborating with various departments and stakeholders in the company to understand the current business scenario, challenges and analytical requirements',
      'Gathering information for business intelligence projects, analysing the same to find any trends/ loopholes that might have an effect on the team’s/ company’s strategies/growth, and providing actionable recommendations based on data analysis.',
      'Creating new SOPs and plans for data collection and analysis.',
      'Refining the system for company-wide reporting of business/performance metrics.',
      'Being a dedicated POC for all the backend teams like Finance, HR, BD and Legal for their designated team/ POD spread across departments (Integrated Solutions, Schbang Motion Pictures, Business Transformation (Seo, Tech, Content, CRM, Marketing Automation) Influencer Marketing, Media. ',
      'You will be working on standalone business intelligence projects assigned to you by the central BI team across different departments in the company.'
    ],
    skills: [
      '1 year experience in data reporting & business analytics',
      'Freshers with an educational background (i.e. graduation) in finance are welcome to apply. ',
      'Robust understanding of MS Excel & experience working in Google Sheets.',
      'Ability to turn large amounts of raw data into actionable information.',
      'Need people with qualifications like BAF, MBA in Finance or Marketing, BBA etc.'
    ],
  },
  {
    id: 'Reactjs',
    type: 'Full-Time',
    text: 'React.js Developer',
    location: 'On-site at our office in Rajkot',
    postedDate: '2024-06-01',
    responsibilities: [
      'Leverage your expertise in React.js to develop dynamic and responsive web applications',
      'Utilize Next.js or similar frameworks to enhance performance, SEO, and developer experience',
      'Collaborate closely with design and backend teams to implement features seamlessly',
      'Optimize applications for speed, scalability, and cross-browser compatibility',
      'Write clean, well-documented, and maintainable code that adheres to best practices',
      'Stay updated with the latest trends and technologies in React and Next.js development',
    ],
    skills: [
      'Proven experience as a React Developer with a strong focus on Next.js or similar frameworks',
      'Proficiency in JavaScript, HTML, CSS, and related web technologies',
      'Deep understanding of React.js and its core principles',
      'Familiarity with server-side rendering and client-side routing in Next.js',
      'Experience with state management libraries like Redux or Context API',
      'Strong problem-solving skills and attention to detail',
      'Ability to work collaboratively in an agile environment',
    ],
  },
  {
    id: 'LaravelDeveloper',
    type: 'Full-Time',
    text: 'Laravel Developer',
    role_job: 'Binary Geckos is seeking an on-site Laravel Developer to create and maintain innovative web applications.',
    location: 'On-site at our office in Rajkot',
    postedDate: '2024-06-01',
    responsibilities: [
      'Develop and maintain PHP applications using Laravel.',
      'Collaborate with UX team and ensure consistent HTML, CSS, and JavaScript.',
      'Manage the full application development life cycle.',
      'Communicate progress and technical issues effectively.',
      'Document processes and maintain project standards.',
      'Stay updated with web development trends.',
    ],
    skills: [
      ' Experience as a PHP Developer with strong Laravel skills.',
      ' Understanding of MVC design patterns and front-end technologies (JavaScript, HTML5, CSS3).',
      ' Knowledge of object-oriented PHP and common PHP exploits.',
      ' Familiarity with SQL/NoSQL databases and Git.',
      ' Degree in Computer Science, Engineering, or related field.',
      ' Previous experience with Laravel development.',
    ],
  },
  {
    id: 'UI-UX',
    type: 'Part-Time',
    text: 'UI/UX',
    location: 'Delhi, India',
    postedDate: '2024-06-01',
    responsibilities: [
      'Plan and execute marketing campaigns',
      'Analyze market trends and competitors',
    ],
    skills: [
      'Strong communication and analytical skills',
      'Experience in digital marketing',
    ],
  },
  // Add more jobs here
];

const JobDetails = () => {
  const { jobId } = useParams(); // Extract jobId from URL
  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    // Simulate fetching job details based on jobId
    const job = jobDetailsList.find((job) => job.id === jobId);
    setJobDetails(job);
  }, [jobId]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  if (!jobDetails) return <p>No job details available</p>; // Handle case where job details are not found
  return (
    <div>
      <JobDetailBanner jobDetails={jobDetails} />
      <JobDetailDescription jobDetails={jobDetails} />
    </div>
  );
};

export default JobDetails;

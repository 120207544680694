import React from 'react'
import "./casestudybanner.css"
import images from '../../constants/images'

const CaseStudyBanner = () => {
  return (
    <div className='Case_banner_main_wrapp'>
        <img src={images.casestudy_banner} alt=""  className='case_banner_back_img'/>
        <div className='case_banner_base_wrapp'>
            <img src={images.usa_intern_logo} alt=""  className='case_banner_logo_img'/>
            <h1>Usa Intern</h1>
            <h2>Website Design & Development</h2>
        </div>
    </div>
  )
}

export default CaseStudyBanner
import React from 'react'
import "./Section1.css"

const Section1 = () => {
  return (
    <div className='section1_mainn'>
        <h1 className='section1_title'>
            Unlock Your <br/>
            IT Potential
        </h1>
        <div className="line1"></div>
        <h1 className='section1_title_2'>
            Dive into Our <br/>
            Expert Blog!
        </h1>
    </div>
  )
}

export default Section1

import React from "react";
import "./HomeHero.css"
import Bg from "../../components/Bg";
import images from "../../constants/images";
import Btn_ani from "./Btn_ani";
import { Link } from "react-router-dom";

const HomeHero = () => {
  return (
    <div className="homehero_main_wrapp">
      <Bg />
      <div className="homehero_text_sec_wrapp">
        <h1>
          We Evolve Your  Digital DNA
        </h1>
        <p className="homehero_text_des">
          Binary Geckos : Where innovation sticks & creativity climbs
        </p>
        <div className="home_hero_contect_btn_wrapp">
          <Link to="/ContactUs" className="hh_btn_link">
            <p>Contact Us </p>
            <Link to="/ContactUs">
              <Btn_ani/>
            </Link>
          </Link>
        </div>
        <div className="homehero_grid_wrapp">
        <div className="homehero_grid_1">
          <img src={images.home_grid_1} alt="" />
        </div>
        <div className="homehero_grid_2">
          <img src={images.home_grid_2} alt="" />
          <p className="homehero_grid_text inovetive_text">Innovative</p>
        </div>

        <div className="homehero_grid_3">
          <img src={images.home_grid_3} alt="" />
          <p className="homehero_grid_text tenacious_text">Tenacious</p>
        </div>
        <div className="homehero_grid_4">
          <img src={images.home_grid_4} alt="" />
        </div>
        <div className="homehero_grid_5">
          <img src={images.home_grid_5} alt="" />
        </div>

        <div className="homehero_grid_6">
          <img src={images.home_grid_6} alt="" />
          <p className="homehero_grid_text strategic_text">Strategic</p>
        </div>
        <div className="homehero_grid_7">
          <img src={images.home_grid_7} alt="" />
        </div>
      </div>
      </div>

      
    </div>
  );
};

export default HomeHero;

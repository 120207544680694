import React from 'react';
import "./service2_whatwedo.css";
import images from '../../constants/images';
import { useNavigate } from 'react-router-dom';

const Service2_Whatwedo = ({whatwecard,handleCardClick, details_whatwedo}) => {
  
    return (
        <div className='ser2_whatwedo_main_wrapp'>
            <div className='ser2_whatwedo_base_wrapp'>
                <h3>What we do</h3>
                <h2>{details_whatwedo}</h2>
            </div>
            <div className='ser2_cards_div'>
                    {whatwecard.map((item, index) => (
                        <div key={index} className='sec2_outer_card' onClick={() => handleCardClick(item.path)}>
                            <div className='ser2_card'>
                                {/* <img src={item.imgSrc} alt={item.title} /> */}
                                <h2>{item.title}</h2>
                                <p>{item.des}</p>
                                <button>Learn More</button>
                            </div> 
                        </div>
                    ))}  
                </div>
        </div>
    );
};

export default Service2_Whatwedo;

import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import images from '../../constants/images';
import './contactuslocation.css';
import ProjectSlider from '../../components/projectslider/ProjectSlider';
import { InView } from 'react-intersection-observer';
import moment from 'moment-timezone';
import sunImage from '../../assets/images/sunnew.png';
import moonImage from '../../assets/images/moonnew.png';

const ContactLocation = () => {
  const contactDetails = [
    {
      id: 1,
      title: "India",
      addtitel: "ADDRESS",
      address: "Office No. 921/922, RK World Tower, Near Shital Park,",
      address2: "150 Feet Ring Road, Rajkot - 360006",
      mailid: "info@binarygeckos.com",
      phno: "+91 740583 5887",
      timezone: "Asia/Kolkata",
      location:"https://www.google.com/maps/place/Binary+Geckos/@22.3171819,70.7674985,15z/data=!4m6!3m5!1s0x3959c97ccc37a431:0xa1d9ba543b059bb4!8m2!3d22.3171819!4d70.7674985!16s%2Fg%2F11lkjh2r91?entry=ttu"
    },
    {
      id: 2,
      title: "Canada",
      addtitel: "ADDRESS",
      address: "415-36 Zorra St, Etobicoke,",
      address2: "ON M8Z 0G5",
      mailid: "info@binarygeckos.com",
      phno: "+1 7785874556",
      timezone: "America/Toronto",
      location:"https://www.google.com/maps/place/36+Zorra+St+%23415,+Etobicoke,+ON+M8Z+0G5,+Canada/@43.6197441,-79.5216896,17z/data=!3m1!4b1!4m5!3m4!1s0x882b37ef85a1a303:0xd8b2425e4018dd4f!8m2!3d43.6197441!4d-79.5216896?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D"
    },
    // {
    //   id: 3,
    //   title: "Houston",
    //   address: "",
    //   mailid: "info@binarygeckos.com",
    //   phno: "+91 740583 5887",
    //   timezone: "America/Chicago",
    //   location:"https://www.google.com/maps/place/Binary+Geckos/@22.3171819,70.7674985,15z/data=!4m6!3m5!1s0x3959c97ccc37a431:0xa1d9ba543b059bb4!8m2!3d22.3171819!4d70.7674985!16s%2Fg%2F11lkjh2r91?entry=ttu"
    // }
  ];

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const getCurrentTime = (timezone) => {
    return moment().tz(timezone).format('HH:mm');
  };

  const isDayTime = (timezone) => {
    const hour = moment().tz(timezone).hour();
    return hour >= 6 && hour < 18; // Daytime is considered from 6 AM to 6 PM
  };

  return (
    <div className='contact_location_main_Wrapp'>
      <img src={images.blue_elips} alt="" className='blue_back_img' />
      <div className='contact_loc_title_div' >
        <ProjectSlider />
      </div>
      <div className='contact_loc_del_div'>
        {contactDetails.map((item, index) => (
          <InView key={item.id} threshold={0.5}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                variants={variants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                custom={index}
                className='contact_loc_del_card'
              >
                <div className='contact_loc_img_div_main'>
                  <div className={`contact_loc_img_div ${isDayTime(item.timezone) ? 'daytime' : 'nighttime'}`}>
                    <img src={isDayTime(item.timezone) ? sunImage : moonImage} alt={isDayTime(item.timezone) ? "Sun" : "Moon"} />
                    <p>{getCurrentTime(item.timezone)}</p>
                  </div>
                </div>
                <div className='contact_loc_address_div'>
                  <h1>{item.title}</h1>
                  <h3>ADDRESS</h3>
                  <p >{item.address}</p>
                  <p>{item.address2}</p>
                </div>
                <div className='contact_loc_contact_div'>
                  <h3>CONTACT</h3>
                  <p>
                    <a href={`mailto:${item.mailid}`}>{item.mailid}</a>
                  </p>
                  <p>
                    <a href={`tel:${item.phno}`}>{item.phno}</a>
                  </p>
                </div>
                <div className='contact_loc_map_div'>
                  <h3>SEE MAP</h3>
                  <Link to={item.location} target='_blank'  className='location_btn'>Location<img src={images.location_arrow} alt="" /> </Link>
                </div>
              </motion.div>
            )}
          </InView>
        ))}
      </div>
    </div>
  );
};

export default ContactLocation;

import React, { useEffect, useRef, useState } from 'react';
import CareerBanner from '../../container/CareerBanner/CareerBanner';
import CareerOpenings from '../../container/CareerOpenings/CareerOpenings';
import CareerWhat from '../../container/CareerWhatitis/CareerWhat';
import CareerLife from '../../container/CareerLifeat/CareerLife';
import CareerWhatWeOffer from '../../container/CareerWhatWeOffer/CareerWhatWeOffer';
import Ready_about from '../../container/Ready/Ready_about';
import { useGlobalState } from '../../GlobalStateContext';

const CareerPage = () => {
  const openingsRef = useRef(null);
  const [scrollToSection, setScrollToSection] = useState(false);
  const { scrollPosition } = useGlobalState();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  const handleScrollToOpenings = () => {
    if (openingsRef.current) {
      const elementPosition = openingsRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 92;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  };

  
  useEffect(() => {
    // Set scrollToSection to true after component mounts
    setScrollToSection(true);

    console.log('scrollPosition',scrollPosition);
  }, []);

  useEffect(() => {
    if (scrollPosition === 1 && scrollToSection && openingsRef.current) {
      const elementPosition = openingsRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 92;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  }, [scrollPosition, scrollToSection]);

  return (
    <div>
      <CareerBanner onViewOpenPositions={handleScrollToOpenings} />
      <div ref={openingsRef}>
        <CareerOpenings  scrollPosition={scrollPosition}/>
      </div>
      <CareerWhat onViewOpenPositions={handleScrollToOpenings}/>
      <CareerLife />
      <CareerWhatWeOffer />
      <Ready_about />
    </div>
  );
};

export default CareerPage;

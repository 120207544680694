import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const Visualization = () => {
    const imagesui = images.Elevate;
    const hashtagall = [
        { hashtagname: "#2DVisualization" },
        { hashtagname: "#3DVisualization" },
        { hashtagname: "#ArchitecturalVisualization" },
        { hashtagname: "#Rendering" },
        { hashtagname: "#3DModeling" },
        { hashtagname: "#VisualizationArt" },
        { hashtagname: "#DesignRender" },
    ];
    // FAQ
    const accordions = [
        { 
            question: "What types of 2D and 3D visualizations do you offer?", 
            answer: "We create a variety of 2D and 3D visuals, including architectural renderings, product visualizations, engineering schematics, explainer animations, and interactive prototypes." 
        },
        { 
            question: "What kind of software do you use?", 
            answer: "We utilise industry-leading software to ensure the highest quality visuals. This may include programs like 3ds Max, Maya, Blender, Photoshop, and After Effects." 
        },
        { 
            question: "Do I need to provide 3D models or technical data?", 
            answer: "Not necessarily. We can create models from scratch or use existing assets depending on the project's requirements." 
        },
        { 
            question: "What file formats will I receive for my visualization project?", 
            answer: "We can deliver your visuals in various formats, including high-resolution images (JPG, PNG), video formats (MP4, MOV), or interactive file formats for specific applications." 
        },
        { 
            question: "Can you animate my visualizations?", 
            answer: "Absolutely! Animation can further enhance your visualization's impact. We offer animation services to create explainer videos, product demonstrations, or interactive presentations." ,
        },
        { 
            question: "How long does the 2D/3D visualization process take?", 
            answer: "The time frame depends on the project's complexity and scope. However, typical visualization projects take 2-4 weeks." ,
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Project Kickoff & Requirements Gathering", 
                op: "+", 
                content: "We start by understanding your project requirements and goals through a detailed kickoff meeting. This phase helps us gather all necessary information to ensure we meet your visualization needs effectively.", 
                isOpen: false
            },
            {
                title: "Concept Development & Storyboarding", 
                op: "+", 
                content: "Based on the requirements, we develop concepts and storyboards to visualize the project's narrative and key elements. This helps outline the direction and structure of the visualization.", 
                isOpen: false
            },
            {
                title: "Content Creation & Asset Acquisition", 
                op: "+", 
                content: "We create and gather necessary content and assets for the visualization, including images, videos, textures, and 3D models, ensuring everything aligns with the project vision.", 
                isOpen: false
            },
            {
                title: "2D/3D Modeling & Environment Building",
                 op: "+", 
                 content: "Using advanced modeling techniques, we create 2D or 3D models of objects, environments, and scenes according to the project requirements, bringing your vision to life.",
                isOpen: false
            },
            {
                title: "Texturing & Material Application", 
                op: "+", 
                content: "We apply textures and materials to the models to enhance realism and visual appeal. Careful attention is given to details to achieve the desired look and feel.", 
                isOpen: false
            },
            {
                title: "Lighting & Rendering", 
                op: "+", 
                content: "We set up lighting scenarios to create the desired mood and atmosphere. Our rendering process brings the visualization to life, producing high-quality images or animations.", 
                isOpen: false
            },
            // {
            //     title: "Animation & Compositing (Optional)", 
            //     op: "+", 
            //     content: "We present the visualization to you for review and feedback. Your input is valuable in refining the visualization to ensure it meets your expectations and objectives.", 
            //     isOpen: false
            // },
            // {
            //     title: "Client Review & Refinement", 
            //     op: "+", 
            //     content: "We present the visualization to you for review and feedback. Your input is valuable in refining the visualization to ensure it meets your expectations and objectives.", 
            //     isOpen: false
            // },
            {
                title: "Final Delivery & File Format Conversion", 
                op: "+", 
                content: "Once approved, we deliver the final visualization files in the desired formats. We ensure compatibility with various platforms and applications for seamless integration.", 
                isOpen: false
            }
        ])

        const toggleService = (index) => {
            setservice(service.map((service, i) => (
                i === index ? { ...service, isOpen: !service.isOpen,op: service.isOpen ? "+" : "-"  } : service
            )));
        };  

        useEffect(() => {
            window.scrollTo({top: 0,behavior: "instant"})
           }, [])
  return (
    <div>
        <Service4_banner title="2D & 3D Visualization"  />
        <div className="paras4"><h1>
        Don't just describe it, visualize it. At Binary Geckos, we're your 2D and 3D visualization specialists. We translate complex ideas, products, and spaces into stunning visuals that ignite imagination, enhance communication, and leave a lasting impression.
          </h1>
        </div>
        <Oursevices_s4 service={service} toggleService={toggleService} title="our visualization odyssey:" hashtagall={hashtagall}/>
        <Service2_Boost />
        <Casestudy />
        <ServiceBranding title1="Beyond the Sketch: We Visualize Ideas that Captivate Audiences." 
        title2="See the Future, Now: We Craft Visualisations that Make an Impact."
        image={imagesui}/>
        <ServiceQuestion  accordions={accordions}/>

    </div>
  )
}

export default Visualization
import React from 'react'
import "./homeInsight.css"
import images from '../../constants/images'
import { Link } from 'react-router-dom'

const HomeInsight = () => {
  return (
    <div className='homeinsight_main_wrapp'>
        <div className='homeinsight_base_wrapp'>
            <h1> Insight</h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}
            <div className='homeinsight_card_div'>
                <Link to="/5-Design-Trends-That-Will-Revolutionise-Your-Mobile-App-in-2024" className='homeinsight_card_l1'>
                    <div className='HI_l_img_div'> 
                        <img src={images.Revolutionise_Mobile_app} alt="blog-img" className='HI_l_img' />
                    </div>
                    <div className='HI_l_del_div'>
                        <div>
                            <h3 className='HI_title'>5 Design Trends That Will Revolutionise Your Mobile App in 2024</h3>
                            <p className='HI_des'>The mobile app landscape is constantly evolving, with new technologies and user expectations shaping how we design and interact with apps. To stay ahead of the curve and create an app that truly resonates with users, it's crucial to understand the design trends that will dominate 2024.</p>
                        </div>
                        <p className='HI_date'>11 March 2023</p>
                    </div>
                </Link>
                <div className='homeinsight_card_r1'>
                    <Link to={"/The-ROI-of-a-Strong-Brand-Identity:-Why-Invest-in-Design(The-Marriage-of-Branding-and-Marketing)"} className='HI_r_card_div'>
                        <img src={images.Strong_Brand_Identity} alt="blog-img" className='HI_r_img'/>
                        <div className="text-overlay">
                        The ROI of a Strong Brand Identity...
                        </div>
                    </Link>
                    <Link to={"/Data-Driven-Marketing:-Unleashing-Analytics-for-Marketing-Automation-Powerhouse"} className='HI_r_card_div'>
                        <img src={images.Data_Driven_Marketing} alt="blog-img" className='HI_r_img' />
                        <div className="text-overlay">
                        Data-Driven Marketing...
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeInsight
import React, { useState } from 'react';
import "./servicequestion.css";

const ServiceQuestion = ({accordions}) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    // Divide the accordions into two parts
    const halfIndex = Math.ceil(accordions.length / 2);
    const firstHalf = accordions.slice(0, halfIndex);
    const secondHalf = accordions.slice(halfIndex);

    return (
        <div className='service_question_main_wrapp'>
            <div className='service_question_base_wrapp'>
                <h2>Wanna Ask Something?</h2>
                <h1>Frequently Asked Question</h1>
                <div className='service_question_accordian_div'>
                    <div className='service_accordion_flex'>
                        <div className='service_question_accordion_part'>
                            {firstHalf.map((item, index) => (
                                <div 
                                    key={index} 
                                    className={`service_question_accordion ${openIndex === index ? 'active' : ''}`}
                                >
                                    <div className='accordion_header' onClick={() => toggleAccordion(index)}>
                                        <h2>{item.question}</h2>
                                        <p>{openIndex === index ? '-' : '+'}</p>
                                    </div>
                                    <div className='accordion_content'>
                                        <p>{item.answer}</p>
                                        <div className='s_a_list'>
                                            {(item.l1 || item.l2 || item.l3) && (
                                                <ul>
                                                    {item.l1 && <li>{item.l1}</li>}
                                                    {item.l2 && <li>{item.l2}</li>}
                                                    {item.l3 && <li>{item.l3}</li>}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='service_question_accordion_part'>
                            {secondHalf.map((item, index) => (
                                <div 
                                    key={index + halfIndex} 
                                    className={`service_question_accordion ${openIndex === index + halfIndex ? 'active' : ''}`}
                                >
                                    <div className='accordion_header' onClick={() => toggleAccordion(index + halfIndex)}>
                                        <h2>{item.question}</h2>
                                        <p>{openIndex === index + halfIndex ? '-' : '+'}</p>
                                    </div>
                                    <div className='accordion_content'>
                                        <p>{item.answer}</p>
                                        <div className='s_a_list'>
                                            {(item.l1 || item.l2 || item.l3) && (
                                                <ul>
                                                    {item.l1 && <li>{item.l1}</li>}
                                                    {item.l2 && <li>{item.l2}</li>}
                                                    {item.l3 && <li>{item.l3}</li>}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default ServiceQuestion;

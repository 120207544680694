import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const LogoDesign = () => {
    const imagesui = images.Logo_Design;
    const hashtagall = [
        { hashtagname: "#LogoDesign" },
        { hashtagname: "#GraphicDesign" },
        { hashtagname: "#BrandIdentity" },
        { hashtagname: "#LogoInspiration" },
        { hashtagname: "#LogoDesigner" },
        { hashtagname: "#CreativeDesign" },
        { hashtagname: "#BrandLogo" },
    ];
    // FAQ
    const accordions = [
        {
            question: "What information do you need from me to start the logo design process?",
            answer: "We'll need details about your brand, target audience, and desired brand image. This might include your brand story, mission statement, colour preferences, and competitor logos you like or dislike."
        },
        {
            question: "How many logo concepts will I receive?",
            answer: "The number of initial concepts can vary depending on the project scope. However, we typically present 3-5 unique logo concepts for your consideration."
        },
        {
            question: "How many revisions are included in the logo design process?",
            answer: "We offer a set number of revisions to ensure your complete satisfaction. We'll work with you to refine the chosen concept until you're happy with the final logo."
        },
        {
            question: "What file formats will I receive for my logo?",
            answer: "You'll receive your logo in various file formats suitable for different uses, including vector formats (EPS, AI) for high-resolution printing and web-friendly formats (PNG, JPG) for digital applications."
        },
        {
            question: "Do you offer brand guideline development?",
            answer: "Yes, integrating your logo into a brand guideline is an essential step in ensuring consistent brand application. We can create a brand guideline document outlining your logo usage, color palette, typography, and other visual elements.",
        },
        {
            question: "How long does the logo design process take?",
            answer: "The time frame depends on the project's complexity and revision cycles. However, the typical logo design process takes 2-4 weeks.",
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Discovery Workshop",
                op: "+",
                content: "We begin with a discovery workshop to understand your brand, vision, values, and target audience. This helps us gather essential information to guide the logo design process effectively.",
                isOpen: false
            },
            {
                title: "Competitive Analysis",
                op: "+",
                content: "We conduct a thorough competitive analysis to identify trends, understand industry standards, and ensure your logo stands out from competitors.",
                isOpen: false
            },
            {
                title: "Concept Development",
                op: "+",
                content: "Based on the insights gathered, we brainstorm and develop multiple logo concepts that reflect your brand identity and resonate with your audience.",
                isOpen: false
            },
            {
                title: "Refine & Iterate",
                op: "+",
                content: "We refine the initial concepts based on your feedback, iterating until we reach a design direction that aligns with your vision and goals.",
                isOpen: false
            },
            {
                title: "Color Palette Selection",
                op: "+",
                content: "We carefully select colors that evoke the right emotions and reflect your brand personality. The color palette is chosen to enhance brand recognition and convey the desired message.",
                isOpen: false
            },
            {
                title: "Typography Selection",
                op: "+",
                content: "Typography plays a crucial role in logo design. We select fonts that complement your brand identity and ensure readability and visual appeal.",
                isOpen: false
            },
            {
                title: "Logo Presentation & Feedback",
                op: "+",
                content: "We present the refined logo concepts for your review and feedback. We welcome your input to ensure the design aligns perfectly with your expectations.",
                isOpen: false
            },
            {
                title: "Finalization & File Delivery",
                op: "+",
                content: "Once the design is approved, we finalize the logo and prepare all necessary file formats for various applications. You'll receive the logo files promptly for use across different platforms.",
                isOpen: false
            },
            {
                title: "Brand Guideline Integration",
                op: "+",
                content: "We integrate the new logo into your brand guidelines, ensuring consistency across all brand assets and communications.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Logo Design" />
            <div className="paras4"><h1>
                First impressions matter, especially when it comes to your logo. It's the visual representation of your brand, setting the tone for your entire brand identity. At Binary Geckos, we're logo design ninjas.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our logo design odyssey:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Sketch: We Design Logos that Make a Statement."
             title2="The Mark of Excellence: Craft a Logo That Climbs to the Top."
             image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default LogoDesign
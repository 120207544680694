// ---------------- logos ------------------
import logo from "../assets/logos/logo.png";
import btn_img from "../assets/logos/btn_img.png";

// partner logos
import rm from "../assets/logos/1rm 1.png";
import cwatt from "../assets/logos/cwat 2.png";
import clubee from "../assets/logos/clube 2.png";
import sapphireclub from "../assets/logos/sapphireclub 1.png";
import global from "../assets/logos/global 1.png";
import imul_vally from "../assets/logos/imul vally 2.png";


// ------------- images -----------------
// import home_slider_1 from "../assets/images/home_slider_1.png";
import about_image from "../assets/images/about_image.webp";

import home_grid_1 from "../assets/images/home_grid_1.webp";
import home_grid_2 from "../assets/images/home_grid_2.png";
import home_grid_3 from "../assets/images/home_grid_3.png";
import home_grid_4 from "../assets/images/home_grid_4.png";
import home_grid_5 from "../assets/images/home_grid_5.webp";
import home_grid_6 from "../assets/images/home_grid_6.png";
import home_grid_7 from "../assets/images/home_grid_7.png";

import home_os_graph from "../assets/images/home_os_graph.png";

// home insight
import insight_img_1 from "../assets/images/insight_img_1.png";
import insight_img_2 from "../assets/images/insight_img_2.png";
import insight_img_3 from "../assets/images/insight_img_3.png";
import blue_graph from "../assets/images/blue_graph.png";

import HS_img_1 from "../assets/images/HS_img_1.webp";
import HS_img_2 from "../assets/images/HS_img2.png";

import project_1 from "../assets/images/project_1.png";
import project_2 from "../assets/images/project_2.webp";
import project_3 from "../assets/images/project_3.png";
import project_4 from "../assets/images/project_4.png";

import project_circle from "../assets/images/project_circle.png";
import project_circle2 from "../assets/images/project_circle2.png";

// blogpage
import insight_img from "../assets/images/insight_img.webp";
import insight_img_blur from "../assets/images/insight_img_blur.png";
import cardimg1 from "../assets/images/cardimg1.png";
import cardimg2 from "../assets/images/cardimg2.png";
import cardimg3 from "../assets/images/cardimg3.png";
import cardimg4 from "../assets/images/cardimg4.png";

// footer
import bg_logo from "../assets/images/bg-logo.png";
import twitter from "../assets/images/twitter.png";
import fb from "../assets/images/fb.png";
import linkdin from "../assets/images/linkdin.png";
import calll from "../assets/images/calll.png";
import loc from "../assets/images/loc.png";
import mail from "../assets/images/mail.png";

import clube from "../assets/images/clube.png";
import cwat from "../assets/images/cwat.png";
import hotel from "../assets/images/hotel.png";
import imulvally from "../assets/images/imulvally.png";

import what_bg from "../assets/images/what_bg.png";
import home_arrow from "../assets/images/home_arrow.png";

import service_arrow from "../assets/images/service_arrow.png";

import branding_service from "../assets/images/branding_service.png";

// import web_video from "../assets/images/web_video.mp4";
import Link from "../assets/images/Link.png";

import about_core from "../assets/images/about_core.png";
import about_discover from "../assets/images/about_discover.png";

// 8-5-24
import p_img1 from "../assets/images/p_img1.webp";
import p_img2 from "../assets/images/p_img2.png";
import p_img3 from "../assets/images/p_img3.png";
import p_img4 from "../assets/images/p_img4.png";
import p_img5 from "../assets/images/p_img5.png";
import p_img6 from "../assets/images/p_img6.png";
 

import gauze from "../assets/images/gauze.png";
import caseimg1 from "../assets/images/caseimg1.png";
import service2_boost_img from "../assets/images/service2_boost_img.png";
import service_branding_img from "../assets/images/service_branding_img.png";
import service2_card_img from "../assets/images/service2_card_img.png";
import blue_elips from "../assets/images/blue_elips.png";
import blank_img from "../assets/images/blank_img.png";
import location_arrow from "../assets/images/location_arrow.png";
import upload_icon from "../assets/images/upload_icon.png";
import spinner from "../assets/images/spinner.gif";
import check from "../assets/images/check.png";
import Design_border from "../assets/images/Design_border.png";

import design_ser from "../assets/images/design_ser.png";
import develop_ser from "../assets/images/develop_ser.png";
import branding_ser from "../assets/images/branding_ser.png";
import technology_ser from "../assets/images/technology_ser.png";

import career_banner_img from "../assets/images/career_banner_img.png";



import casestudy_banner from "../assets/images/casestudy_banner.png";
import usa_intern_logo from "../assets/images/usa_intern_logo.png";
import Dustitcasestudy from "../assets/images/Dustitcasestudy.png";
import CS_About from "../assets/images/CS_About.png";
import cs_user_img from "../assets/images/career_user_img.webp";
import cs_instant_showcase from "../assets/images/cs_instant_showcase.webp";
import cs_circle_mob from "../assets/images/cs_circle_mob.webp";
import mail_usa from "../assets/images/mail -usa.png";
import us_flag from "../assets/images/us-flag.png";
import msg_notif from "../assets/images/message-notif.png";
import call_logo from "../assets/images/call-calling.png";
import skype_notif from "../assets/images/skype-svgrepo-com 1 (1).png";
import tropography from "../assets/images/tropography.png";
import cs_phone_design from "../assets/images/cs_phone_design.webp";
import cs_result from "../assets/images/cs_result.png";

import chain from "../assets/logos/chain.png";

import design_ser_img from "../assets/images/design_ser_img.webp"
import technology_ser_img from "../assets/images/tehnology_ser_img.webp";
import marketing_ser_img from "../assets/images/marketing_ser_img.webp";


// testimonial logos
import cwaty from "../assets/logos/cwaty.png";
import dustit from "../assets/logos/dustit.webp";
import scorio from "../assets/logos/scorio.webp";
import tickat from "../assets/logos/tickat.png";
import needaofficer from "../assets/logos/needaofficer.png";
import rhentertainment from "../assets/logos/rhentertainment.webp";
import hashtag_tourism from "../assets/logos/hashtag_tourism.png";
import masala_bowl from "../assets/logos/masala_bowl.png";
import imperial_palace from "../assets/logos/imperial_palace.png";

import branding_banner_logo from "../assets/images/branding_banner_logo.png";

import Revolutionise_Mobile_app from "../assets/images/Revolutionise_Mobile App.webp";
import Strong_Brand_Identity from "../assets/images/Strong_Brand_Identity.webp";
import Data_Driven_Marketing from "../assets/images/Data_Driven_Marketing.webp";
import User_Experience from "../assets/images/User_Experience.webp";
import Software_Development_Process from "../assets/images/Software_Development_Process.webp";
import Tech_Powered_Transformation from "../assets/images/Tech_Powered_Transformation.jpg";

import teknika_img from "../assets/images/teknika_img.webp";
import usaintern_img from "../assets/images/usaintern_img.webp";
import concept_img from "../assets/images/concept_img.webp";
import rarerez_img from "../assets/images/rarerez_img.webp";


import usa_3rd_img from "../assets/images/usa_3rd_img.webp";
import usa_4th_img from "../assets/images/usa_4th.webp";

import usa_5th_img from "../assets/images/usa_5th_img.webp";
import rarerezlogo from "../assets/images/rarerezlogo.png";

import USAIntern_cs from "../assets/images/USAIntern_cs.png";
import Dustit_cs from "../assets/images/Dustit_cs.png";
import teknika_cs from "../assets/images/teknika_cs.png";
import Rarerez_cs from "../assets/images/Rarerez_cs.png";

import Rarerez_pro from "../assets/images/Rarerez_pro.webp";
import USAIntern_pro from "../assets/images/usainternship_pro.webp";
import concept_pro from "../assets/images/Concept_pro.webp";
import teknika_pro from "../assets/images/teknika_pro.webp";
import Nasrani_pro from "../assets/images/Nasrani_pro.webp";

import comingsoon_img from "../assets/images/comingsoon_img.png";

import UI_UX_Design from "../assets/images/UI UX Design.webp";
import Elevate from "../assets/images/Elevate Branding.webp";
import Quality_Assurance_Testing from "../assets/images/Quality Assurance & Testing.webp";
import Software_Solutions from "../assets/images/Software Solutions.webp";
import Content_Marketing from "../assets/images/Content Marketing.webp";
import Performance_Marketing from "../assets/images/Performance Marketing.webp";
import Mobile_Experience from "../assets/images/Mobile Experience.webp";
import DataAnalytic from "../assets/images/DataAnalytic.webp";
import Social_Media_Management from "../assets/images/Social Media Management.webp";
import Digital_Marketing from "../assets/images/Digital Marketing.webp";
import Web_Development from "../assets/images/Web Development.webp";
import Data_Analytics from "../assets/images/Data Analytics.webp";
import marketing_automation from "../assets/images/marketing automation.webp";
import MarketingAnalysis from "../assets/images/MarketingAnalysis.webp";
import BrandConsulting from "../assets/images/Brand Consulting.webp";
import Logo_Design from "../assets/images/Logo Design.webp";
import Graphic_Design from "../assets/images/Graphic_Design.webp";
import IndustrialProduct_Design from "../assets/images/IndustrialProduct Design.webp";
import mobileappdevelopment from "../assets/images/mobile app development.webp";
import Designbanner from "../assets/images/Designbanner.webp";
import Technologybanner from "../assets/images/Technologybanner.webp";
import Marketingbanner from "../assets/images/Marketingbanner.webp";
import insightbanner from "../assets/images/Insightbanner.webp";
import dustitbannercase from "../assets/images/dustitbannercase.webp";
import life1 from "../assets/images/life1.webp";
import life2 from "../assets/images/life2.jpeg";
import life3 from "../assets/images/life3.webp";
import life4 from "../assets/images/life4.jpg";
import life5 from "../assets/images/live5.webp";
import life6 from "../assets/images/life6.webp";
import life7 from "../assets/images/life7.webp";
import life8 from "../assets/images/life8.webp";

// rarerez
import rarerez_next_btn from "../assets/images/rarerez_next.png";
import rar_veriefyemail from "../assets/images/rar_veriefyemail.png";
import rar_home_btns from "../assets/images/rar_home_btns.png";
import rar_updatebid from "../assets/images/rar_updatebid.png";
import rar_withdrwbid from "../assets/images/rar_withdrwbid.png";
import rar_arrow from "../assets/images/rar_arrow.png";
import rar_edit from "../assets/images/rar_edit.png";

import rarerez from "../assets/images/rarerz.webp";
import screen1_rar from "../assets/images/screen1.webp";
import screen2_rar from "../assets/images/screen2_rar.webp";
import screen3_rar from "../assets/images/Screen3_rar.webp";
import cs_rarerez_phone_2 from "../assets/images/cs_rarerez_phone_2.webp";

// dust
import dust_single from "../assets/images/dust_single.png";
import dust_btn from "../assets/images/dust_btn.png";
import dust_side from "../assets/images/dust_side.png";

import website_feedback_form from "../assets/images/website-feedback-form.png";



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // logos
  logo,
  btn_img,
  spinner,
  check,

  // ---------- images ----------------------

  // home
  // home_slider_1,
  about_image,

  home_grid_1,
  home_grid_2,
  home_grid_3,
  home_grid_4,
  home_grid_5,
  home_grid_6,
  home_grid_7,

  home_os_graph,

  insight_img_1,
  insight_img_2,
  insight_img_3,
  blue_graph,

  HS_img_1,
  HS_img_2,

  project_1,
  project_2,
  project_3,
  project_4,

  project_circle,
  project_circle2,

  // blog
  insight_img,
  insight_img_blur,
  cardimg1,
  cardimg2,
  cardimg3,
  cardimg4,
  // footer
  twitter,
  fb,
  linkdin,
  bg_logo,
  calll,
  loc,
  mail,

    // teamup
    clube,
    cwat,
    imulvally,
    hotel,
  
    what_bg,

    home_arrow,

    service_arrow,
    branding_service,

    // web_video,
    Link,

    about_core,
    about_discover,

    // 8-5-24
    p_img1,
    p_img2,
    p_img3,
    p_img4,
    p_img5,
    p_img6,

    cwatt,
    clubee,
    sapphireclub,
    rm,
    global,
    imul_vally,
    gauze,
    caseimg1,
    service2_boost_img,
    service_branding_img,
    service2_card_img,
    blue_elips,
    blank_img,
    location_arrow,
    upload_icon,

    Design_border,

    design_ser,
    develop_ser,
    branding_ser,
    technology_ser,

    // career
    career_banner_img,
  

    // casestudy
    casestudy_banner,
    usa_intern_logo,
    CS_About,
    cs_user_img,
    cs_instant_showcase,
    cs_circle_mob,

    mail_usa,
    us_flag,
    msg_notif,
    call_logo,
    skype_notif,
    tropography,
    cs_phone_design,
    cs_result,
    chain,

    // testimonials
    cwaty,
    dustit,
    scorio,
    tickat,
    needaofficer,
    rhentertainment,
    hashtag_tourism,
    imperial_palace,
    masala_bowl,

    design_ser_img,
    technology_ser_img,
    marketing_ser_img,

    branding_banner_logo,

    // blog images
    Revolutionise_Mobile_app,
    Strong_Brand_Identity,
    Data_Driven_Marketing,
    User_Experience,
    Software_Development_Process,
    Tech_Powered_Transformation,

    // portfolio
    teknika_img,
    usaintern_img,
    concept_img,
    rarerez_img,


    usa_3rd_img,
    usa_4th_img,
    usa_5th_img,

    rarerezlogo,

    // case study card img
    USAIntern_cs,
    Dustit_cs,
    teknika_cs,
    Rarerez_cs,

    // project page
    Rarerez_pro,
    USAIntern_pro,
    concept_pro,
    teknika_pro,
    Nasrani_pro,

    comingsoon_img,


     // Solutions menu images 
     UI_UX_Design,
     Elevate,
     Quality_Assurance_Testing,
     Software_Solutions,
     Content_Marketing,
     Performance_Marketing,
     Mobile_Experience,
     DataAnalytic,
     Social_Media_Management,
     Digital_Marketing,
     Web_Development,
     Data_Analytics,
     marketing_automation,
     MarketingAnalysis,
     BrandConsulting,
     Logo_Design,
     Graphic_Design,
     IndustrialProduct_Design,
     mobileappdevelopment,
     Designbanner,
     Technologybanner,
     Marketingbanner,
     insightbanner,
     Dustitcasestudy,
     dustitbannercase,
     life1,
     life2,
     life3,
     life4,
     life5,
     life6,
     life7,
     life8,

      //  rarerez buttons
      rarerez_next_btn,
      rar_veriefyemail,
      rar_home_btns,
      rar_updatebid,
      rar_withdrwbid,
      rar_arrow,
      rar_edit,
      rarerez,
      screen1_rar,
      screen2_rar,
      screen3_rar,
      cs_rarerez_phone_2,

      // dust it
      dust_btn,
      dust_side,
      dust_single,

      website_feedback_form,
}
// JobDetailBanner.js
import React from 'react';
import images from '../../constants/images';
import { FaFacebookF, FaShareAlt } from "react-icons/fa";
import { FaLinkedinIn, FaTelegram, FaWhatsapp, FaXTwitter } from 'react-icons/fa6';
import './jobdetailbanner.css';
import { useGlobalState } from '../../GlobalStateContext';
import { Link } from 'react-router-dom';

// Function to generate WhatsApp share URL
const generateWhatsAppShareUrl = (jobDetails) => {
  const currentPageUrl = window.location.href;
  const message = `Check out this job opportunity at Binary Geckos: ${jobDetails.text} in ${jobDetails.location}. More details: ${currentPageUrl}`;
  return `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
};

const generateFacebookShareUrl = () => {
  const currentPageUrl = window.location.href;
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentPageUrl)}`;
};
  // Function to generate LinkedIn share URL
  const generateLinkedInShareUrl = () => {
    const currentPageUrl = window.location.href;
    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentPageUrl)}`;
  };

// Function to copy the job details URL to clipboard
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    alert('Link copied to clipboard! You can now paste it into your Instagram bio or post.');
  }, (err) => {
    console.error('Failed to copy text: ', err);
  });
};


// Function to generate the share data for Web Share API
const getShareData = (jobDetails) => {
  const currentPageUrl = window.location.href;
  const shareData = {
    title: 'Job Opportunity at Binary Geckos',
    text: `Check out this job opportunity at Binary Geckos: ${jobDetails.text} in ${jobDetails.location}.`,
    url: currentPageUrl,
  };
  return shareData;
};


const JobDetailBanner = ({ jobDetails }) => {
  const { setScrollPosition } = useGlobalState();
  if (!jobDetails) return null; // Handle the case where jobDetails is not available


  const currentPageUrl = window.location.href;
  const whatsappShareUrl = generateWhatsAppShareUrl(jobDetails);
  const facebookShareUrl = generateFacebookShareUrl();
  const linkedinShareUrl = generateLinkedInShareUrl();
  const shareData = getShareData(jobDetails);

// Function to handle share button click
const handleShareClick = () => {
  if (navigator.share) {
    navigator.share(shareData)
      .then(() => console.log('Successful share'))
      .catch((error) => console.error('Error sharing:', error));
  } else {
    // Fallback for browsers that do not support Web Share API
    alert('Web Share API is not supported in your browser. Please share manually.');
  }
};


const handleApplyNowClick = () => {
  setScrollPosition(3);
};
  return (
    <div className='jb_main_wrapp'>
      <img src={images.career_banner_img} alt="" className='' />
      <div className='jb_content_div'>
        <h2>Binary Geckos | {jobDetails.type}</h2>
        <h1>{jobDetails.text}</h1>
        <h3>{jobDetails.location} | <span>Posted on {jobDetails.postedDate}</span></h3>
        {/* <br /> */}
        <Link to={"/ContactUs"} state={{ scroll: 'true' }} onClick={handleApplyNowClick}>
        <button>
          Apply Now
        </button>
        </Link>
        {/* <br /> */}
        <div className='jb_btn_container'>

          <a onClick={handleShareClick} className="jb_link1">
            <FaShareAlt fontSize={"1.5rem"} />
          </a>

          <a href={facebookShareUrl} target='_blank' rel='noopener noreferrer' className="jb_link2">
            <FaFacebookF fontSize={"1.5rem"} />
          </a>

          <a href={whatsappShareUrl} target='_blank' rel='noopener noreferrer' className="jb_link2">
            <FaWhatsapp fontSize={"1.5rem"} />
          </a>

          <a href={linkedinShareUrl} target='_blank' rel='noopener noreferrer' className="jb_link2">
            <FaLinkedinIn fontSize={"1.5rem"} />
          </a>
          <a onClick={() => copyToClipboard(currentPageUrl)} className="jb_link3">
            <img src={images.chain} alt="" />
          </a>
        </div>
      </div>
      <div className='jb_overlay'></div>
    </div>
  );
};

export default JobDetailBanner;

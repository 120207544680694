import React, { useEffect } from 'react'
import "./serviceBranding.css"
import images from '../../constants/images'
import { Link } from 'react-router-dom';

const ServiceBranding = ({ title1, title2, image  }) => {
  useEffect(() => {
    console.log(image); // This should now correctly log the image URL
  }, [image]);
  return (
    <div className='service_branding_main_wrapp'>
      <div className='sb_hero_img_div'>
        <img src={image} alt="banner" loading='lazy' />
        <div className='sb_hero_overlay'></div>
      </div>
      <div className='sb_contact_div'>
        <div className='sb_contact_card'>
          <h1>{title1}</h1>
          <p>{title2}</p>
          <Link to={"/ContactUs"}>
          <button className='sb_contact_card_button'></button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ServiceBranding
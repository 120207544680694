import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "./Navbar.css";

const transition = {
  type: "spring",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};

const MenuItem = ({ setActive, active, item, children, style }) => {
  const [timeoutId, setTimeoutId] = useState(null);
  const handleMouseLeave = () => {
    // Set a new timeout to clear the active item after 10 seconds
    const newTimeoutId = setTimeout(() => {
      setActive(null);
    }, ); // 10000 milliseconds = 10 seconds

    setTimeoutId(newTimeoutId);
  };
  return (
    <div onMouseEnter={() => setActive(item)} className="relative "
     onMouseLeave={handleMouseLeave}
    >
      <motion.a
        transition={{ duration: 0.3 }}
        className="cursor-pointer text-white dark:text-white text-18px"
        style={active === item ? style : {}}
        onMouseEnter={() => setActive(item)}
        onMouseLeave={() => setActive(null)}
      >
        {item}
      </motion.a>
      {active !== null && (
        <motion.div
          initial={{ opacity: 0, scale: 0.85, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={transition}
          onMouseEnter={() => setActive(item)}
          onMouseLeave={() => setActive(null)}
        >
          {active === item && (
            <div className="absolute  left-1/2 transform -translate-x-1/2 absolute-menu-item"
                >
              <motion.div
                transition={transition}
                layoutId="active"
                className="bg-black backdrop-blur-sm rounded-2xl overflow-hidden border-white/[0.2] shadow-xl menu_border_white"
              >
                <motion.div layout className="w-max h-full p-8">
                  {children}
                </motion.div>
              </motion.div>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

const Menu = ({ setActive, children }) => {
  const navRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setActive(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setActive]);

  return (
    <nav ref={navRef} className="">
      {children}
    </nav>
  );
};

const ProductItem = ({ title, description, href, src, setActive }) => {
  const handleLinkClick = () => {
    setActive(null);
  };

  return (
    <div className="flex space-x-2">
      <Link to={href} className="menu_img_div" onClick={handleLinkClick}>
        <img src={src} alt={title} className="flex-shrink-0 rounded-md shadow-2xl" />
      </Link>
      <div className="menu_list_div">
        <h4 className="text-xl mb-1 text-white">
          <Link to={href} onClick={handleLinkClick}>
          {title}
          </Link>
          </h4>
        <ul className="text-neutral-700 text-sm text-white list-disc pl-5">
          {description.map((item, index) => (
            <li key={index}>
              <Link to={item.href} className="nav_pro_item_link" onClick={handleLinkClick}>
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { Menu, MenuItem, ProductItem };

import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const ElevateBranding = () => {
    const imagesui = images.Elevate;
    const hashtagall = [
        { hashtagname: "#BrandElevation" },
        { hashtagname: "#BrandingStrategy" },
        { hashtagname: "#BrandIdentity" },
        { hashtagname: "#BrandDevelopment" },
        { hashtagname: "#BrandGrowth" },
        { hashtagname: "#BrandEnhancement" },
        { hashtagname: "#ElevateYourBrand" },
    ];
    // FAQ
    const accordions = [
        {
            question: "Is branding really that important?",
            answer: "Absolutely! A strong brand can significantly impact your customer loyalty, market share, and overall success."
        },
        {
            question: "Do I need a rebrand?",
            answer: "If your brand feels outdated, fails to resonate with your audience, or doesn't reflect your company's current vision, then a rebrand could be beneficial."
        },
        {
            question: "How long does the branding process take?",
            answer: "The time frame varies depending on the scope of the project, but typically takes 4-8 weeks."
        },
        {
            question: "What happens after the branding is complete?",
            answer: "We don't just launch your brand and walk away. We offer ongoing support to ensure your brand is implemented effectively and evolves alongside your business."
        },
        {
            question: "What are some deliverables I can expect from a branding project?",
            answer: "A branding project typically delivers a comprehensive brand style guide. This guide outlines your brand identity, including your logo, colour palette, typography, voice and tone, and usage guidelines. It ensures consistent brand application across all marketing materials, websites, and social media platforms. Additionally, depending on the project scope, you might receive deliverables like:",
            l1: "Brand messaging framework",
            l2: "Sample brand applications (e.g., mockups of website design elements, business card design)",
            l3: "Brand storytelling assets (e.g., brand story document, elevator pitch script)",
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Discovery",
                op: "+",
                content: "Our branding journey begins with a comprehensive discovery phase. We dive deep into understanding your business, target audience, market landscape, and competitors. This foundational step helps us uncover key insights and identify unique opportunities to elevate your brand.",
                isOpen: false
            },
            {
                title: "Positioning",
                op: "+",
                content: "Effective brand positioning sets you apart in the marketplace. We work with you to define a distinctive position that resonates with your target audience, aligns with your business goals, and highlights your unique value proposition.",
                isOpen: false
            },
            {
                title: "Messaging",
                op: "+",
                content: "Crafting compelling messaging is crucial for communicating your brand’s value. We develop clear, consistent, and persuasive messages that capture the essence of your brand and connect emotionally with your audience.",
                isOpen: false
            },
            {
                title: "Visual Identity",
                op: "+",
                content: "Your visual identity is the face of your brand. We create a cohesive and memorable visual identity, including logos, color palettes, typography, and imagery, that reflects your brand’s personality and appeals to your audience.",
                isOpen: false
            },
            {
                title: "Voice & Tone",
                op: "+",
                content: "Consistency in voice and tone builds trust and recognition. We define your brand’s voice and tone, ensuring that your communications are consistent, whether they are friendly and approachable or professional and authoritative.",
                isOpen: false
            },
            {
                title: "Brand Guidelines",
                op: "+",
                content: "To maintain brand consistency across all channels, we create comprehensive brand guidelines. These guidelines serve as a reference for your team, detailing how to use your brand’s visual and verbal elements correctly.",
                isOpen: false
            },
            {
                title: "Content Strategy",
                op: "+",
                content: "A strong content strategy is vital for engaging your audience and driving brand awareness. We develop a content strategy that aligns with your brand’s goals, ensuring that every piece of content supports your brand’s message and values.",
                isOpen: false
            },
            {
                title: "Brand Launch",
                op: "+",
                content: "We plan and execute a strategic brand launch to introduce your new or revitalized brand to the world. This includes coordinating marketing efforts, creating launch materials, and ensuring a seamless rollout across all platforms.",
                isOpen: false
            },
            {
                title: "Brand Measurement",
                op: "+",
                content: "Post-launch, we measure the impact of your branding efforts. We track key performance indicators (KPIs), gather feedback, and analyze data to ensure your brand continues to resonate with your audience and achieve desired outcomes.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Elevate Branding" />
            <div className="paras4"><h1>
                Your brand is more than just a logo. It's the voice, personality, and promise that sets you apart. At Binary Geckos, we don't just create brands, we elevate them. Through a 9-step process that blends in-depth analysis with creative exploration, we'll transform your brand strategy and messaging into a powerful force that attracts, engages, and converts.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our gecko-powered branding process:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Logo: We Craft Brands that Climb." title2="Gecko-fy Your Brand: Stand Out, Stick Around" image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default ElevateBranding
import React from 'react'
import "./cs_colorpalette.css"
import images from '../../constants/images'

const CS_ColorPalette = () => {
  return (
    <div className='cs_color_main_wrapp'>
        <div className='cs_color_base_wrapp'>
            <h1>Color <span>palette</span></h1>
            <div className="cs_color_grid_wrapp">
                <div className="cs_grid_1">
                    
                </div>
                <div className="cs_grid_2">
                    
                </div>

                <div className="cs_grid_3">
                    
                </div>
                <div className="cs_grid_4">
                    
                </div>
                <div className="cs_grid_5">
                    
                </div>

                <div className="cs_grid_6">
                   
                </div>
            </div>
        </div>
    </div>
  )
}

export default CS_ColorPalette
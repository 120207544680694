import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import Casestudy from '../../container/casestudy/Casestudy'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import OurService_socialM_M from '../../container/Oursevices_s4/Ourservice_socialM_M'
import images from '../../constants/images'

const SocialMediaMgt = () => {
    const imagesui = images.Social_Media_Management;
    const hashtagall = [
        { hashtagname: "#SocialMediaManagement" },
        { hashtagname: "#SocialMediaMarketing" },
        { hashtagname: "#DigitalMarketing" },
        { hashtagname: "#SocialMediaStrategy" },
        { hashtagname: "#ContentMarketing" },
        { hashtagname: "#SocialMediaTips" },
        { hashtagname: "#SMM" },
    ];
    const accordions = [
        { question: "Why is social media marketing important for my business?", answer: "Social media provides a powerful platform to connect with your target audience, build brand awareness, generate leads, and drive sales." },
        { question: "Which social media platforms should I be on?", answer: "We'll help you identify the platforms where your target audience spends their time. There's no one-size-fits-all approach, and we focus on maximizing impact where it matters most." },
        { question: "How do you create engaging social media content?", answer: "We develop creative and strategic content calendars that resonate with your audience. This includes a mix of text, images, videos, and interactive elements to keep your followers engaged." },
        { question: "What is social listening and why is it important?", answer: "Social listening involves monitoring online conversations about your brand and industry. It allows us to understand brand sentiment, identify trends, and respond to customer needs proactively." },
        {question:"Can social media advertising help my business?" ,answer:"Absolutely! Paid social media promotions can significantly increase your reach, target specific demographics, and drive valuable traffic to your website or landing pages." },
        { question: "How will I know if my social media strategy is working?", answer: "We provide comprehensive reports with key metrics like follower growth, engagement rates, website traffic generated, and campaign performance. This allows us to optimize your strategy for continuous improvement." },
    ];

    const [service, setservice] = useState(
      [
          {
              title: "Social Media Audit & Audience Research",
              op: "+", 
              content: "Evaluate current social media presence and understand your audience.", 
              isOpen: false},
          {
              title: "Social Media Strategy Development & Platform Selection",
              op: "+", 
              content: "Craft a strategic plan and select the best platforms to reach your goals.", isOpen: false},
          {
              title: "Content Calendar Creation & Content Marketing Integration", op: "+", content: "Develop a content calendar that aligns with your marketing strategy.", isOpen: false},
          {
              title: "Community Building & Engagement Strategies",
               op: "+", 
               content: "Foster community growth and engagement through targeted strategies.", isOpen: false},
          {
              title: "Social Media Advertising & Paid Promotions (if applicable)", op: "+", content: "Execute paid campaigns to increase reach and conversions.", isOpen: false},
          {
              title: "Social Listening & Brand Reputation Management", op: "+", content: "Monitor social channels to manage and protect your brand’s reputation.", isOpen: false},
          {
              title: "Influencer Marketing & Partnerships (if applicable)", op: "+", content: "Leverage influencers and partnerships to expand your brand’s reach.", isOpen: false},
          {
              title: "Social Media Analytics & Reporting", op: "+", content: "Track performance and provide detailed analytics reports.", isOpen: false},
          {
              title: "Ongoing Optimization & Growth Strategies", op: "+", content: " Continuously improve social media efforts to drive growth and engagement.", isOpen: false}
      ])

  const toggleService = (index) => {
      setservice(service.map((service, i) => (
          i === index ? { ...service, isOpen: !service.isOpen,op: service.isOpen ? "+" : "-"  } : service
      )));
  };

  useEffect(() => {
    window.scrollTo({top: 0,behavior: "instant"})
   }, [])
  return (
    <div>
        <Service4_banner title="Social Media Management"  />
        <div className="paras4"><h1>
        Social media isn't just a platform, it's a conversation. At Binary Geckos, we're your social media chameleons, blending seamlessly into each platform to build vibrant communities, ignite brand conversations, and drive real results. We  craft engaging content and strategic campaigns that make your brand stick in the minds (and feeds) of your target audience.
          </h1>
        </div>
        <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step social media management process:" hashtagall={hashtagall}/>
        <Service2_Boost />
        <Casestudy />
        <ServiceBranding title1="Beyond the Likes: We Build Communities that Climb with Your Brand." 
        title2="Social Savvy for Success: Binary Geckos Gets You Talking Online."
        image={imagesui}/>
        <ServiceQuestion accordions={accordions} />
    </div>
  )
}

export default SocialMediaMgt
// CS_InstantShowcase.js
import React from 'react'
import './cs_instantshowcase.css'

const CS_InstantShowcase = ({ imageSrc }) => {
  return (
    <div className='cs_is_main_wrapp'>
      <img src={imageSrc} alt="Instant Showcase" />
    </div>
  )
}

export default CS_InstantShowcase

import React, { useEffect } from 'react'
import "./AboutUsPage.css"
import AboutServices from '../../container/aboutServices/AboutServices'
import About_video from '../../container/About_video/About_video'
import Aboutus_section1 from '../../container/About_section1/Aboutus_section1'
import Ready_about from '../../container/Ready/Ready_about'
import AboutCorevalues from '../../container/AboutCorevalues/AboutCorevalues'
import AboutDiscover from '../../container/AboutDiscover/AboutDiscover'

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo({top: 0,behavior: "instant"})
  }, [])
  
  return (
    <div>
        <Aboutus_section1 />
        <AboutCorevalues/>
        <AboutDiscover/>
        {/* <About_video /> */}
        <AboutServices/>
        <Ready_about />
    </div>
  )
}

export default AboutUsPage
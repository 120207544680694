import React from 'react'
import "./cs_Webpages.css"
import images from '../../constants/images'

const CS_Webpages = ({head1,content1,img1,head2,content2,img2}) => {
  return (
    <div className='cs_webpage_main_wrapp'>
        <div className='cs_webpage_base_wrapp'>
            {/* section1 */}
            {/* <div className='cs_webpage_content_div'>
                <div className='cs_webpage_content_div_left'>
                    <div className='cs_appmap_circle'>
                    <img src={images.cs_circle_mob} alt="" />
                    </div>
                </div>
                <div className='cs_webpage_content_div_right'>
                    <div>
                        <h1>Home Screen</h1>
                        <br />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet .</p>
                    </div>
                </div>
            </div> */}
            {/* section2 */}
            <div className='cs_webpage_content_div2'>
                <div className='cs_webpage_content_div_right2'>
                    <div>
                        <h1>{head1}</h1>
                        <br />
                        <p>{content1}</p>
                    </div>
                </div>
                <div className='cs_webpage_content_div_left2'>
                    <div className='cs_webpage_mob_div'>
                        <img src={img1} alt="" className='cs_web_mob1'/>
                        {/* <img src={images.cs_circle_mob} alt="" className='cs_web_mob2'/> */}
                    </div>
                </div>
            </div>
            {/* section3 */}
            <div className='cs_webpage_content_div'>
                <div className='cs_webpage_content_div_left'>
                    <div className='cs_appmap_circle2'>
                    <img src={img2} alt="" />
                    </div>
                </div>
                <div className='cs_webpage_content_div_right'>
                    <div>
                        <h1>{head2}</h1>
                        <br />
                        <p>{content2}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CS_Webpages
import React, { Suspense, useEffect } from 'react'
import "./BlogPage.css"
import images from '../../constants/images'
// import Techinsight_card from '../../container/Techinsight_cards/Techinsight_card'
import Section1 from '../../container/section1/Section1'
import Section2_banner from '../../container/Section2_banner/Section2_banner'

const Techinsight_card = React.lazy(() => import("../../container/Techinsight_cards/Techinsight_card"))
const BlogPage = () => {
  useEffect(() => {
    window.scrollTo({top: 0,behavior: "instant"})
   }, [])
   
  return (
    <div className='' style={{marginTop: "150px", width: "100%"}}>
        <Section1 />
        <Section2_banner />
        <Suspense fallback={<></>}>
          <Techinsight_card />
        </Suspense>
    </div>
  )
}

export default BlogPage

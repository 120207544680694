import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const MobileExperience = () => {
    const imagesui = images.Mobile_Experience;
    const hashtagall = [
        { hashtagname: "#MobileUX" },
        { hashtagname: "#MobileDesign" },
        { hashtagname: "#AppDesign" },
        { hashtagname: "#UXMobile" },
        { hashtagname: "#MobileUI" },
        { hashtagname: "#AppUX" },
        { hashtagname: "#MobileExperience" },
    ];

    // FAQ
    const accordions = [
        { 
            question: "Why is mobile experience important?", 
            answer: "In today's digital world, most users access the internet through their mobile devices. A well-designed mobile experience is crucial for reaching your target audience and achieving your business goals." 
        },
        { 
            question: "Do you develop native apps or web apps?", 
            answer: "We can develop both native apps (designed specifically for a particular operating system) and web apps (accessible through a web browser). We'll work with you to determine the best solution for your needs." 
        },
        { 
            question: "What are the benefits of working with Binary Geckos for mobile experience?", 
            answer: "We combine user-centered design principles with cutting-edge development practices to create mobile experiences that are not only visually appealing but also intuitive and functional." 
        },
        { 
            question: "How do you ensure my mobile app is user-friendly?", 
            answer: "We conduct thorough user testing throughout the development process to identify and address any usability issues. Our focus is on creating a mobile experience that feels natural and effortless for your users." 
        },
        { 
            question: "Will my mobile app be optimized for different screen sizes?", 
            answer: "Absolutely! We design mobile experiences that are responsive and adapt seamlessly to various devices and screen sizes." ,
        },
        { 
            question: "How can I get started with creating a mobile experience?", 
            answer: "Contact Binary Geckos today for a free consultation! We'd love to discuss your project and help you develop a mobile experience that climbs to the top of the app store charts." ,
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Design", 
                op: "+", 
                content: "We begin by crafting the design of your mobile experience, focusing on user interface (UI) and user experience (UX) principles to create intuitive and visually appealing designs.", 
                isOpen: false
            },
            {
                title: "Prototype", 
                op: "+", 
                content: "Next, we create interactive prototypes that simulate the functionality of your mobile app or website, allowing you to experience the flow and features before development begins.", 
                isOpen: false
            },
            {
                title: "Test", 
                op: "+", 
                content: "We conduct rigorous testing to gather feedback from users, ensuring that the mobile experience meets usability standards and addresses user needs effectively.", 
                isOpen: false
            },
            {
                title: "Refine", 
                op: "+", 
                content: "Based on user feedback and testing results, we refine the design and functionality to enhance usability, accessibility, and overall user satisfaction.", 
                isOpen: false
            },
            {
                title: "Optimise", 
                op: "+", 
                content: "We optimize the mobile experience for performance, speed, and compatibility across various devices and screen sizes, ensuring a seamless experience for all users.", 
                isOpen: false
            },
            {
                title: "Launch", 
                op: "+", 
                content: "With everything in place, we prepare for the official launch of your mobile app or website, ensuring a smooth rollout to your audience.", 
                isOpen: false
            },
            {
                title: "Monitor",
                 op: "+", 
                 content: "After launch, we continuously monitor the mobile experience, gathering data and insights to understand user behavior and performance metrics.",
                isOpen: false
            },
            {
                title: "Adapt", 
                op: "+", 
                content: "Using the insights gathered, we adapt the mobile experience as needed, making improvements and adjustments to optimize performance and user satisfaction.", 
                isOpen: false
            },
            {
                title: "Evolve", 
                op: "+", 
                content: "As technology and user expectations evolve, we continue to evolve your mobile experience, introducing new features and enhancements to stay ahead of the curve.", 
                isOpen: false
            }
        ])

        const toggleService = (index) => {
            setservice(service.map((service, i) => (
                i === index ? { ...service, isOpen: !service.isOpen,op: service.isOpen ? "+" : "-"  } : service
            )));
        };  

        useEffect(() => {
            window.scrollTo({top: 0,behavior: "instant"})
           }, [])
  return (
    <div>
        <Service4_banner title="Mobile Experience"  />
        <div className="paras4"><h1>
        Welcome to the age of mobile-first! At Binary Geckos, we craft mobile experiences that are as captivating as a gecko climbing a skyscraper.
          </h1>
        </div>
        <Oursevices_s4 service={service} toggleService={toggleService} title="Our process ensures seamless user journeys, from intuitive navigation to fingertip-friendly interactions." hashtagall={hashtagall}/>
        <Service2_Boost />
        <Casestudy />
        <ServiceBranding title1="Gecko-Sized Interactions, Big-Time Results: Mobile experiences that drive engagement and conversions." 
        title2="Apps that Stick in Your Pocket: Create unforgettable mobile experiences users won't put down"
        image={imagesui}/>
        <ServiceQuestion  accordions={accordions}/>

    </div>
  )
}

export default MobileExperience
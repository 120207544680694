import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const QualityAssuranceTesting = () => {
    const imagesui = images.Quality_Assurance_Testing;

    const hashtagall = [
        { hashtagname: "#QualityAssurance" },
        { hashtagname: "#SoftwareTesting" },
        { hashtagname: "#QATesting" },
        { hashtagname: "#Testing" },
        { hashtagname: "#QAEngineer" },
        { hashtagname: "#TestAutomation" },
        { hashtagname: "#BugBounty" },
    ];
    // FAQ
    const accordions = [
        {
            question: "Why is QA & testing important?",
            answer: "QA & testing uncovers defects before they reach users, saving time, resources, and ensuring a positive user experience."
        },
        {
            question: "What types of testing do you perform?",
            answer: "We perform a comprehensive suite of testing, including functional, non-functional (usability, performance, security), compatibility (browsers, devices), and regression testing."
        },
        {
            question: "How do you handle bugs and defects?",
            answer: "We use a robust bug tracking system to efficiently log, prioritize, and communicate defects to developers for resolution."
        },
        {
            question: "What happens after launch? Do you offer ongoing testing?",
            answer: "Yes, we offer post-launch monitoring to identify any issues that arise after deployment and ensure continued performance."
        },
        {
            question: "How can QA & testing benefit my project timeline?",
            answer: "Thorough testing upfront can actually save time in the long run by preventing costly bug fixes later in the development process."
        },
        {
            question: "Can QA & testing be integrated into agile development methodologies?",
            answer: "Absolutely! We have a flexible approach that seamlessly integrates with agile development practices."
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Requirements Review & Test Plan Creation",
                op: "+",
                content: "We start by thoroughly reviewing project requirements and creating a comprehensive test plan. This plan outlines the scope, objectives, resources, schedule, and deliverables, ensuring all testing activities align with project goals.",
                isOpen: false
            },
            {
                title: "Test Case Design & Development",
                op: "+",
                content: "Next, we design and develop detailed test cases based on the requirements and use cases. These test cases cover all possible scenarios to ensure thorough testing of the application.",
                isOpen: false
            },
            {
                title: "Functional Testing",
                op: "+",
                content: "We conduct extensive functional testing to verify that all features and functionalities work as intended. This ensures the application meets the specified requirements and provides the expected user experience.",
                isOpen: false
            },
            {
                title: "Non-Functional Testing (Usability, Performance, Security)",
                op: "+",
                content: "Beyond functionality, we perform non-functional testing to assess usability, performance, and security. This ensures the application is user-friendly, performs well under various conditions, and is secure from vulnerabilities.",
                isOpen: false
            },
            {
                title: "Compatibility Testing (Browser, Device)",
                op: "+",
                content: "We perform compatibility testing to ensure the application works seamlessly across different browsers and devices. This guarantees a consistent experience for all users, regardless of their chosen platform.",
                isOpen: false
            },
            {
                title: "Regression Testing",
                op: "+",
                content: "Regression testing is conducted to ensure that new code changes do not adversely affect the existing functionality. This helps maintain the overall stability and integrity of the application.",
                isOpen: false
            },
            {
                title: "Defect Tracking & Management",
                op: "+",
                content: "We meticulously track and manage all identified defects. Our process includes logging, prioritizing, and assigning bugs for resolution, ensuring that no issue is overlooked.",
                isOpen: false
            },
            {
                title: "Bug Reporting & Communication",
                op: "+",
                content: "We provide detailed bug reports and maintain open communication with your development team. This facilitates efficient bug fixing and helps keep the project on track.",
                isOpen: false
            },
            {
                title: "Pre-Release Sign-Off & Post-Launch Monitoring",
                op: "+",
                content: "Before the application goes live, we conduct a final round of testing and provide a pre-release sign-off. Post-launch, we monitor the application to ensure it performs as expected and address any issues that may arise.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Quality Assurance & Testing" />
            <div className="paras4"><h1>
                Quality isn't an afterthought, it's the foundation. At Binary Geckos, we believe meticulous quality assurance (QA) and testing are the cornerstones of successful software development. Our team of QA ninjas meticulously scrutinize your project, eliminating bugs and ensuring a flawless user experience from launch and beyond.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step quality assurance & testing process:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Bugs: We Test for Flawless Performance." 
            title2="Confidence Climbs with Quality: We Assure Success." image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default QualityAssuranceTesting
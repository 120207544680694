import React, { useEffect, useRef } from 'react'
import Service2Sec3 from '../../container/Service2Sec3/Service2Sec3'
import Service2_Whatwedo from '../../container/Service2Whatwedo/Service2_Whatwedo'
import Casestudy from '../../container/casestudy/Casestudy'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import { useNavigate } from 'react-router-dom'
import images from '../../constants/images'
import Design_banner from '../../container/Design_Ser_banner/Design_banner'
// import Service2UiUx1 from '../../container/Service2UIUX1/Service2UiUx1'

const ServiceDesign = () => {
    const imagesui = images.Designbanner;
    const accordions = [
        {
            question: "What design services does Binary Geckos offer?",
            answer: "Binary Geckos offers a range of design services including graphic design, web design, UI/UX design, branding, print design, illustration, and digital design to meet all your creative needs."
        },
        {
            question: "How can Binary Geckos help improve my brand identity?",
            answer: "Our expert team at Binary Geckos can help build and enhance your brand identity through professional logo design, brand guidelines, and cohesive visual identity development tailored to your business."
        },
        {
            question: "What is the process for starting a design project with Binary Geckos?",
            answer: "To start a design project with Binary Geckos, simply contact us through our website. We'll discuss your needs, provide a detailed proposal, and guide you through each step of the design process to ensure your vision is realized."
        },
        {
            question: "How long does it typically take to complete a design project?",
            answer: "The timeline for completing a design project with Binary Geckos depends on the scope and complexity of the project. We provide estimated timelines during our initial consultation and keep you updated throughout the process."
        },
        {
            question: "Can Binary Geckos design for both print and digital media?",
            answer: "Yes, Binary Geckos specializes in both print and digital design. Whether you need marketing materials, packaging, social media graphics, or website design, we have you covered."
        },
        {
            question: "What makes Binary Geckos' design services stand out?",
            answer: "Binary Geckos stands out with our commitment to quality, creativity, and personalized service. Our team of experienced designers works closely with you to ensure your designs are unique, visually appealing, and aligned with your brand’s goals"
        },
    ];

    const navigate = useNavigate();
    const whatwecard = [
        {
            imgSrc: images.service2_card_img, title: "UI/UX Design", des: "We're obsessed with crafting intuitive interfaces that feel as natural as a gecko navigating a wall.",
            path: "/UIUXDesign"
        },
        {
            imgSrc: images.service2_card_img, title: "Web Development", des: "We craft beautiful, functional websites that are secure, scalable, and deliver exceptional user experiences.",
            path: "/Design-WebDevelopment"
        },
        {
            imgSrc: images.service2_card_img, title: "Mobile Experience", des: "We craft mobile experiences that are as captivating as a gecko climbing a skyscraper.",
            path: "/MobileExperience"
        }
        // { imgSrc: images.service2_card_img, title: "Industrial / Product Design:", des: "From sleek tech gadgets to innovative products, we design experiences that are as functional as they are visually stunning.",
        // path: "/IndustrialDesign" },
        // { imgSrc: images.service2_card_img, title: "Graphic Design", des: " Eye-catching visuals that tell your brand story and captivate your audience. We're the masters of visual storytelling.",
        // path: "/GraphicDesign" },
        // { imgSrc: images.service2_card_img, title: "2D / 3D Visualization:", des: "We bring your brand vision to life with stunning 2D and 3D visualizations, allowing you to see your ideas before they become reality.",
        // path: "/2D-3DVisualization" },
    ];
    const handleCardClick = (path) => {
        navigate(path);
    };


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Design_banner />
            <Service2Sec3 titleservice="We are dedicated to providing you with top-notch design solutions that cater to all your creative needs. Our team of expert designers is here to help you bring your vision to life, whether you are looking to enhance your brand identity, create stunning visuals, or develop an engaging user interface." />
            <Service2_Whatwedo whatwecard={whatwecard} handleCardClick={handleCardClick} details_whatwedo="Enhance your brand identity, create stunning visuals, and develop engaging user experiences with our professional design solutions." />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Captivating Interfaces: We create user experiences that engage and retain."
                title2="Engagement-Driven Design: Elevate conversions with user-focused UI/UX solutions."
                image={imagesui}
            />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default ServiceDesign
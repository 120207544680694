import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const SoftwareSolutions = () => {
    const imagesui = images.Software_Solutions;

    const hashtagall = [
        { hashtagname: "#SoftwareSolutions" },
        { hashtagname: "#TechSolutions" },
        { hashtagname: "#CustomSoftware" },
        { hashtagname: "#SoftwareDevelopment" },
        { hashtagname: "#BusinessSolutions" },
        { hashtagname: "#EnterpriseSoftware" },
        { hashtagname: "#ITSolutions" },
    ];
    // FAQ
    const accordions = [
        {
            question: "What types of software solutions do you develop?",
            answer: "We have a diverse team capable of developing a wide range of software solutions, including custom business applications, enterprise software, web applications, and mobile apps."
        },
        {
            question: "What development methodologies do you use?",
            answer: "We primarily utilize agile development methodologies like Scrum or Kanban, ensuring flexibility and continuous improvement throughout the development process."
        },
        {
            question: "How do you ensure my software meets my specific needs?",
            answer: "We prioritize in-depth communication and collaboration throughout the project. We gather your requirements, conduct user interviews, and involve you in every stage of development to ensure the final product aligns perfectly with your vision."
        },
        {
            question: "What happens after development is complete?",
            answer: "We don't disappear after launch! We offer ongoing maintenance and support plans to ensure your software functions optimally, receives bug fixes, and adapts to your evolving needs."
        },
        {
            question: "How do you handle project complexity?",
            answer: "Our experienced project managers ensure clear communication, manage timelines effectively, and break down complex projects into manageable phases."
        },
        {
            question: "Is your software development process secure?",
            answer: "We prioritize secure coding practices and stay updated on the latest security threats to protect your data and intellectual property."
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Project Kickoff & Business Needs Analysis",
                op: "+",
                content: "We begin every project with a comprehensive kickoff meeting to understand your business needs and goals. This phase involves analyzing your current processes, identifying challenges, and setting clear objectives to ensure our software solutions align perfectly with your business strategy.",
                isOpen: false
            },
            {
                title: "Requirements Gathering & User Experience (UX) Design",
                op: "+",
                content: "In this phase, we gather detailed requirements through stakeholder interviews and workshops. We then focus on User Experience (UX) design, creating wireframes and prototypes to ensure the software is intuitive, user-friendly, and meets your users' needs.",
                isOpen: false
            },
            {
                title: "Software Architecture & System Design",
                op: "+",
                content: "In this phase, we gather detailed requirements through stakeholder interviews and workshops. We then focus on User Experience (UX) design, creating wireframes and prototypes to ensure the software is intuitive, user-friendly, and meets your users' needs.",
                isOpen: false
            },
            {
                title: "Agile Development Methodology & Iteration Planning",
                op: "+",
                content: "Our development approach is rooted in Agile methodology, allowing for flexibility and iterative progress. We plan iterations in sprints, ensuring regular updates, continuous feedback, and timely delivery of functional components.",
                isOpen: false
            },
            {
                title: "Software Development & Programming",
                op: "+",
                content: "Our skilled developers bring the design to life, writing clean, efficient, and maintainable code. We focus on creating robust software solutions that fulfill all specified requirements while adhering to industry best practices and standards.",
                isOpen: false
            },
            {
                title: "Rigorous Testing & Quality Assurance (QA)",
                op: "+",
                content: "Quality is paramount in our process. We conduct rigorous testing and Quality Assurance (QA) to identify and fix any issues. This includes unit testing, integration testing, system testing, and user acceptance testing to ensure the software is reliable, secure, and performs as expected.",
                isOpen: false
            },
            {
                title: "Deployment & Integration",
                op: "+",
                content: "Once the software has passed all testing phases, we deploy it to your production environment. We ensure seamless integration with your existing systems and provide support during the transition to ensure minimal disruption to your operations.",
                isOpen: false
            },
            {
                title: "User Training & Support",
                op: "+",
                content: "We offer comprehensive user training sessions to ensure your team can use the new software effectively. Our support services are available to address any questions or issues that arise post-deployment, ensuring a smooth adoption process.",
                isOpen: false
            },
            {
                title: "Ongoing Maintenance & Optimization",
                op: "+",
                content: "Our commitment doesn’t end at deployment. We provide ongoing maintenance and optimization services to keep your software running smoothly. We continuously monitor performance, implement updates, and make improvements to ensure your software remains aligned with your evolving business needs.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Software Solutions" />
            <div className="paras4"><h1>
                Software shouldn't just exist, it should empower. At Binary Geckos, we're your software sherpa, guiding you through the development journey to create custom software solutions that solve your unique business challenges and propel you towards your goals.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step software development odyssey:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Code: We Build Solutions that Empower Your Business."
             title2="Software Tailored for Success: Climb the Ladder with Binary Geckos."
             image={imagesui}
              />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default SoftwareSolutions
import React, { useEffect, useRef } from 'react'
import Service2Sec3 from '../../container/Service2Sec3/Service2Sec3'
import Service2_Whatwedo from '../../container/Service2Whatwedo/Service2_Whatwedo'
import Casestudy from '../../container/casestudy/Casestudy'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import { useNavigate } from 'react-router-dom'
import images from '../../constants/images'
import ServiceBrandingBanner from '../../container/Service_Branding_banner/ServiceBrandingBanner'
import Ser_Branding_sec2 from '../../container/Service2Sec3/Ser_Branding_sec2'
// import Service2UiUx1 from '../../container/Service2UIUX1/Service2UiUx1'

const ServiceBrandingp = () => {
    const imagesui = images.Designbanner;
    const accordions = [
        {
            question: "What services does Binary Geckos offer?",
            answer: "Binary Geckos specializes in a wide range of digital services, including web design, app development, digital marketing, and branding. We cater to businesses of all sizes, from startups to established enterprises, helping them achieve their digital goals efficiently."
        },
        {
            question: "How does Binary Geckos approach branding projects?",
            answer: "At Binary Geckos, we approach branding projects with a strategic mindset. We begin by understanding your business objectives, target audience, and competitive landscape. Our process involves comprehensive research, creative brainstorming, and iterative design to ensure your brand identity aligns perfectly with your vision and resonates with your audience."
        },
        {
            question: "What makes Binary Geckos' branding services unique?",
            answer: "Our branding services stand out due to our deep understanding of design principles, consumer psychology, and market trends. We blend creativity with strategic thinking to create compelling brand stories, memorable visual identities, and cohesive brand experiences that leave a lasting impression on your audience."
        },
        {
            question: "How long does the branding process typically take?",
            answer: "The duration of the branding process depends on the scope and complexity of the project. Typically, it involves initial consultations, research and strategy development, design iterations, and final implementation. We work closely with our clients to ensure timelines are met without compromising quality."
        },
        {
            question: "Can Binary Geckos assist with rebranding projects?",
            answer: "Yes, Binary Geckos has extensive experience in rebranding projects. Whether you're looking to refresh your brand identity or undertake a complete rebrand, our team can guide you through the process. We analyze current brand performance, conduct market research, and develop strategies to successfully transition your brand while maintaining continuity and relevance."
        },
        {
            question: "How can I get started with Binary Geckos' branding services",
            answer: "Getting started with Binary Geckos is simple! Contact us through our website or give us a call to schedule a consultation. During the initial meeting, we'll discuss your branding goals, timeline, and budget. From there, our team will create a customized proposal outlining the recommended strategy and next steps to kickstart your branding journey with us."
        },
    ];

    const navigate = useNavigate();
    const nextSectionRef = useRef(null);
    const whatwecard = [
        {
            imgSrc: images.service2_card_img, title: "Elevate Branding", des: "We take your brand from good to gecko-great! Through in-depth analysis and creative exploration, we'll elevate your brand strategy and messaging to new heights.",
            path: "/ElevateBranding"
        },
        {
            imgSrc: images.service2_card_img, title: "Brand Consulting", des: "We become your trusted advisors, offering strategic guidance and insights to help you navigate the ever-evolving branding landscape.",
            path: "/BrandConsulting"
        },
        {
            imgSrc: images.service2_card_img, title: "Logo Design", des: "We design logos that are as memorable as a gecko's footprint. Logos that capture the essence of your brand and leave a lasting impression.",
            path: "/LogoDesign"
        },
        {
            imgSrc: images.service2_card_img, title: "Industrial / Product Design:", des: "From sleek tech gadgets to innovative products, we design experiences that are as functional as they are visually stunning.",
            path: "/IndustrialDesign"
        },
        {
            imgSrc: images.service2_card_img, title: "Graphic Design", des: " Eye-catching visuals that tell your brand story and captivate your audience. We're the masters of visual storytelling.",
            path: "/GraphicDesign"
        },
        // {
        //     imgSrc: images.service2_card_img, title: "2D / 3D Visualization:", des: "We bring your brand vision to life with stunning 2D and 3D visualizations, allowing you to see your ideas before they become reality.",
        //     path: "/2D-3DVisualization"
        // },
    ];
    const handleCardClick = (path) => {
        navigate(path);
    };
    const scrollToNextSection = () => {
        if (nextSectionRef.current) {
            const offsetTop = nextSectionRef.current.offsetTop;
            window.scrollTo({
              top: offsetTop - 92, // Subtract 92px to account for the navbar
              behavior: 'smooth',
            });
          }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <ServiceBrandingBanner onLogoClick={scrollToNextSection}/>
            <div ref={nextSectionRef}>
                <Service2Sec3 titleservice="At Binary Geckos, we specialize in crafting unique brand identities that resonate and drive growth. Our team works closely with you to ensure every aspect of your brand reflects your vision and values. Whether you're a startup or an established brand, we have the expertise to help you achieve your objectives" />
            </div>
            <Service2_Whatwedo whatwecard={whatwecard} handleCardClick={handleCardClick} details_whatwedo="We create memorable brand identities that set your business apart, whether you're launching a new brand or revitalizing an existing one." />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Deep Impact Branding: Crafting Strategic and Memorable Identities."
                title2="Guide Your Brand's Journey: Steer Through the Market with Assurance."
                image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default ServiceBrandingp
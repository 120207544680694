import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const MobileappDevelopment = () => {
    const imagesui = images.mobileappdevelopment;

    const hashtagall = [
        { hashtagname: "#AppDevelopment" },
        { hashtagname: "#MobileApp" },
        { hashtagname: "#iOSDevelopment" },
        { hashtagname: "#AndroidDevelopment" },
        { hashtagname: "#AppDesign" },
        { hashtagname: "#MobileDev" },
        { hashtagname: "#AppDeveloper" },
    ];

    // FAQ
    const accordions = [
        {
            question: "What type of mobile apps do you develop?",
            answer: "We develop a wide range of mobile apps, from simple utility apps to complex social networking platforms and e-commerce solutions"
        },
        {
            question: "Do I need to have a web design before development starts?",
            answer: "While a finalized design is ideal, we can collaborate with you to develop your website design alongside the development process."
        },
        {
            question: "Native vs. Hybrid Apps: What's the difference?",
            answer: "Native apps are built specifically for a particular platform (iOS or Android) and offer the best performance and user experience. Hybrid apps use a single codebase that can be deployed across platforms, but may have limitations in functionality. We'll help you choose the right approach for your app."
        },
        {
            question: "How much does it cost to develop a mobile app?",
            answer: "Costs vary depending on the complexity of your app, features, and desired platforms. We'll provide a transparent quote after discussing your project needs."
        },
        {
            question: "How do you ensure my app stands out in the app store?",
            answer: "App Store Optimization (ASO) is crucial. We'll optimize your app listing with relevant keywords and compelling visuals to increase discoverability."
        },
        {
            question: "What about maintenance and updates after launch?",
            answer: "We offer ongoing support plans to ensure your app receives bug fixes, security updates, and new features to keep users engaged."
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Project Kickoff & Discovery",
                op: "+",
                content: "We begin with a thorough kickoff and discovery phase to understand your vision, goals, and requirements. This involves detailed discussions to align our approach with your business objectives.",
                isOpen: false
            },
            {
                title: "Competitive Analysis & Market Research",
                op: "+",
                content: "We conduct in-depth competitive analysis and market research to identify opportunities, understand market trends, and define the unique value proposition of your app.",
                isOpen: false
            },
            {
                title: "User Persona Development & User Journey Mapping",
                op: "+",
                content: "To ensure a user-centric design, we develop detailed user personas and map user journeys. This helps us understand the needs, behaviors, and pain points of your target audience.",
                isOpen: false
            },
            {
                title: "App Feature Definition & Prioritization",
                op: "+",
                content: "We work with you to define and prioritize app features, ensuring that we focus on the functionalities that will deliver the most value to your users and business.",
                isOpen: false
            },
            {
                title: "App Design & Prototyping",
                op: "+",
                content: "Our design team creates intuitive and engaging app designs. We develop interactive prototypes that allow you to experience the app's flow and functionality before development begins.",
                isOpen: false
            },
            {
                title: "Native App Development (iOS/Android)",
                op: "+",
                content: "We specialize in native app development for both iOS and Android platforms. Our developers use the latest technologies and best practices to build high-performance, scalable apps.",
                isOpen: false
            },
            {
                title: "Rigorous Testing & Quality Assurance",
                op: "+",
                content: "Quality is paramount. We conduct rigorous testing and quality assurance to ensure your app is reliable, secure, and provides a seamless user experience across all devices.",
                isOpen: false
            },
            {
                title: "App Store Optimization & Launch Strategy",
                op: "+",
                content: "We prepare your app for a successful launch with comprehensive app store optimization (ASO) and a strategic launch plan to maximize visibility and downloads.",
                isOpen: false
            },
            {
                title: "Ongoing Support & Maintenance",
                op: "+",
                content: "Post-launch, we provide ongoing support and maintenance to keep your app running smoothly. We monitor performance, implement updates, and continuously optimize to enhance user satisfaction.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Mobile app development" />
            <div className="paras4"><h1>
                Your app idea deserves to climb to the top of the charts.  At Binary Geckos, we're your mobile app development dream team. We transform innovative concepts into user-friendly, engaging mobile apps that resonate with your target audience and drive results.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step mobile app development process:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Download: We Craft Apps Users Can't Put Down."
             title2="Apps that Climb the Charts: Built to Stick and Impress."
             image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default MobileappDevelopment
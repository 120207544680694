import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const DataAnalytic = () => {
    const imagesui = images.Data_Analytics;
    const hashtagall = [
        { hashtagname: "#DataAnalytics" },
        { hashtagname: "#BigData" },
        { hashtagname: "#DataScience" },
        { hashtagname: "#Analytics" },
        { hashtagname: "#DataInsights" },
        { hashtagname: "#DataDriven" },
        { hashtagname: "#DataVisualization" },
    ];

    // FAQ
    const accordions = [
        {
            question: "What types of data analytics services do you offer?",
            answer: "We offer a range of data analytics services, including data strategy development, data visualization, customer segmentation, marketing analytics, and business intelligence."
        },
        {
            question: "Do I need a lot of data to benefit from data analytics?",
            answer: "Not necessarily. We can help you leverage your existing data, even if it's limited, to gain valuable insights. We can also guide you on collecting additional data if needed."
        },
        {
            question: "How can data analytics help my business?",
            answer: "Data analytics can help you understand your target audience better, improve marketing ROI, personalize customer experiences, optimize operations, and make data-driven decisions for overall business growth."
        },
        {
            question: "What tools and technologies do you use for data analytics?",
            answer: "We utilize a variety of industry-standard tools and technologies, including but not limited to: Python, R, SQL, Tableau, Power BI, Google Analytics, and more."
        },
        {
            question: "How much does data analytics cost?",
            answer: "Costs vary depending on the complexity of your project, the amount of data involved, and the specific services required. We'll provide a transparent quote after discussing your needs."
        },
        { question: "Can you help me integrate data analytics into my existing business processes?", answer: "Absolutely! We can help you develop a data-driven culture within your organization and seamlessly integrate data analytics into your existing workflows." },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Project Kickoff & Business Goal Alignment",
                op: "+",
                content: "At the beginning of every project, we ensure alignment with your business goals. We conduct thorough discussions to understand your objectives, challenges, and vision, setting the stage for a successful collaboration.",
                isOpen: false
            },
            {
                title: "Data Source Identification & Integration",
                op: "+",
                content: "Identifying the right data sources is crucial. We work to integrate diverse data sources seamlessly, ensuring that your analytics process has access to the information it needs for meaningful insights.",
                isOpen: false
            },
            {
                title: "Data Cleaning & Preprocessing",
                op: "+",
                content: "Quality data is the foundation of insightful analysis. We employ advanced techniques to clean and preprocess your data, ensuring accuracy and consistency before analysis begins.",
                isOpen: false
            },
            {
                title: "Exploratory Data Analysis (EDA)",
                op: "+",
                content: "Unlocking the potential of your data starts with exploration. Through EDA, we delve deep into your data, uncovering patterns, trends, and valuable insights that drive informed decision-making.",
                isOpen: false
            },
            {
                title: "Hypothesis Development & Statistical Modeling",
                op: "+",
                content: "We formulate hypotheses and apply statistical modeling techniques to validate assumptions and extract meaningful relationships from your data, paving the way for predictive insights.",
                isOpen: false
            },
            {
                title: "Data Visualization & Story telling",
                op: "+",
                content: "Data comes to life through visualization. We craft compelling visualizations and narratives that make complex data easy to understand, enabling you to tell a story that resonates with your audience.",
                isOpen: false
            },
            {
                title: "Actionable Recommendations & Strategy Development",
                op: "+",
                content: "Insights are only valuable when acted upon. We provide actionable recommendations and collaborate with you to develop strategies that leverage data-driven insights to achieve your business objectives.",
                isOpen: false
            },
            {
                title: "Implementation & Monitoring",
                op: "+",
                content: "We support you in implementing recommended strategies and continuously monitor their performance. This iterative process ensures that your initiatives are on track to meet your goals.",
                isOpen: false
            },
            {
                title: "Ongoing Data Management & Optimization",
                op: "+",
                content: "Data is dynamic, and so are we. We provide ongoing data management services, optimizing processes, and strategies to adapt to changes, ensuring sustained success.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Data Analytics" />
            <div className="paras4"><h1>
                Don't just collect data, make it climb the ladder of success. At Binary Geckos, we're data ninjas. We harness the power of data analytics to extract valuable insights that fuel informed decision-making and drive impactful results for your brand.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step data & analytics process:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Data: We Climb to Actionable Insights." title2="Data Doesn't Lie, We Make it Tell Your Success Story." image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default DataAnalytic
import React from 'react'
import "./About_discover.css"
import images from '../../constants/images'
import Btn_ani from '../homehero/Btn_ani'
import { Link } from 'react-router-dom'

const AboutDiscover = () => {
  return (
    <div className='about_discover_main_wrapp'>
        <div className='about_discover_base_wrapp'>
            <div className="about_discover_img_container">
                <img src={images.about_discover} alt="" className='about_discover_img' loading='lazy'/>
                <div className="about_discover_overlay"></div>
            </div>
            <div className='about_discover_title_div'>
                <div className='about_discover_title'>
                    <div className='headerr'></div>
                    <h1 className='ppppppp'>Across industries, we're your one stop shop for digital transformation.</h1>
                </div>
                <div className='about_discover_button'>
                    <Link className='a_d_btn' to={"/Projects"}>
                        <p>Discover our Works</p>
                        <Link to={"/Projects"}>
                            <Btn_ani />
                        </Link>
                    </Link>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default AboutDiscover
import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import images from "../../constants/images";
import "./ProjectSlider.css"

const cards = [
    {
      url: images.project_1,
      title: "Title 1",
      id: 1,
    },
    {
      url: images.project_2,
      title: "Title 2",
      id: 2,
    },
    {
      url: images.project_3,
      title: "Title 3",
      id: 3,
    },
    {
      url: images.project_4,
      title: "Title 4",
      id: 4,
    },
  ];

const ProjectSlider = () => {
  return (
    <div>
      
      <HorizontalScrollCarousel />
      <HorizontalScrollCarousel2 />
      
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-20%"]);


  return (
    <section ref={targetRef} className="PS_Section1">
      <div className="PS_Sticky_div">
        <motion.div style={{ x }} className="PS_Motion_div">
          <h1 className="contact_loc_title_div_h1">Find our</h1>
        </motion.div>
      </div>
    </section>
  );
};

const HorizontalScrollCarousel2 = () => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
      target: targetRef,
    });
  
    const x = useTransform(scrollYProgress, [0, 1], ["-20%", "40%"]); 
  
    return (
      <section ref={targetRef} className="PS_Section1">
        <div className="PS_Sticky_div">
          <motion.div style={{ x }} className="PS_Motion_div">
            <h1 className="contact_loc_title_div_h1">Locations</h1>
          </motion.div>
        </div>
      </section>
    );
  };



const Card = ({ card }) => {
  return (
    <div
      key={card.id}
      className=" PS_card_main_div"
    >
      <div
        style={{
          backgroundImage: `url(${card.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "200px",
          width: "500px",
      
        }}
        className="H_project_img_div"
      ></div>
      {/* <div className="absolute inset-0 z-10 grid place-content-center">
      </div> */}
    </div>
  );
};

export default ProjectSlider
import React from 'react'
import "./CS_aboutproject.css"
import images from '../../constants/images'

const CS_AboutProject = ({content1,content2,content3}) => {
  return (
    <div className='CS_project_main_wrapp'>
        <div className='CS_project_top'>
            <hr />
            <img src={images.CS_About} alt="" />
        </div>
        <div className='CS_project_base_wrapp'>
            <div className='CS_project_div1'>
                <h2>About <span className='CS_pro'>project</span></h2>
                <p>{content1}</p>
            </div>
            <div className='CS_project_div2'>
                <div className='CS_project_div2_left'>
                    <h2>Problem</h2>
                    <p>{content2}</p>
                </div>
                <div className='CS_project_div2_right'>
                    <h2>Solution</h2>
                    <p>{content3}</p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default CS_AboutProject
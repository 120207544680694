import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const UIUXDesign = () => {
    const hashtagall = [
        { hashtagname: "#UXDesign" },
        { hashtagname: "#UIDesign" },
        { hashtagname: "#UserExperience" },
        { hashtagname: "#UserInterface" },
        { hashtagname: "#DesignThinking" },
        { hashtagname: "#InteractionDesign" },
        { hashtagname: "#ProductDesign" },
    ];
    // FAQ
    const accordions = [
        {
            question: "What does UI/UX design encompass?",
            answer: "UI/UX design focuses on both the aesthetics (UI) and functionality (UX) of your digital product. We create interfaces that are visually appealing, easy to navigate, and meet your users' needs."
        },
        {
            question: "How will your UI/UX design benefit my business?",
            answer: "A well-designed user experience can significantly improve user engagement, brand loyalty, and ultimately, conversions."
        },
        {
            question: "What's your approach to UI/UX design?",
            answer: "We believe in a collaborative process. We work closely with you to understand your target audience, goals, and brand vision. This allows us to craft a UI/UX experience that's both user-friendly and strategically aligned."
        },
        {
            question: "Do you offer UI/UX design for mobile apps?",
            answer: "Absolutely! Our expertise extends to designing intuitive and user-friendly mobile experiences."
        },
        {
            question: "Can I see some examples of your UI/UX design work?",
            answer: "Head over to our portfolio section to explore how we've helped other businesses create exceptional user experiences!",
        },
        {
            question: "How can I get started with UI/UX design for my project?",
            answer: "Contact us today for a free consultation! We'd love to discuss your project and see how we can help you create a winning UI/UX experience.",
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Analyse",
                op: "+",
                content: "Understand user needs and business goals through thorough research.",
                isOpen: false
            },
            {
                title: "Design",
                op: "+",
                content: "Craft intuitive and engaging user interfaces that align with user expectations.",
                isOpen: false
            },
            {
                title: " Prototype",
                op: "+",
                content: "Create interactive prototypes to visualize and refine design concepts.",
                isOpen: false
            },
            {
                title: "Test",
                op: "+",
                content: "Conduct rigorous usability testing to ensure seamless user experiences.",
                isOpen: false
            },
            {
                title: "Refine",
                op: "+",
                content: "Iterate on designs based on feedback and testing results to perfect the experience.",
                isOpen: false
            },
            {
                title: "Launch",
                op: "+",
                content: "Deploy the final product, ensuring a smooth and successful rollout.",
                isOpen: false
            },
            {
                title: "Delight",
                op: "+",
                content: "Exceed user expectations with a polished and enjoyable interface.",
                isOpen: false
            },
            {
                title: "Repeat",
                op: "+",
                content: "Continuously improve by cycling through these steps to keep innovating.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };
    const imagesui = images.UI_UX_Design;
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="UI/UX Design" />
            <div className="paras4"><h1>
                Welcome to the world of Binary Geckos UI/UX design!
                We're obsessed with crafting intuitive interfaces that feel as natural as a gecko navigating a wall.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our design odyssey:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding
                title1="Gecko-Gripping User Experiences: We design interfaces that users can't put down."
                title2="UI/UX that Climbs Conversions: Boost engagement and drive results with user-centric design."
                image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default UIUXDesign
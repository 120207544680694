import React, { useEffect } from 'react'
import images from '../../constants/images'
import "./comingsoon.css"

const ComingSoon = () => {
    useEffect(() => {
        window.scrollTo({top: 0,behavior: "instant"})
       }, [])
  return (
    <div className='comingsoon_div'>
        <img src={images.comingsoon_img} alt="" />
    </div>
  )
}

export default ComingSoon
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./CareerOpenings.css";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import { IoIosArrowRoundForward } from "react-icons/io";

const CareerOpenings = () => {
  const [openIndexes, setOpenIndexes] = useState([]);
  const navigate = useNavigate();

  const toggleAccordion = (index) => {
    setOpenIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  const handleApplyNow = (job) => {
    navigate(`/job/${job.id}`); // Navigate to the job details page with jobId in URL
  };

  const accordionData = [
    {
      title: 'Front End (1)',
      content: [
        // {
        //   id: 'Business-Analyst',
        //   text: 'Business Analyst',
        //   duration: '6+',
        //   postedDate: '19/04/2024',
        //   type: 'Full time',
        //   responsibilities: [
        //     'Collaborating with various departments and stakeholders in the company to understand the current business scenario, challenges and analytical requirements',
        //     'Gathering information for business intelligence projects, analysing the same to find any trends/ loopholes that might have an effect on the team’s/ company’s strategies/growth, and providing actionable recommendations based on data analysis.',
        //     'Creating new SOPs and plans for data collection and analysis.',
        //     'Refining the system for company-wide reporting of business/performance metrics.',
        //     'Being a dedicated POC for all the backend teams like Finance, HR, BD and Legal for their designated team/ POD spread across departments (Integrated Solutions, Schbang Motion Pictures, Business Transformation (Seo, Tech, Content, CRM, Marketing Automation) Influencer Marketing, Media. ',
        //     'You will be working on standalone business intelligence projects assigned to you by the central BI team across different departments in the company.'
        //   ],
        //   skills: [
        //     '1 year experience in data reporting & business analytics',
        //     'Freshers with an educational background (i.e. graduation) in finance are welcome to apply. ',
        //     'Robust understanding of MS Excel & experience working in Google Sheets.',
        //     'Ability to turn large amounts of raw data into actionable information.',
        //     'Need people with qualifications like BAF, MBA in Finance or Marketing, BBA etc.'
        //   ],
        //   location: 'Mumbai, India',
        //   link: '/Jobdetails', // Generic link to job details page
        // },
        {
          id: 'Reactjs',
          text: 'React.js Developer',
          duration: '2+',
          postedDate: '1/06/2024',
          type: 'Full time',
          responsibilities: [
            'Develop and maintain web applications',
            'Collaborate with cross-functional teams',
          ],
          skills: [
            'Proficiency in JavaScript and React',
            'Experience with RESTful APIs',
          ],
          location: 'Bangalore, India',
          link: '/Jobdetails', // Generic link to job details page
        },
      ],
    },
    // {
    //   title: 'Back End (1)',
    //   content: [
    //     {
    //       id: 'LaravelDeveloper',
    //       text: 'Laravel Developer',
    //       duration: '5+',
    //       postedDate: '01/06/2024',
    //       type: 'Part time',
    //       responsibilities: [
    //         'Plan and execute marketing campaigns',
    //         'Analyze market trends and competitors',
    //       ],
    //       skills: [
    //         'Strong communication and analytical skills',
    //         'Experience in digital marketing',
    //       ],
    //       location: 'Delhi, India',
    //       link: '/Jobdetails', 
    //     },
    //   ],
    // },
    // {
    //   title: 'Design (1)',
    //   content: [
    //     {
    //       id:'UI-UX',
    //       text: 'UI/UX',
    //       duration: '5+',
    //       postedDate: '01/06/2024',
    //       type: 'Part time',
    //       responsibilities: [
    //         'Plan and execute marketing campaigns',
    //         'Analyze market trends and competitors',
    //       ],
    //       skills: [
    //         'Strong communication and analytical skills',
    //         'Experience in digital marketing',
    //       ],
    //       location: 'Delhi, India',
    //       link: '/Jobdetails', 
    //     },
    //   ],
    // },
  ];

  return (
    <div className='CO_main_wrapp'>
      <div className='CO_base_wrapp'>
        <h1>Current Openings</h1>
        <p>Become part of a high-performing & collaborative team</p>
        <div className='CO_accordian'>
          {accordionData.map((item, index) => (
            <div key={index} className='CO_accordian_div'>
              <div className='CO_accordian_header' >
                <p onClick={() => toggleAccordion(index)}>{item.title}</p>
                <button  onClick={() => toggleAccordion(index)}>
                  {openIndexes.includes(index) ? <SlArrowUp /> : <SlArrowDown />}
                </button>
              </div>
              {openIndexes.includes(index) && (
                <div className='acordian_content'>
                  {item.content.map((job, subindex) => (
                    <div key={subindex} className='CO_accordian_inner'>
                      <div className='CO_acco_text' onClick={() => handleApplyNow(job)}>
                        <p>{job.text}</p>
                      </div>
                      <div className='CO_acco_exp'>
                        <p>{job.duration} Years Of Experience</p>
                      </div>
                      <div className='CO_acco_btn'>
                        <button onClick={() => handleApplyNow(job)}>
                          <IoIosArrowRoundForward fontSize={"25px"} /> Apply Now
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CareerOpenings;

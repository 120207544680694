import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const DigitalMarketting = () => {
    const imagesui = images.Digital_Marketing;
    const hashtagall = [
        { hashtagname: "#DigitalMarketing" },
        { hashtagname: "#SocialMediaMarketing" },
        { hashtagname: "#ContentMarketing" },
        { hashtagname: "#SEO" },
        { hashtagname: "#OnlineMarketing" },
        { hashtagname: "#DigitalStrategy" },
        { hashtagname: "#MarketingTips" },
    ];
    // FAQ
    const accordions = [
        { question: "What types of digital marketing services do you offer?", answer: "We offer a comprehensive suite of digital marketing services, including SEO, content marketing, social media marketing, paid advertising (PPC), email marketing, and marketing automation." },
        { question: "How do you develop my digital marketing strategy?", answer: "We work closely with you to understand your target audience, business goals, and budget. We then develop a data-driven strategy that leverages the most effective channels to reach your audience and achieve your objectives" },
        { question: "What is the role of content marketing in my digital strategy?", answer: "High-quality, engaging content is crucial for attracting and retaining audiences. We help you create valuable content that informs, educates, and resonates with your target market." },
        { question: "How do you measure the success of my digital marketing campaigns?", answer: "We use advanced analytics tools to track key metrics like website traffic, engagement rates, conversions, and ROI. We  regularly analyze data and optimize campaigns for continuous improvement" },
        { question: "Can I integrate social media marketing with other channels?", answer: "Absolutely! We create a cohesive omnichannel marketing strategy that ensures a consistent brand message across all platforms, social media being a key component." },
        { question: "How can I stay informed about the ever-changing digital marketing landscape?", answer: "We'll keep you updated on the latest trends and best practices. We also provide regular reports and insights to ensure you're always in the loop." },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Project Kickoff & Marketing Goal Alignment",
                op: "+",
                content: "We kick off every project by aligning with your marketing goals and objectives. Understanding your business objectives helps us tailor our strategies to drive the results you want to achieve.",
                isOpen: false
            },
            {
                title: "Target Audience Research & Competitive Analysis",
                op: "+",
                content: "We conduct in-depth research to understand your target audience and analyze competitors. This helps us develop strategies that resonate with your audience and stay ahead in the competitive landscape.",
                isOpen: false
            },
            {
                title: "Digital Marketing Strategy Development",
                op: "+",
                content: "Based on our research, we develop a comprehensive digital marketing strategy tailored to your business. This strategy outlines the approach across various channels to achieve your marketing goals effectively.",
                isOpen: false
            },
            {
                title: "Omnichannel Marketing Planning & Campaign Creation",
                op: "+",
                content: "We plan omnichannel marketing campaigns that integrate various channels such as social media, email, content, and paid advertising. Our campaigns are designed to reach your audience at every touchpoint.",
                isOpen: false
            },
            {
                title: "Content Marketing & SEO Optimization",
                op: "+",
                content: "We create valuable content optimized for search engines to improve your online visibility and drive organic traffic. Our content strategy focuses on providing value to your audience while boosting SEO.",
                isOpen: false
            },
            {
                title: "Paid Advertising & Social Media Marketing",
                op: "+",
                content: "We execute targeted paid advertising campaigns across platforms like Google Ads, Facebook, Instagram, and LinkedIn to reach your audience and drive conversions. Social media marketing strategies engage your audience and build brand awareness.",
                isOpen: false
            },
            {
                title: "Marketing Automation & Email Marketing",
                op: "+",
                content: "We implement marketing automation tools to streamline processes and nurture leads efficiently. Email marketing campaigns are designed to engage your audience, drive conversions, and build customer loyalty.",
                isOpen: false
            },
            {
                title: "Analytics & Performance Tracking",
                op: "+",
                content: "We track key performance metrics to measure the success of our campaigns. Analytics help us understand what's working and what needs improvement, allowing us to make data-driven decisions.",
                isOpen: false
            },
            {
                title: "Campaign Optimization & Reporting",
                op: "+",
                content: "We continuously optimize campaigns based on performance data to maximize results. Detailed reports provide insights into campaign effectiveness and ROI, keeping you informed of progress.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Digital Marketing" />
            <div className="paras4"><h1>
                In today's digital landscape, a strong presence isn't enough.  At Binary Geckos, we're your digital marketing strategists, crafting data-driven campaigns that propel your brand to new heights. We climb the digital ladder rung by rung, reaching your target audience, igniting engagement, and driving real results for your business.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step digital marketing process:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Click: We Drive Results that Climb the Charts." 
            title2="Reach New Heights: Binary Geckos Owns Your Digital Marketing Journey."
            image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default DigitalMarketting
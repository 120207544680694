import React from 'react';
import './jobdetaildescription.css';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../GlobalStateContext';

const JobDetailDescription = ({ jobDetails }) => {
  const { setScrollPosition } = useGlobalState();
  if (!jobDetails) return null; // Handle the case where jobDetails is not available
  const handleApplyNowClick = () => {
    setScrollPosition(3);
  };
  return (
    <div className='jb_descrip_main_wrapp'>
      <div className="jb_descrip_base_wrapp">
        <h1>Job Description</h1>
        <h2>
          {/* <b>About the Role : </b> */}
          {jobDetails.role_job}</h2>
        <h2>You will be responsible for: </h2>
        <div className="jb_des">
          <ul>
            {jobDetails.responsibilities.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <h2>Skills/Experience Required:</h2>
        <div className="jb_skill">
          <ul>
            {jobDetails.skills.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <h2>Location: {jobDetails.location}</h2>
        <h1>Join the team</h1>
        <h2>Thank you for your interest in Binary Geckos. We look forward to reviewing your application.</h2>
        <Link to={"/ContactUs"} state={{ scroll: 'true' }} onClick={handleApplyNowClick}>
        <button>
          Apply Now
        </button>
        </Link>
      </div>
    </div>
  );
};

export default JobDetailDescription;

import React, { useState, useEffect } from "react";
import "./Navbar.css"; // Import your CSS file for styling
import { Link, useLocation } from "react-router-dom";
import images from "../../constants/images";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn";
import { Menu, MenuItem, ProductItem } from "./Navbar-menu";
import { SlArrowRight } from "react-icons/sl";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const location = useLocation();
  const [getsidebarOpen1, setSidebarOpen1] = useState(false);


  const [active, setActive] = useState(null);

  // solution sidebar menu submenus
  const [isSideDropdownOpen, setSideIsDropdownOpen] = useState(false);
 

  const [isdesignserviceOpen, setdesignserviceOpen] = useState(false);
  const handleDesignOpen = () => {
    setdesignserviceOpen(!isdesignserviceOpen);

  };
  const [istechnologyserviceOpen, settechnologyserviceOpen] = useState(false);
  const handletechnologyOpen = () => {
    settechnologyserviceOpen(!istechnologyserviceOpen);

  };
  const [isbrandingserviceOpen, setbrandingserviceOpen] = useState(false);
  const handlebrandingOpen = () => {
    setbrandingserviceOpen(!isbrandingserviceOpen);

  };
  const [ismrketingserviceOpen, setmrketingserviceOpen] = useState(false);
  const handlemarketingOpen = () => {
    setmrketingserviceOpen(!ismrketingserviceOpen);

  };




  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState("main");

  const handleChange1 = () => {
    setSidebarOpen1(false);
    setCurrentMenu("main");
  };

  const handleMenuChange = (menu) => {
    setCurrentMenu(menu);
  };

  const toggleSidebar = () => {
    if (getsidebarOpen1) {
      setSidebarOpen1(false);
      setCurrentMenu("main");
    } else {
      setSidebarOpen1(true);
    }
  };


  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <>
        <div className="navbar-content">
          <Link to={"/"}>
          <img src={images.logo} alt="" className="nav_logo" />
          </Link>
          <ul className="nav-links">
            <li>
              <Link
                to="/"
                className="nav_single_link"
                style={{
                  // borderTop: location.pathname === "/" ? "4px solid" : "0px",
                  // borderImage: location.pathname === "/" ? "linear-gradient(90deg, #5691E9 0%, #00A0A7 100%)" : "none",
                  // borderImageSlice: location.pathname === "/" ? "1" : "0",
                  // paddingTop: location.pathname === "/" ? "17px" : "0"
                  fontWeight:location.pathname ==="/" ? "600" :"500"
                }}
              >
                Home
              </Link>
            </li>
            <li>
              
              <MenuItem setActive={setActive} active={active} item="Solutions"
               style={{
                borderTop: active === "Solutions" ? "4px solid" : "0px",
                borderImage: active === "Solutions" ? "linear-gradient(90deg, #5691E9 0%, #00A0A7 100%)" : "none",
                borderImageSlice: active === "Solutions" ? "1" : "0",
                paddingTop: active === "Solutions" ? "17px" : "0"
              }}
              >
                <div className="text-sm grid grid-cols-2 gap-10 p-4">
                  <ProductItem
                    title="Design"
                    href="/Design"
                    src={images.design_ser}
                    setActive={setActive}
                    description={[
                      { text: "UI/UX Design", href: "/UIUXDesign" },
                      { text: "Web Development", href: "/Design-WebDevelopment" },
                      { text: "Mobile Experience", href: "/MobileExperience" },
                    ]}
                  />
                  <ProductItem
                    title="Branding"
                    href="/Branding"
                    src={images.branding_ser}
                    setActive={setActive}
                    description={[
                      { text: "Elevate Branding", href: "/ElevateBranding" },
                      { text: "Brand Consulting", href: "/BrandConsulting" },
                      {text: "Logo Design",href:"/LogoDesign"},
                      { text: "Industrial / Product Design", href: "/IndustrialDesign" },
                      { text: "Graphic Design", href: "/GraphicDesign" },
                      // { text: "2D / 3D Visualization", href: "/2D-3DVisualization" },
                    ]}
                  />
                  <ProductItem
                    title="Technology"
                    href="/Technology"
                    src={images.technology_ser}
                    setActive={setActive}
                    description={[
                      { text: "Data & Analytics", href: "/DataAnalytics" },
                      // { text: "Web Development", href: "/WebDevelopment" },
                      { text: "Mobile App Development", href: "/MobileAppDevelopment" },
                      { text: "Quality Assurance & Testing", href: "/QualityAssurance&Testing" },
                      { text: "Software Solutions", href: "/SoftwareSolutions" },
                    ]}
                  />
                  <ProductItem
                    title="Marketing"
                    href="/Marketing"
                    src={images.develop_ser}
                    setActive={setActive}
                    description={[
                      { text: "Digital Marketing", href: "/DigitalMarketing" },
                      { text: "Social Media Management", href: "SocialMediaManagement" },
                      { text: "Performance Marketing", href: "/PerformanceMarketing" },
                      { text: "Content Marketing", href: "/ContentMarketing" },
                      { text: "Marketing Automation", href: "/MarketingAutomation" },
                      { text: "Marketing Analytics", href: "/MarketingAnalysis" },
                    ]}
                  />
                </div>
              </MenuItem>
            </li>
            <li>
              <Link
                to="/AboutUs"
                className="nav_single_link"
                style={{
                  // borderTop: location.pathname === "/AboutUs" ? "4px solid" : "0px",
                  // borderImage: location.pathname === "/AboutUs" ? "linear-gradient(90deg, #5691E9 0%, #00A0A7 100%)" : "none",
                  // borderImageSlice: location.pathname === "/AboutUs" ? "1" : "0",
                  // paddingTop: location.pathname === "/AboutUs" ? "17px" : "0"
                  fontWeight:location.pathname ==="/AboutUs" ? "600" :"500"
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/Careers"
                className="nav_single_link"
                style={{
                  // borderTop: location.pathname === "/Careers" ? "4px solid" : "0px",
                  // borderImage: location.pathname === "/Careers" ? "linear-gradient(90deg, #5691E9 0%, #00A0A7 100%)" : "none",
                  // borderImageSlice: location.pathname === "/Careers" ? "1" : "0",
                  // paddingTop: location.pathname === "/Careers" ? "17px" : "0"
                  fontWeight:location.pathname ==="/Careers" ? "600" :"500"
                }}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                to="/Insight"
                className="nav_single_link"
                style={{
                  // borderTop: location.pathname === "/Insight" ? "4px solid" : "0px",
                  // borderImage: location.pathname === "/Insight" ? "linear-gradient(90deg, #5691E9 0%, #00A0A7 100%)" : "none",
                  // borderImageSlice: location.pathname === "/Insight" ? "1" : "0",
                  // paddingTop: location.pathname === "/Insight" ? "17px" : "0"
                  fontWeight:location.pathname ==="/Insight" ? "600" :"500"
                }}
              >
                Insights
              </Link>
            </li>
            <li>
              <Link
                to="/Projects"
                className="nav_single_link"
                style={{
                  // borderTop: location.pathname === "/Projects" ? "4px solid" : "0px",
                  // borderImage: location.pathname === "/Projects" ? "linear-gradient(90deg, #5691E9 0%, #00A0A7 100%)" : "none",
                  // borderImageSlice: location.pathname === "/Projects" ? "1" : "0",
                  // paddingTop: location.pathname === "/Projects" ? "17px" : "0"

                  fontWeight:location.pathname ==="/Projects" ? "600" :"500"
                }}
              >
                Projects
              </Link>
            </li>
          </ul>
          <div className="Nav_btn_toggle_wrap">
            <Link
              to="/ContactUs"
              onClick={handleChange1}
            >
             <button className="nav-getintouch-btn">
              {/* Lets Talk */}
              </button>
            </Link>
            <input
              id="checkbox"
              type="checkbox"
              checked={getsidebarOpen1}
              onChange={toggleSidebar}
            />
            <label className="toggle" htmlFor="checkbox">
              <div id="bar1" className="bars"></div>
              <div id="bar2" className="bars"></div>
              <div id="bar3" className="bars"></div>
            </label>

      {/* ----------- sidemenu start ------------ */}
      <div
        className={`nav_sidebar_wrapp`}
        style={{
          width: getsidebarOpen1 ? "100vw" : "0px",
          height: getsidebarOpen1 ? "90vh" : "0px",
          transition: "height 0.5s ease",
          overflow: "scroll",
        }}
      >
        {currentMenu === "main" && (
          <div className="navbar_inner_wrapp">
            <Link to="/" onClick={handleChange1}>
              Home
            </Link>
            <div className="solution_drop" onClick={() => handleMenuChange("solutions")}>
              <p>Solutions</p>
              <p>
                <SlArrowRight />
              </p>
            </div>
            <Link to="/AboutUs" onClick={handleChange1}>
              About Us
            </Link>
            <Link to="/Careers" onClick={handleChange1}>
              Careers
            </Link>
            <Link to="/Insight" onClick={handleChange1}>
              Insights
            </Link>
            <Link to="/Projects" onClick={handleChange1} >
              Projects
            </Link>
            {/* <div className="btn-div-side">
            <Link
              to="/ContactUs"
              className="btn-gradiant1"
              onClick={handleChange1}
            >
              <span className="btn-text">Get in Touch</span>
            </Link>
            </div> */}
          </div>
        )}
        {currentMenu === "solutions" && (
          <div className="navbar_solution_menu">
            <div className="navbar_inner_wrapp">
              <div className="solution_drop" onClick={() => handleMenuChange("Design")}>
                <span>Design</span>
                <SlArrowRight />
              </div>
              <div className="solution_drop" onClick={() => handleMenuChange("Branding")}>
                <span>Branding</span>
                <SlArrowRight />
              </div>
              <div className="solution_drop" onClick={() => handleMenuChange("Technology")}>
                <span>Technology</span>
                <SlArrowRight />
              </div>
              <div className="solution_drop" onClick={() => handleMenuChange("Marketing")}>
                <span>Marketing</span>
                <SlArrowRight />
              </div>
            </div>
          </div>
        )}
        {currentMenu === "Design" && (
          <div className="navbar_inner_wrapp">
            <Link to="/Design" onClick={handleChange1} className="overview_nav_btn">
              Overview
            </Link>
            <Link to="/UIUXDesign" onClick={handleChange1}>
              UI/UX
            </Link>
            <Link to="/Design-WebDevelopment" onClick={handleChange1}>
              Web Development
            </Link>
            <Link to="/MobileExperience" onClick={handleChange1}>
              Mobile Experience
            </Link>
          </div>
        )}
        {currentMenu === "Branding" && (
          <div className="navbar_inner_wrapp">
            <Link to="/Branding" onClick={handleChange1} className="overview_nav_btn">
              Overview
            </Link>
            <Link to="/ElevateBranding" onClick={handleChange1}>
              Elevate Branding
            </Link>
            <Link to="/BrandConsulting" onClick={handleChange1}>
              Brand Consulting
            </Link>
            <Link to="/LogoDesign" onClick={handleChange1}>
              Logo Design
            </Link>
            <Link to="/IndustrialDesign" onClick={handleChange1}>
              Industrial/Product Design
            </Link>
            <Link to="/GraphicDesign" onClick={handleChange1}>
              Graphic Design
            </Link>
            {/* <Link to="/2D-3DVisualization" onClick={handleChange1}>
              2D/3D Visualization
            </Link> */}
          </div>
        )}
        {currentMenu === "Technology" && (
          <div className="navbar_inner_wrapp">
            <Link to="/Technology" onClick={handleChange1} className="overview_nav_btn">
              Overview
            </Link>
            <Link to="/DataAnalytics" onClick={handleChange1}>
              Data Analytics
            </Link>
            {/* <Link to="/WebDevelopment" onClick={handleChange1}>
              Web Development
            </Link> */}
            <Link to="/MobileAppDevelopment" onClick={handleChange1}>
              Mobile App Development
            </Link>
            <Link to="/QualityAssurance&Testing" onClick={handleChange1}>
              Quality Assurance & Testing
            </Link>
            <Link to="/SoftwareSolutions" onClick={handleChange1}>
              Software Solutions
            </Link>
          </div>
        )}
        {currentMenu === "Marketing" && (
          <div className="navbar_inner_wrapp">
            <Link to="/Marketing" onClick={handleChange1} className="overview_nav_btn">
              Overview
            </Link>
            <Link to="/DigitalMarketing" onClick={handleChange1}>
              Digital Marketing
            </Link>
            <Link to="/SocialMediaManagement" onClick={handleChange1}>
              Social Media Management
            </Link>
            <Link to="/PerformanceMarketing" onClick={handleChange1}>
              Performance Marketing
            </Link>
            <Link to="/ContentMarketing" onClick={handleChange1}>
              Content Marketing
            </Link>
            <Link to="/MarketingAutomation" onClick={handleChange1}>
              Marketing Automation
            </Link>
            <Link to="/MarketingAnalysis" onClick={handleChange1}>
              Marketing Analytics
            </Link>
          </div>
        )}
      </div>
      </div>
      </div>
        {/* ---------solution menu end------------ */}
      </>
    </nav>
  );
};

export default Navbar;

import React from 'react'
import "./Service4_banner.css"
import images from '../../constants/images'

const Service4_banner = ({ title }) => {
  return (
    <div className='Service4_banner_main'>
      <div className="s4_left">
        <div>{title}</div>
      </div>
      <div className="s4_right">
        <img src={images.gauze} alt="" loading='lazy'/>
      </div>
    </div>
  )
}

export default Service4_banner
import React from 'react'
import "./cs_appmap.css"
import images from '../../constants/images'

const CS_AppMap = ({ imageSrc }) => {
  return (
    <div className='cs_appmap_main_wrapp'>
        <div className='cs_appmap_base_wrapp'>
            <h1>App <span>map</span></h1>
            <div className='cs_appmap_img_div'>
                <div className='cs_appmap_circle'>
                   <img src={imageSrc} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default CS_AppMap
import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';
import { Link } from 'react-router-dom';
import './Project_anim.css';
import images from '../../constants/images';

import Project_anim from '../../container/Project_anim/Project_anim';
const Projects_main = () => {
  const [category, setCategory] = useState('All');

  const products = [
    { id:1,
      title: "Concept",
      link: "https://gokulnair.com/jalpesh/concept/",
      thumbnail: images.concept_pro,
      category: "website" 
    },
    { title: "Nasrani", link: "https://play.google.com/store/apps/details?id=com.nasranikalyanam", thumbnail: images.Nasrani_pro, category: "Application" ,id:2,},
    
    { title: "USA Intern", link: "https://usaintern.club/", thumbnail: images.USAIntern_pro, category: "website" ,id:3,},
    { title: "Dustit", link: "https://play.google.com/store/apps/details?id=com.dustitapp", thumbnail: images.p_img3, category: "Application" ,id:4,},
    { title: "Teknika", link: "https://teknikaceramiche.com/", thumbnail: images.teknika_pro, category: "website" ,id:5,},
    { title: "Rarerez", link: "https://rarerez.club/", thumbnail: images.Rarerez_pro, category: "website",id:6, },
    { title: "Nasrani", link: "https://play.google.com/store/apps/details?id=com.nasranikalyanam", thumbnail: images.Nasrani_pro, category: "Application" ,id:7,},
    { title: "USA Intern", link: "https://usaintern.club/", thumbnail: images.usaintern_img, category: "website",id:8, },
    { title: "Teknika", link: "https://teknikaceramiche.com/", thumbnail: images.teknika_pro, category: "website" ,id:9,},
    { title: "Rarerez", link: "https://rarerez.club/", thumbnail: images.Rarerez_pro, category: "website",id:10, },
    { title: "Concept", link: "https://gokulnair.com/jalpesh/concept/", thumbnail: images.concept_pro, category: "website",id:11, },
    {title: "Teknika", link: "https://teknikaceramiche.com/", thumbnail: images.teknika_pro, category: "website" ,id:12, },
    { title: "USA Intern", link: "/USAInternCasestudy", thumbnail: images.USAIntern_pro, category: "casestudy",id:13, },
    { title: "Dustit", link: "https://play.google.com/store/apps/details?id=com.dustitapp", thumbnail: images.p_img3, category: "Application" ,id:14,},
    { title: "Teknika", link: "https://teknikaceramiche.com/", thumbnail: images.teknika_pro, category: "website" ,id:15,},
  
    { title: "USA Intern", link: "https://usaintern.club/", thumbnail: images.usaintern_img, category: "website" ,id:16,},
    { title: "Rarerez", link: "/ComingSoon", thumbnail: images.Rarerez_pro, category: "casestudy",id:17, },
    { title: "Concept", link: "https://gokulnair.com/jalpesh/concept/", thumbnail: images.concept_pro, category: "website",id:18, },
    { title: "Dustit", link: "/ComingSoon", thumbnail: images.p_img3, category: "casestudy" ,id:19,},
    { title: "USA Intern", link: "/USAInternCasestudy", thumbnail: images.USAIntern_pro, category: "casestudy",id:20 },
    { title: "Teknika", link: "https://teknikaceramiche.com/", thumbnail: images.teknika_pro, category: "website" ,id:21,},
    { title: "Dustit", link: "/ComingSoon", thumbnail: images.p_img3, category: "casestudy" ,id:22,},
    { title: "USA Intern", link: "https://usaintern.club/", thumbnail: images.usaintern_img, category: "website" ,id:23,},
    { title: "USA Intern", link: "/USAInternCasestudy", thumbnail: images.USAIntern_pro, category: "casestudy",id:24, },
    { title: "Teknika", link: "https://teknikaceramiche.com/", thumbnail: images.teknika_pro, category: "website" ,id:25,},
    { title: "Rarerez", link: "/ComingSoon", thumbnail: images.Rarerez_pro, category: "casestudy" ,id:26,},
    { title: "Dustit", link: "/ComingSoon", thumbnail: images.p_img3, category: "casestudy",id:27, },
    { title: "Rarerez", link: "https://rarerez.club/", thumbnail: images.Rarerez_pro, category: "website",id:28, },
    { title: "Nasrani", link: "https://play.google.com/store/apps/details?id=com.nasranikalyanam", thumbnail: images.Nasrani_pro, category: "Application" ,id:29,},
    { title: "Dustit", link: "https://play.google.com/store/apps/details?id=com.dustitapp", thumbnail: images.p_img3, category: "Application" ,id:30,},
    { title: "Concept", link: "https://gokulnair.com/jalpesh/concept/", thumbnail: images.concept_pro, category: "website" ,id:31,},
    { title: "Nasrani", link: "https://play.google.com/store/apps/details?id=com.nasranikalyanam", thumbnail: images.Nasrani_pro, category: "Application" ,id:32,},
    { title: "Dustit", link: "https://play.google.com/store/apps/details?id=com.dustitapp", thumbnail: images.p_img3, category: "Application" ,id:33,},
    { title: "Nasrani", link: "https://play.google.com/store/apps/details?id=com.nasranikalyanam", thumbnail: images.Nasrani_pro, category: "Application" ,id:34,},
    { title: "Dustit", link: "https://play.google.com/store/apps/details?id=com.dustitapp", thumbnail: images.p_img3, category: "Application" ,id:35,},
    { title: "Nasrani", link: "https://play.google.com/store/apps/details?id=com.nasranikalyanam", thumbnail: images.Nasrani_pro, category: "Application" ,id:36,},
  ];

  return (
    <Project_anim products={products} category={category} setCategory={setCategory} />
  );
};

export default Projects_main;
import React from 'react'
import "./servicebrandingbanner.css"
import images from '../../constants/images'

const ServiceBrandingBanner = ({ onLogoClick }) => {
  return (
    <div className='ser_branding_banner_main_Wrapp'>
        <div className="ser_branding_banner_base_wrapp">
            <div className='ser_bb'>
                <h1>EXPERIENCE</h1>
                <h2>Branding</h2>
                <img 
                    src={images.branding_banner_logo} 
                    alt="Branding Logo" 
                    onClick={onLogoClick} 
                    style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate it's clickable
                />
            </div>
        </div>
    </div>
  )
}

export default ServiceBrandingBanner
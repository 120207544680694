import React from 'react';
import "./CareerBanner.css";
import images from '../../constants/images';

const CareerBanner = ({ onViewOpenPositions }) => {
  return (
    <div className='CB_main_wrapp'>
        <img src={images.career_banner_img} alt="" className=''/>
        <div className='CB_content_div'>
            <h1>The Rise of Cloud Computing: <br /> 
            How It's Revolutionizing IT Services</h1>
            <button className='careers_btn' onClick={onViewOpenPositions}>
              
            </button>
        </div>
        <div className='CB_overlay'></div>
    </div>
  );
};

export default CareerBanner;

import React, { useEffect } from 'react'
import "./service2sec3.css"

const Service2Sec3 = ({ titleservice }) => {
  useEffect(() => {
    console.log(titleservice); // This should now correctly log the image URL
  }, [titleservice]);
  return (
    <div className='service_sec3_main_wrapp'>
      <div className='service_sec3_base_wrapp'>
        <h2>
          {titleservice}
        </h2>
      </div>
    </div>
  )
}

export default Service2Sec3
import React from 'react'
import "./cs_support.css"
import images from '../../constants/images'

const CS_Support = ({title1,title2,title3}) => {
  return (
    <div className='cs_support_main_wrapp'>
        <img src={images.cs_result} alt=""  className='cs_support_back_img'/>
        <div className='cs_support_base_wrapp'>
            <h1>{title1}</h1>
            <h2>{title2}</h2>
            <h3>{title3}</h3>
        </div>
    </div>
  )
}

export default CS_Support
import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const BrandConsulting = () => {
    const imagesui = images.BrandConsulting;
    const hashtagall = [
        { hashtagname: "#BrandConsulting" },
        { hashtagname: "#BrandStrategy" },
        { hashtagname: "#BrandAdvisor" },
        { hashtagname: "#BrandConsultant" },
        { hashtagname: "#BrandDevelopment" },
        { hashtagname: "#BrandManagement" },
        { hashtagname: "#BrandTransformation" },
    ];
    // FAQ
    const accordions = [
        {
            question: "What's the difference between branding and brand consulting?",
            answer: "Branding focuses on creating the visual and messaging elements of your brand identity. Brand consulting takes a broader approach, analysing your brand strategy, market position, and customer experience to ensure your brand is aligned with your business goals."
        },
        {
            question: "How can brand consulting benefit my business?",
            answer: "Brand consulting can help you gain clarity on your brand vision, strengthen your market position, build stronger customer connections, and ultimately drive business growth."
        },
        {
            question: "Is brand consulting a one-time service?",
            answer: "Brand consulting can be a one-time project or an ongoing engagement. We can tailor a package to suit your specific needs and budget."
        },
        {
            question: "What types of businesses can benefit from brand consulting?",
            answer: "Businesses of all sizes, from startups to established companies, can benefit from brand consulting. It's especially valuable if you're undergoing a rebrand, experiencing competitive pressure, or seeking to refine your brand strategy."
        },
        {
            question: "What are some deliverables from brand consulting?",
            answer: "Deliverables can include a brand strategy document, brand positioning statement, messaging framework, customer experience map, and brand activation plan.",
        },
        {
            question: "How can I get started with brand consulting?",
            answer: "Contact Binary Geckos today for a free consultation! We'll discuss your brand challenges and goals and create a customised plan to help you achieve success.",
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Brand Audit",
                op: "+",
                content: "Our brand consulting journey begins with a comprehensive brand audit. We analyze your current brand positioning, visual identity, messaging, and overall brand perception. This helps identify strengths, weaknesses, and areas for improvement.",
                isOpen: false
            },
            {
                title: "Market Research",
                op: "+",
                content: "We conduct in-depth market research to understand your industry landscape, target audience, and competitors. This research provides valuable insights that inform strategic decisions and ensure your brand stands out in the market.",
                isOpen: false
            },
            {
                title: "Brand Strategy Development",
                op: "+",
                content: "Based on the audit and research findings, we develop a robust brand strategy. This strategy outlines your brand’s vision, mission, values, and long-term goals, serving as a roadmap for all branding efforts.",
                isOpen: false
            },
            {
                title: "Brand Positioning",
                op: "+",
                content: "Effective brand positioning is crucial for differentiation. We help you define a unique position in the market that resonates with your target audience and highlights your competitive advantages.",
                isOpen: false
            },
            {
                title: "Messaging Framework",
                op: "+",
                content: "We create a messaging framework that clearly communicates your brand’s value proposition, key messages, and brand voice. This ensures consistent and compelling communication across all touchpoints.",
                isOpen: false
            },
            {
                title: "Brand Architecture",
                op: "+",
                content: "For businesses with multiple products or services, we develop a cohesive brand architecture. This structure organizes your offerings under a unified brand, ensuring clarity and synergy within your brand portfolio.",
                isOpen: false
            },
            {
                title: "Customer Experience Mapping",
                op: "+",
                content: "We map out the customer journey to understand how your audience interacts with your brand. This helps identify touchpoints where you can enhance the customer experience and foster stronger brand loyalty.",
                isOpen: false
            },
            {
                title: "Brand Activation Strategy",
                op: "+",
                content: "We create a strategic plan to activate your brand across various channels. This includes marketing campaigns, digital presence, events, and other initiatives to engage your audience and drive brand awareness.",
                isOpen: false
            },
            {
                title: "Brand Measurement & Optimization",
                op: "+",
                content: "Post-activation, we measure the effectiveness of your brand strategy using key performance indicators (KPIs). We continuously analyze data and feedback to optimize and refine your branding efforts, ensuring sustained success.",
                isOpen: false
            }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Brand Consulting" />
            <div className="paras4"><h1>
                Feeling lost in the brand jungle? At Binary Geckos, we're your expert guides. Our brand consulting services provide a strategic roadmap to help you navigate the complexities of brand development.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our brand consulting ascent:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Surface: We Build Brands with Strategic Depth."
                title2="Chart Your Brand Course: Navigate the Market with Confidence."
                image={imagesui}
            />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default BrandConsulting
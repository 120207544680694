import React from 'react'
import "./Section2_banner.css"
import images from '../../constants/images'

const Section2_banner = () => {
  return (
    <div className="techinsight_main_wrapp">
      <div className='techinsight_img_wrapp'> 
        <img src={images.insightbanner} alt="" className='insight_banner_img'/>
      </div>
      <h2 className='techinsight_text'>Tech Insights</h2>
    </div>
  )
}

export default Section2_banner

import React from "react";
import "./HomeOurStory.css";
import images from "../../constants/images";
import TextGenerateEffect from "../../components/TextGenerateEffect";
import { Link } from "react-router-dom";
import IconCloud from "../../components/IconCloud";
import Globe from "../../components/Glob";



const HomeOurStory = () => {
  // const words ="We're a collective of Digital Alchemists, constantly adapting and evolving to craft the future. We blend cutting-edge tech with captivating design to help your brand thrive in the ever-changing digital landscape."

  const slugs = [
    "typescript",
    "javascript",
    "dart",
    "java",
    "react",
    "flutter",
    "android",
    "html5",
    "css3",
    "nodedotjs",
    "express",
    "nextdotjs",
    "prisma",
    "amazonaws",
    "postgresql",
    "firebase",
    "nginx",
    "vercel",
    "testinglibrary",
    "jest",
    "cypress",
    "docker",
    "git",
    "jira",
    "github",
    "gitlab",
    "visualstudiocode",
    "androidstudio",
    "sonarqube",
    "figma",
  ];
  return (
    <div className="home_os_main_wrapp">
      <div className="home_os_base_wrapp">
        <h2 className="sec_heading1">Our Story</h2>
        <p className="home_os_main_des ">
        We're a collective of Digital Alchemists, constantly adapting and evolving to craft the future. We blend cutting-edge tech with captivating design to help your brand thrive in the ever-changing digital landscape.
        </p>

        {/* <div className="home_os_two_sec_wrapp">
          <div className="home_os_two_sec_wrapp_1">
            <p className="home_os_two_sec_wrapp_1_first_text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor{" "}
            </p>
            <img
              src={images.home_os_graph}
              className="home_os_graph_img"
              alt=""
            />
            <p className="home_os_two_sec_wrapp_1_sec_text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris.
            </p>
          </div>
          <div className="home_os_two_sec_wrapp_2">
            <p
              className="home_os_two_sec_wrapp_1_first_text"
              style={{ color: "white" }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <div className="home_os_two_sec_wrapp_2_center_sec">
              <div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
              <img src={images.home_grid_7} alt="" />
            </div>

            <p
              className="home_os_two_sec_wrapp_1_sec_text"
              style={{ color: "white" }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris.
            </p>
          </div>
        </div> */}

        {/* Glob Animation start*/}

          <div className="home_os_glob_div">
            <div className="home_os_globs">
              <div className="relative flex h-full w-full  items-center justify-center overflow-hidden rounded-lg bg-background px-20 pb-20 pt-8 bg-[radial-gradient(circle_at_50%_200%,rgba(0,0,0,0.2),rgba(255,255,255,0))] md:shadow-xl">
                <IconCloud iconSlugs={slugs} />
              </div>
            </div>

            {/* <div className="home_os_globs glob2">
              <div className="relative flex h-full w-full  items-center justify-center overflow-hidden rounded-lg  bg-background px-40 pb-40 pt-8 md:pb-60 md:shadow-xl">
                <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-gray-300/80 bg-clip-text text-center text-8xl font-semibold leading-none text-transparent dark:from-white dark:to-slate-900/10 glob-text">
                  Globe
                </span>
                <Globe className="top-28" />
                <div className="pointer-events-none absolute inset-0 h-full bg-[radial-gradient(circle_at_50%_200%,rgba(0,0,0,0.2),rgba(255,255,255,0))]" />
              </div>
            </div> */}
          </div>
        {/* glob animation end */}
        <div className="home_os_two_sec_wrapp  home_os_2">
          <div className="home_os_two_sec_wrapp_2_2">
            <Link to="/AboutUs">
                <button className="contactus-button">
                    {/* Lets Talk */}
                </button>
            </Link>
          
            <p>Advance your revenue and advance your business</p>
          </div>
          <div className="home_os_two_sec_wrapp_2_3">
            <p className="home_os_two_sec_P">Through collaboration and gecko-like perseverance, we tackle complex projects and deliver results that pack a punch.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOurStory;

import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const ContentMarketing = () => {
    const imagesui = images.Content_Marketing;
    const hashtagall = [
        { hashtagname: "#ContentMarketing" },
        { hashtagname: "#ContentStrategy" },
        { hashtagname: "#DigitalMarketing" },
        { hashtagname: "#ContentCreation" },
        { hashtagname: "#ContentWriting" },
        { hashtagname: "#ContentStrategy" },
        { hashtagname: "#ContentTips" },
    ];

    const accordions = [
        { question: "Why is content marketing important for my business?", answer: "High-quality content informs, educates, and entertains your target audience, building trust and establishing your brand as an authority in your industry. This ultimately leads to increased brand awareness, lead generation, and customer acquisition." },
        {
            question: "What types of content marketing services do you offer?",
            answer: "We create a diverse range of content, including blog posts, articles, website copy, infographics, videos, white papers, case studies, and e-books. We tailor content to your specific audience and marketing goals."
        },
        {
            question: "How do you ensure my content is optimized for search engines?",
            answer: "We conduct keyword research and use SEO best practices to optimize your content for search engines. This increases organic traffic and the visibility of your content."
        },
        {
            question: "How do you promote my content beyond your website?",
            answer: "We develop a comprehensive content distribution strategy that leverages social media promotion, email marketing, influencer outreach, and other channels to reach your target audience wherever they are."
        },
        { question: "How do you measure the success of my content marketing strategy?", answer: "We use advanced analytics tools to track key metrics like website traffic, engagement rates, leads generated, and conversions. This data helps us refine your content strategy for continuous improvement." },
        { question: "Can you help me repurpose my existing content?", answer: "Absolutely! We can transform existing content into different formats (e.g., infographics from blog posts) to maximize its reach and engagement across various platforms." },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            { title: "Audience Research & Content Strategy Development", op: "+", content: "Understand your audience and develop a tailored content strategy.", isOpen: false },
            { title: "Content Calendar Creation & Topic Ideation", op: "+", content: "Plan content creation and brainstorm relevant topics.", isOpen: false },
            { title: "Content Creation (Blog Posts, Articles, Videos, etc.)", op: "+", content: "Produce diverse content formats like blogs, articles, and videos.", isOpen: false },
            { title: "Content Optimization for Search Engines (SEO)", op: "+", content: "Optimize content to improve search engine visibility.", isOpen: false },
            { title: "Content Distribution & Promotion Strategy", op: "+", content: "Implement strategies to distribute and promote content effectively.", isOpen: false },
            { title: "Content Performance Measurement & Analytics", op: "+", content: "Measure and analyze content performance for insights.", isOpen: false },
            { title: "Content Repurposing & Amplification", op: "+", content: "Extend content reach through repurposing and amplification.", isOpen: false },
            { title: "Content Refresh & Optimization", op: "+", content: "Update and optimize existing content for relevance and effectiveness.", isOpen: false },
            { title: "Ongoing Content Marketing Strategy Refinement", op: "+", content: "Continuously refine content strategy based on performance and trends.", isOpen: false }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])

    return (
        <div>
            <Service4_banner title="Content Marketing" />
            <div className="paras4"><h1>
                Content is king, but quality reigns supreme. At Binary Geckos, we're your content marketing strategists, crafting compelling content that engages your audience, establishes your brand as a thought leader, and drives real business results. We climb the mountain of content creation, ensuring every piece resonates with your target audience and fuels your marketing success.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step content marketing process" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Blog Post: We Craft Content that Captivates and Converts." 
            title2="Content that Climbs the Charts: Binary Geckos Gets You Seen and Heard."
            image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default ContentMarketing
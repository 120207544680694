import React from 'react'
import "./careerwhatweoffer.css"

const CareerWhatWeOffer = () => {
    return (
        <div className='CWW_main_wrapp'>
            <div className='CWW_base_wrapp'>
                <h1>What We Offer</h1>
                <div className='CWW_content_div'>
                    <div className='CWW_content_div_left'>
                        <p>
                            Opportunities to climb the career ladder (figuratively, of course). We value growth and development, and we'll provide you with the tools and resources to reach your full potential.
                            <br /><br />
                            A competitive compensation and benefits package that's as sticky as a gecko's grip. (We won't let you go hungry... or thirsty... or bored)
                        </p>
                    </div>
                    <div className='CWW_content_div_right'>
                        <p>
                            A chance to work on cutting-edge projects and make a real impact. We don't just build websites, we build the future.
                            <br /><br />
                            A fun and collaborative work environment where you can be yourself (even if your "self" involves wearing a gecko costume to team meetings... no judgement here)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CareerWhatWeOffer
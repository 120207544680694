import React, { useEffect } from 'react'
import Service2Sec3 from '../../container/Service2Sec3/Service2Sec3'
import Service2_Whatwedo from '../../container/Service2Whatwedo/Service2_Whatwedo'
import Casestudy from '../../container/casestudy/Casestudy'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import { useNavigate } from 'react-router-dom'
import images from '../../constants/images'
import Marketing_banner from '../../container/Design_Ser_banner/Marketing_banner'
// import Service2UiUx1 from '../../container/Service2UIUX1/Service2UiUx1'

const Service2 = () => {
  const imagesui = images.Marketingbanner;
  const accordions = [
    {
      question: "What marketing services does Binary Geckos offer?",
      answer: "Binary Geckos offers a range of marketing services including digital marketing, social media management, content creation, email marketing, market research, and brand strategy."
    },
    {
      question: " How can digital marketing benefit my business?",
      answer: "Digital marketing can benefit your business by increasing online visibility, reaching a larger audience, driving website traffic, and generating leads and sales through targeted strategies."
    },
    {
      question: "Why is social media management important for businesses?",
      answer: "Social media management is important as it helps businesses build brand awareness, engage with their audience, drive website traffic, and cultivate customer relationships on popular platforms."
    },
    {
      question: "How does content creation contribute to business growth?",
      answer: "Content creation contributes to business growth by providing valuable information to your audience, establishing authority in your industry, improving SEO, and driving conversions through engaging content."
    },
    {
      question: "What is the importance of market research in marketing strategy?",
      answer: "Market research helps businesses understand their target audience, identify market trends, assess competition, and make informed decisions to tailor marketing strategies for maximum effectiveness."
    },
    {
      question: "How do I get started with Binary Geckos' marketing services?",
      answer: "To get started with our marketing services, simply reach out to us through our contact page. We'll discuss your goals, assess your needs, and tailor a strategy that fits your business objectives."
    },
  ];

  const navigate = useNavigate();
  const whatwecard = [
    {
      imgSrc: images.service2_card_img, title: "Digital Marketing:", des: "We leverage the power of the digital world to reach your target audience with laser focus.",
      path: "/DigitalMarketing"
    },
    {
      imgSrc: images.service2_card_img, title: "Social Media Management", des: "We become an extension of your team, crafting engaging content and fostering meaningful connections",
      path: "/SocialMediaManagement"
    },
    {
      imgSrc: images.service2_card_img, title: "Performance Marketing", des: "We focus on measurable results, optimizing your campaigns for maximum impact and return on investment (ROI).",
      path: "/PerformanceMarketing"
    },
    {
      imgSrc: images.service2_card_img, title: "Content Marketing", des: "We create compelling content that educates, engages, and converts your audience into loyal brand advocates.",
      path: "/ContentMarketing"
    },
    {
      imgSrc: images.service2_card_img, title: "Marketing Automation", des: "We streamline your marketing efforts with powerful automation tools, freeing you to focus on what matters most – growing your business.",
      path: "/MarketingAutomation"
    },
    {
      imgSrc: images.service2_card_img, title: "Analytics", des: "We don't just throw darts in the dark. We use data-driven insights to measure success and refine your marketing strategy for ongoing optimization.",
      path: "/MarketingAnalysis"
    },
  ];
  const handleCardClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  return (
    <div>
        <Marketing_banner/>
        <Service2Sec3 titleservice="We're here to fuel your business growth with innovative marketing strategies. Our expert team provides the tools and insights to conquer the competition and achieve your goals." />
      <Service2_Whatwedo whatwecard={whatwecard} handleCardClick={handleCardClick} details_whatwedo="We drive business growth with innovative marketing strategies, equipping you with the tools and insights to succeed in a competitive market." />
      <Service2_Boost />
      <Casestudy />
      <ServiceBranding title1="More Than Words: We Create Content that Engages and Converts."
        title2="Top-Tier Content: Binary Geckos Ensures You're Seen and Heard."
        image={imagesui}
      />
      <ServiceQuestion accordions={accordions} />
   
    </div>
  )
}

export default Service2
import React from 'react';
import "./Oursevices_s4.css";

const Oursevices_s4 = ({ service, toggleService, title, hashtagall }) => {
    return (
        <div className='ourserice_4'>
            <div className="wid">
                <div className='ser_main'>
                    <h2>Our Process</h2>
                    <h1>Here's a glimpse into {title}</h1>
                </div>
                <div className="ourserice_44">
                    <div className='ourserv_4_left'>
                        <div className='left_item'>
                            {hashtagall && hashtagall.map((hashtag, index) => (
                                <div key={index} className={`hashtag${index + 1}`}>{hashtag.hashtagname}</div>
                            ))}
                        </div>
                    </div>
                    <div className='ourserv_4_right'>
                        {service.map((service, index) => (
                            <div key={index}>
                                <div className="ser_acco" onClick={() => toggleService(index)}>
                                    <div className='ser_acco_h'>
                                        <h5>{service.title}</h5>
                                        <h3>{service.op}</h3>
                                    </div>
                                    {service.isOpen && <p className=''>{service.content}</p>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Oursevices_s4;

import React, { useState } from 'react';
import "./AboutServices.css";
import images from '../../constants/images';
import { useNavigate } from 'react-router-dom';

const AboutServices = () => {
  const navigate = useNavigate();
  const accordionData = [
    { id: "01", title: 'Design', content: "We don't just make it look good, we make it stick. User-friendly interfaces and captivating visuals that climb into your user's hearts.", img: images.design_ser_img, path: "/Design" },
    { id: "02", title: 'Technology', content: "We're not afraid to climb the tech wall. We harness the power of innovation to build seamless solutions that empower your brand.", img: images.technology_ser_img, path: "/Technology" },
    { id: "03", title: 'Branding', content: "We don't blend in, we make you stand out. Crafting compelling brand narratives and visuals that leave an indelible mark on your audience.", img: images.branding_service, path: "/Branding" },
    { id: "04", title: 'Marketing', content: "We don't just get you noticed, we make you remembered. Strategic campaigns with a data-driven grip that drive impactful results.", img: images.marketing_ser_img, path: "/Marketing" },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className='about_service_main_wrapp'>
      <div className='about_service_base_wrapp'>
        <h1 className='about_service_title'>Services</h1>
        <div className="home-question-sec2">
          <div>
            {accordionData.map((accordion, index) => (
              <div className={`accordian-main ${hoveredIndex === index ? 'open' : ''}`}
              key={index}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className='title-btn-div'>
                <div className='id'>
                  <p>{accordion.id}</p>
                </div>
                <div 
                  className='title'
                  onMouseEnter={() => setHoveredIndex(index)}
                >
                  <p>{accordion.title}</p>
                </div>
                <div 
                  className='btn'
                  onMouseEnter={() => setHoveredIndex(index)}
                >
                  {hoveredIndex === index ? (
                    <button style={{ visibility: 'hidden' }}>
                      <img src={images.service_arrow} alt="accordion-open" />
                    </button>
                  ) : (
                    <button>
                      <img src={images.service_arrow} alt="accordion-open" />
                    </button>
                  )}
                </div>
              </div>
              <div className={`accordian-del ${hoveredIndex === index ? 'open' : 'closed'}`}>
                <div className='accordian_open_div'>
                  <div className='accordian_open_div_1'>
                    <div className='accordian_open_div_1_1'>
                      <p className='accordian-del-p'>{accordion.content}</p>
                    </div>
                    <div className='accordian_open_div_1_2'>
                      <img src={accordion.img} alt="" />
                    </div>
                  </div>
                  <div className='accordian_open_div_2'>
                    <button onClick={() => handleCardClick(accordion.path)}>
                      <img src={images.home_arrow} alt="accordion-close" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutServices;


import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './contactmethod.css';
import { useGlobalState } from '../../GlobalStateContext';

const ContactMethod = () => {
  const titleControls = useAnimation();
  const detailControls = useAnimation();
  const { setScrollPosition } = useGlobalState();

  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: detailRef, inView: detailInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (titleInView) {
      titleControls.start('visible');
    }
    if (detailInView) {
      detailControls.start('visible');
    }
  }, [titleControls, detailControls, titleInView, detailInView]);

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  const handleviewposition = () => {
    setScrollPosition(1);
  };
  return (
    <div className='contact_method_main_wrapp'>
      <div className='contact_method_base_wrapp'>
        <div className='contact_method_title_div' ref={titleRef}>
          <motion.h1
            variants={itemVariants}
            initial="hidden"
            animate={titleControls}
          >
            Drop us an email to explore
            for Business <span className='grey-text'>collaborations</span> or
            career <span className='grey-text'>opportunities</span>.
          </motion.h1>
        </div>
        <motion.div 
          ref={detailRef}
          variants={itemVariants}
          initial="hidden"
          animate={detailControls}
          className='contact_method_detail'
        >
          <div className='c_m_link'>
            <Link to="mailto:info@binarygeckos.com">info@binarygeckos.com</Link>
            <p>Projects, collaborations and queries.</p>
          </div>
          <div className='c_m_link'>
            <Link to="mailto:hr@binarygeckos.com">hr@binarygeckos.com</Link>
            <p>Join our dynamic Team</p>
            <Link to="/careers" state={{ scroll: 'true' }} >
           
            <button className='contact_view_btn' onClick={handleviewposition}>View</button>
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactMethod;

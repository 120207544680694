import React, { useEffect, useState } from 'react'
import "./contactForm.css"
import images from '../../constants/images';
import axios from 'axios';
import { FormInput } from '../../components/FormInput';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineClose } from "react-icons/ai";
import { FormTextArea } from '../../components/FormTextArea';
import SearchableDropdown from '../../components/SearchableDropdown';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";
// import SearchableDropdown from './SearchableDropdown';

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const ContactForm = ({ scrollPosition }) => {

  const [inquiryType, setInquiryType] = useState(1);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedStateName, setSelectedStateName] = useState('');
  const [selectedStateId, setSelectedStateId] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [selectedCountry, setSelectedCountry] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedExperience, setSelectedExperience] = useState('');
  const [selectedSector, setSelectedSector] = useState('');

  const [loading, setLoading] = useState(false);
  const [modalopen, setmodalopen] = useState(false);

  const email_validation =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [getfname, setfname] = useState("");
  const [getlname, setlname] = useState("");
  const [getemail, setEmail] = useState("");
  const [getphone, setphone] = useState("");
  const [getcountry, setcountry] = useState("");
  const [getsub, setsub] = useState("");
  const [getprodel, setprodel] = useState("");
  const [getmessage, setMessage] = useState("");
  const [getinstitute, setinstitute] = useState("");
  const [getresume, setresume] = useState(null);
  const [getcon, setcon] = useState(false);

  const [submittedName, setSubmittedName] = useState("");



  // for country code mob
  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        const countryData = data
          .filter(country => country.idd.root) // Filter out countries without country codes
          .map((country) => ({
            name: country.name.common,
            code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
          }));
        // Filter out duplicate country codes
        const uniqueCountries = countryData.filter((country, index, self) =>
          index === self.findIndex((c) => c.code === country.code)
        );
        setCountries(uniqueCountries);
      });
  }, []);

  useEffect(() => {
    // Fetch countries
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countryList = response.data
          .filter(country => country.idd && country.idd.root) // Ensure country has a code
          .map(country => ({
            name: country.name.common,
            code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')
          }));
        setCountries(countryList);
        console.log(countryList); // Log the country list if needed
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  // Fetch states 
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const formData = new FormData();
        formData.append('country_id', 101); // Static country_id

        const response = await axios.post('https://www.ravi.host/api/getstatebycountry', formData);
        console.log('API response:', response);

        // Accessing the nested data property to get the states array
        if (response.data && response.data.data && Array.isArray(response.data.data)) {
          setStates(response.data.data);
          console.log('States data:', response.data.data); // Log the fetched states data
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };

    fetchStates();
  }, []);


  useEffect(() => {
    const fetchCities = async () => {
      if (!selectedStateId) return;

      try {
        const formData = new FormData();
        formData.append('country_id', '101'); // Static country_id
        formData.append('state_id', selectedStateId); // Use the selected state ID

        const response = await axios.post('https://www.ravi.host/api/getcitybystate', formData);
        console.log('City API response:', response);

        if (response.data && response.data.data && Array.isArray(response.data.data)) {
          setCities(response.data.data);
          console.log('Cities data:', response.data.data);
        } else {
          console.error('Unexpected city response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, [selectedStateId]);


  // Event handler to update state when select option is changed
  const handleCountryChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedCountry(selectedOption);
    console.log("Selected value:", selectedOption); // Log selected value to console
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    if (/^\d{0,10}$/.test(phoneNumber)) {
      setphone(phoneNumber);
    }
  };

  const handleStateChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const selectedName = selectedOption.text;
    const selectedId = selectedOption.value;

    setSelectedStateName(selectedName);
    setSelectedStateId(selectedId);
  };


  const handleCityChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedCity(selectedOption);
    console.log("Selected value:", selectedOption);
  };
  const handleExperienceChange = (e) => {
    setSelectedExperience(e.target.value);
  };
  // const handleSectorChange = (e) => {
  //   setSelectedSector(e.target.value);
  // };

  let handleBttons = (id) => {
    setInquiryType(id);
  };

  const handleResumeUpload = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    if (file && !allowedTypes.includes(file.type)) {
      toast.error('Invalid file type. Please upload a PDF or Word document.');
      return;
    }
    setresume(file);
  };

  const handleSuccessModal = () => {
    setmodalopen(true);
    // setTimeout(() => {
    //   setmodalopen(false);
    // }, 3000);
  };

  const handleGeneralEnquiry = async () => {

    const fullPhoneNumber = `${countryCode}${getphone}`;
    // e.preventDefault();
    if (!getfname) {
      toast.error("First Name is required");
      return;
    } else if (!/^[A-Za-z\s]+$/.test(getfname)) {
      toast.error("First Name can only contain alphabetic characters and spaces");
      return;
    } else if (!getlname) {
      toast.error("Last Name is required");
      return;
    } else if (!/^[A-Za-z\s]+$/.test(getlname)) {
      toast.error("Last Name can only contain alphabetic characters and spaces");
      return;
    }
    else if (!getemail) {
      toast.error("Email is required");
      return;
    }
    else if (!email_validation.test(getemail)) {
      toast.error("Invalid Email Address");
      return;
    }
    else if (!getphone) {
      toast.error("Phone Number is required");
      return;
    }
    else if (getphone.length !== 10) {
      toast.error("Phone Number must be 10 digits");
      return;
    }
    else if (selectedCountry == null || selectedCountry == "") {
      toast.error("please select country");
      return;
    }
    else if (!getsub) {
      toast.error("Subject is required");
      return;
    }
    else if (!getprodel) {
      toast.error("Project Details are required");
      return;
    }
    else if (!getmessage) {
      toast.error("Message is required");
      return;
    }
    else {
      setLoading(true);
      const param = {
        first_name: getfname,
        last_name: getlname,
        email: getemail,
        phone: fullPhoneNumber,
        country: selectedCountry,
        subject: getsub,
        project_details: getprodel,
        message: getmessage,
        form_type: inquiryType,
      };

      console.log("param", param);

      await axios
        .post("https://gokulnair.com/bg/api/add_contact_form", param, {})
        .then((res) => {
          setLoading(false);
          if (res.data.status == 1) {
            setSubmittedName(`${getfname} ${getlname}`);
            handleSuccessModal();
            setlname("");
            setfname("");
            setEmail("");
            setphone("");
            setInquiryType(1);
            setSelectedCountry(null);
            setSelectedCity(null);
            setSelectedStateName(null);
            setSelectedSector(null);
            setSelectedExperience(null);
            setsub("");
            setprodel("");
            setMessage("");
            setinstitute("");
            setresume("");
            

            console.log("formsubmitted successfully");
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log("err", err);
        });
    }
  };


  const handleInternship = async (e) => {
    e.preventDefault();
    const fullPhoneNumber = `${countryCode}${getphone}`;
    if (!getfname) {
      toast.error("First Name is required");
      return;
    } else if (!/^[A-Za-z\s]+$/.test(getfname)) {
      toast.error("First Name can only contain alphabetic characters and spaces");
      return;
    } else if (!getlname) {
      toast.error("Last Name is required");
      return;
    } else if (!/^[A-Za-z\s]+$/.test(getlname)) {
      toast.error("Last Name can only contain alphabetic characters and spaces");
      return;
    }
    if (!getemail) {
      toast.error("Email is required");
      return;
    }
    if (!email_validation.test(getemail)) {
      toast.error("Invalid Email Address");
      return;
    }
    if (!getphone) {
      toast.error("Phone Number is required");
      return;
    }
    if (getphone.length !== 10) {
      toast.error("Phone Number must be 10 digits");
      return;
    }
    if (!selectedStateName) {
      toast.error("Please select State");
      return;
    }
    if (!selectedCity) {
      toast.error("Please select City");
      return;
    }
    if (!getinstitute) {
      toast.error("Name of Institute is required");
      return;
    }
    if (!getresume) {
      toast.error("Please upload Resume");
      return;
    }
    const resumeFileType = getresume.type;
    if (resumeFileType !== "application/pdf") {
      toast.error("Resume must be a PDF file");
      return;
    }
    if (!getmessage) {
      toast.error("Message is required");
      return;
    }

    setLoading(true);

    // Use FormData to handle file upload
    const formData = new FormData();
    formData.append('first_name', getfname);
    formData.append('last_name', getlname);
    formData.append('email', getemail);
    formData.append('phone', fullPhoneNumber);
    formData.append('state', selectedStateName);
    formData.append('city', selectedCity);
    formData.append('institute', getinstitute);
    formData.append('resume', getresume);  // This is the file
    formData.append('message', getmessage);
    formData.append('form_type', inquiryType);

    console.log("Form Data:", formData);

    try {
      const res = await axios.post("https://gokulnair.com/bg/api/add_contact_form", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setLoading(false);
      if (res.data.status == 1) {
        setSubmittedName(`${getfname} ${getlname}`);
        handleSuccessModal();
        setlname("");
        setfname("");
        setEmail("");
        setphone("");
        setInquiryType(1);
        setSelectedCountry(null);
        setSelectedCity(null);
        setSelectedStateName(null);
        setSelectedSector(null);
        setSelectedExperience(null);
        setsub("");
        setprodel("");
        setMessage("");
        setinstitute("");
        setresume("");

        console.log("Form submitted successfully");
      }
    } catch (err) {
      setLoading(false);
      console.log("Error:", err);
      toast.error("An error occurred while submitting the form. Please try again.");
    }
  };


  const handleCareer = async (e) => {
    e.preventDefault();
    const fullPhoneNumber = `${countryCode}${getphone}`;
    if (!getfname) {
      toast.error("First Name is required");
      return;
    } else if (!/^[A-Za-z\s]+$/.test(getfname)) {
      toast.error("First Name can only contain alphabetic characters and spaces");
      return;
    } else if (!getlname) {
      toast.error("Last Name is required");
      return;
    } else if (!/^[A-Za-z\s]+$/.test(getlname)) {
      toast.error("Last Name can only contain alphabetic characters and spaces");
      return;
    }
    else if (!getemail) {
      toast.error("Email is required");
      return;
    }
    else if (!email_validation.test(getemail)) {
      toast.error("Invalid Email Address");
      return;
    }
    else if (!getphone) {
      toast.error("Phone Number is required");
      return;
    }
    else if (getphone.length !== 10) {
      toast.error("Phone Number must be 10 digits");
      return;
    }
    else if (selectedExperience == null || selectedExperience == "") {
      toast.error("please select Experience");
      return;
    }
    else if (selectedSector == null || selectedSector === "") {
      toast.error("Please select sector");
      return;
    } else if (selectedSector === "other" && !customSector) {
      toast.error("Please specify your sector");
      return;
    }
    else if (!getresume) {
      toast.error("Please upload Resume");
      return;
    }
    const resumeFileType = getresume.type;
    if (resumeFileType !== "application/pdf") {
      toast.error("Resume must be a PDF file");
      return;
    }
    setLoading(true);
    // const param3 = {
    //   first_name: getfname,
    //   last_name: getlname,
    //   email: getemail,
    //   phone:fullPhoneNumber,
    //   experience:selectedExperience,
    //   sector:selectedSector,
    //   resume:getresume,
    //   form_type: inquiryType,
    // };
    // Use FormData to handle file upload
    const formData = new FormData();
    formData.append('first_name', getfname);
    formData.append('last_name', getlname);
    formData.append('email', getemail);
    formData.append('phone', fullPhoneNumber);
    formData.append('experience', selectedExperience);
    formData.append('sector', selectedSector === "other" ? customSector : selectedSector);
    formData.append('resume', getresume);  // This is the file
    formData.append('form_type', inquiryType);

    console.log("Form Data:", formData);

    // console.log("param", param3);

    try {
      const res = await axios.post("https://gokulnair.com/bg/api/add_contact_form", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setLoading(false);
      if (res.data.status == 1) {
        setSubmittedName(`${getfname} ${getlname}`);
        handleSuccessModal();
        setlname("");
        setfname("");
        setEmail("");
        setphone("");
        setInquiryType(1);
        setSelectedCountry(null);
        setSelectedCity(null);
        setSelectedStateName(null);
        setSelectedSector(null);
        setSelectedExperience(null);
        setsub("");
        setprodel("");
        setMessage("");
        setinstitute("");
        setresume("");
        setCustomSector("");

        console.log("Form submitted successfully");
      }
    } catch (err) {
      setLoading(false);
      console.log("Error:", err);
      toast.error("An error occurred while submitting the form. Please try again.");
    }
  };



  // scrolling at career apply now btn
  useEffect(() => {
    console.log("scrollPosition->", scrollPosition);
    console.log("inquiryType->", inquiryType);
    if (scrollPosition == 3) {
      handleBttons(3);
    } else {
      handleBttons(1);
    }
  }, [scrollPosition]);



  const handleResumeRemove = () => {
    setresume(null);
  };
  // Additional state for custom sector
  const [customSector, setCustomSector] = useState("");

  // Additional handlers for custom sector input and dropdown change
  const handleSectorChange = (e) => {
    const value = e.target.value;
    setSelectedSector(value);
    if (value !== "other") {
      setCustomSector("");
    }
  };

  const handleCustomSectorChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z\s]*$/.test(value)) {
      setCustomSector(value);
    }
  };
  return (
    <div className='contact_form_main_wrapp'>
      <div className='contact_form_base_wrapp'>
        <div className='contact_form_div'>
          <div className='contact_left'>
            <div className='contact-btn-group'>
              <hr className={`contact_hr ${inquiryType === 1 ? 'con_hr_clicked' : ''}`} />
              <button
                type="button"
                className={`inqtyp_btn_con ${inquiryType === 1 ? 'con_clicked' : ''}`}
                onClick={() => {
                  handleBttons(1);
                }}
              > General Enquiry</button>
            </div>
            <div className='contact-btn-group'>
              <hr className={`contact_hr ${inquiryType === 2 ? 'con_hr_clicked' : ''}`} />
              <button
                type="button"
                className={`inqtyp_btn_con ${inquiryType === 2 ? 'con_clicked' : ''}`}
                onClick={() => {
                  handleBttons(2);
                }}
              >Internship</button>
            </div>
            <div className='contact-btn-group'>
              <hr className={`contact_hr ${inquiryType === 3 ? 'con_hr_clicked' : ''}`} />
              <button
                type="button"
                className={`inqtyp_btn_con ${inquiryType === 3 ? 'con_clicked' : ''}`}
                onClick={() => {
                  handleBttons(3);
                }}
              > Career</button>
            </div>
          </div>
          {inquiryType === 1 ? (
            <div className='contact_right'>
              <form className="form">
                <div className="contact-form-row">
                  <div className='contact_label_input'>
                    <label htmlFor="">First Name</label>
                    <FormInput
                      type="text"
                      name="firstName"
                      className="contact_label_input_input"
                      value={getfname}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                          setfname(value);
                        }
                      }}
                    />
                  </div>
                  <div className='contact_label_input'>
                    <label htmlFor="">Last Name</label>
                    <FormInput
                      type="text"
                      name="lastName"
                      className="contact_label_input_input"
                      value={getlname}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                          setlname(value);
                        }
                      }} />
                  </div>
                </div>

                <div className="contact-form-row">
                  <div className='contact_label_input'>
                    <label htmlFor="">Email</label>
                    <FormInput
                      type="email"
                      name="email"
                      className="contact_label_input_input"
                      value={getemail}
                      onChange={(e) => {
                        const text = e.target.value;
                        if (email_validation.test(text) === false) {
                          setcon(false);
                        } else if (text.length == 0) {
                          setcon(false);
                        } else {
                          setcon(true);
                        }
                        setEmail(text);
                      }}
                    />
                  </div>
                  <div className="contact_label_input">
                    <label htmlFor="phone">Phone Number</label>
                    <div className="phone-input-container">
                      <SearchableDropdown
                        options={countries}
                        value={countryCode}
                        onChange={(code) => setCountryCode(code)}
                      />
                      <FormInput
                        type="text"
                        name="phone"
                        className="contact_label_input_input"
                        value={getphone}
                        onChange={handlePhoneChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="contact-form-row">
                  <div className='contact_label_input'>
                    <label htmlFor="">Select Country</label>
                    <select
                      name="country"
                      className="input"
                      onChange={handleCountryChange}
                    >
                      <option value="">Select</option>
                      {countries.map(country => (
                        <option
                          key={country.code}
                          value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {/* <BsChevronDown className="select_arrow" /> */}
                  </div>
                  <div className='contact_label_input'>
                    <label htmlFor="">Enter Subject</label>
                    <FormInput
                      type="text"
                      name="phone"
                      className="contact_label_input_input"
                      value={getsub}
                      onChange={(e) => {
                        setsub(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="contact-form-group">
                  <div className='contact_label_input_full'>
                    <label htmlFor="">Project Details</label>
                    <FormTextArea
                      name="projectDetails"
                      className="contact_label_input_pro"
                      value={getprodel}
                      onChange={(e) => {
                        setprodel(e.target.value);
                      }} />
                  </div>
                </div>

                <div className="contact-form-group">
                  <div className='contact_label_input_full_msg'>
                    <label htmlFor="">Message</label>
                    <FormTextArea
                      name="message"
                      className="contact_label_input_msg full-width "
                      value={getmessage}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="contact-form-group">
                  <button
                    type="button"
                    className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium  dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                    onClick={handleGeneralEnquiry}
                  >Submit
                    <BottomGradient />
                  </button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
          {inquiryType === 2 ? (
            <div className='contact_right'>
              <form className="form">
                <div className="contact-form-row">
                  <div className='contact_label_input'>
                    <label htmlFor="">First Name</label>
                    <FormInput
                      type="text"
                      name="firstName"
                      className="input"
                      value={getfname}
                      onChange={(e) => {
                        // setfname(e.target.value);
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                          setfname(value);
                        }
                      }}
                    />
                  </div>
                  <div className='contact_label_input'>
                    <label htmlFor="">Last Name</label>
                    <FormInput
                      type="text"
                      name="lastName"
                      className="input"
                      value={getlname}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                          setlname(value);
                        }
                      }} />
                  </div>
                </div>

                <div className="contact-form-row">
                  <div className='contact_label_input'>
                    <label htmlFor="">Email</label>
                    <FormInput
                      type="email"
                      name="email"
                      className="input"
                      value={getemail}
                      onChange={(e) => {
                        const text = e.target.value;
                        if (email_validation.test(text) === false) {
                          setcon(false);
                        } else if (text.length == 0) {
                          setcon(false);
                        } else {
                          setcon(true);
                        }
                        setEmail(text);
                      }} />
                  </div>
                  <div className="contact_label_input">
                    <label htmlFor="phone">Phone Number</label>
                    <div className="phone-input-container">
                      <SearchableDropdown
                        options={countries}
                        value={countryCode}
                        onChange={(code) => setCountryCode(code)}
                      />
                      <FormInput
                        type="text"
                        name="phone"
                        className="contact_label_input_input"
                        value={getphone}
                        onChange={handlePhoneChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="contact-form-row">
                  <div className='contact_label_input'>
                    <label htmlFor="">Select State</label>
                    <select
                      name="state"
                      id="state"
                      onChange={handleStateChange}
                      value={selectedStateId} // Ensure this matches the state ID
                    >
                      <option value="">Select</option>
                      {states.map((state) => (
                        <option key={state.Id} value={state.Id}>
                          {state.Name}
                        </option>
                      ))}
                    </select>

                  </div>
                  <div className='contact_label_input'>
                    <label htmlFor="">Select City</label>
                    <select
                      name="city"
                      className="input"
                      onChange={handleCityChange}
                      value={selectedCity}
                    >
                      <option value="">Select</option>
                      {cities.map((city) => (
                        <option key={city.Id} value={city.Name}>
                          {city.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="contact-form-row row-1-contact">
                  <div className='contact_label_input w-full '>
                    <label htmlFor="">Name of Institute</label>
                    <FormInput
                      type="text"
                      name="nameofInstitute"
                      className="input"
                      value={getinstitute}
                      onChange={(e) => {
                        setinstitute(e.target.value);
                      }} />
                  </div>
                </div>

                <div className="contact-form-row row-1-contact">
                  <div className='contact_label_input w-full'>
                    <label htmlFor="resume">Upload Resume</label>
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      className="input input-file"
                      accept="application/pdf"
                      onChange={handleResumeUpload}
                    />
                    {!getresume && (
                      <label htmlFor="resume" className="file-label">
                        <img src={images.upload_icon} alt="Upload" className="upload-icon" />
                      </label>
                    )}
                    {getresume && (
                      <div className="uploaded-file-info">
                        <p className="uploaded-file-name">{getresume.name}</p>
                        <button type="button" className="remove-btn" onClick={handleResumeRemove}>
                          Remove
                        </button>
                      </div>
                    )}
                    {/* {resume && <p>{resume.name}</p>} */}
                  </div>
                </div>

                <div className="contact-form-group">
                  <div className='contact_label_input_full_msg'>
                    <label htmlFor="">Message</label>
                    <FormTextArea
                      name="message"
                      className="contact_label_input_msg full-width "
                      value={getmessage}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="contact-form-group">
                  <button
                    type="submit"
                    className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium  dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"

                    onClick={handleInternship}
                  >
                    Submit

                    <BottomGradient />
                  </button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
          {inquiryType === 3 ? (
            <div className='contact_right'>
              <form className="form">
                <div className="contact-form-row">
                <div className='contact_label_input'>
                    <label htmlFor="">First Name</label>
                    <FormInput
                      type="text"
                      name="firstName"
                      className="contact_label_input_input"
                      value={getfname}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                          setfname(value);
                        }
                      }}
                    />
                  </div>
                  <div className='contact_label_input'>
                    <label htmlFor="">Last Name</label>
                    <FormInput
                      type="text"
                      name="lastName"
                      className="contact_label_input_input"
                      value={getlname}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                          setlname(value);
                        }
                      }} />
                  </div>
                </div>

                <div className="contact-form-row">
                  <div className='contact_label_input'>
                    <label htmlFor="">Email</label>
                    <FormInput
                      type="email"
                      name="email"
                      className="input"
                      value={getemail}
                      onChange={(e) => {
                        const text = e.target.value;
                        if (email_validation.test(text) === false) {
                          setcon(false);
                        } else if (text.length == 0) {
                          setcon(false);
                        } else {
                          setcon(true);
                        }
                        setEmail(text);
                      }} />
                  </div>
                  <div className="contact_label_input">
                    <label htmlFor="phone">Phone Number</label>
                    <div className="phone-input-container">
                      <SearchableDropdown
                        options={countries}
                        value={countryCode}
                        onChange={(code) => setCountryCode(code)}
                      />
                      <FormInput
                        type="text"
                        name="phone"
                        className="contact_label_input_input"
                        value={getphone}
                        onChange={handlePhoneChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="contact-form-row">
                  <div className='contact_label_input'>
                    <label htmlFor="experience">Years of Experience</label>
                    <select
                      name="experience"
                      id="experience"
                      onChange={handleExperienceChange}
                      value={selectedExperience}
                    >
                      <option value="">Select</option>
                      <option value="0-1">0-1 Years</option>
                      <option value="1-3">1-3 Years</option>
                      <option value="3-5">3-5 Years</option>
                      <option value="5-7">5-7 Years</option>
                      <option value="7-10">7-10 Years</option>
                      <option value="10+">10+ Years</option>
                    </select>

                  </div>
                  <div className='contact_label_input'>
                    <label htmlFor="sector">Your Sector</label>
                    <select
                      name="sector"
                      id="sector"
                      className="input"
                      onChange={handleSectorChange}
                      value={selectedSector}
                    >
                      <option value="">Select</option>
                      <option value="designer">Designer</option>
                      <option value="react-developer">React Developer</option>
                      <option value="backend-developer">Backend Developer</option>
                      <option value="full-stack-developer">Full Stack Developer</option>
                      <option value="project-manager">Project Manager</option>
                      <option value="qa-tester">QA Tester</option>
                      <option value="devops-engineer">DevOps Engineer</option>
                      <option value="data-scientist">Data Scientist</option>
                      <option value="ui-ux-designer">UI/UX Designer</option>
                      <option value="product-manager">Product Manager</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                {selectedSector === "other" && (
                  <div className='contact-form-row row-1-contact'>
                    <div className='contact_label_input w-full'>
                      <label htmlFor="customSector">Add Your Work</label>
                      <FormInput
                        placeholder="Please specify your sector"
                        type="text"
                        className="input"
                        value={customSector}
                        onChange={handleCustomSectorChange}
                      />
                    </div>
                  </div>
                )}
                <div className="contact-form-row row-1-contact">
                  <div className='contact_label_input w-full'>
                    <label htmlFor="resume">Upload Resume</label>
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      className="input input-file"
                      accept="application/pdf"
                      onChange={handleResumeUpload}
                    />
                    {!getresume && (
                      <label htmlFor="resume" className="file-label">
                        <img src={images.upload_icon} alt="Upload" className="upload-icon" />
                      </label>
                    )}
                    {getresume && (
                      <div className="uploaded-file-info">
                        <p className="uploaded-file-name">{getresume.name}</p>
                        <button type="button" className="remove-btn" onClick={handleResumeRemove}>
                          Remove
                        </button>
                      </div>
                    )}
                    {/* {resume && <p>{resume.name}</p>} */}
                  </div>
                </div>
                <div className="contact-form-group">
                  <button
                    type="submit"
                    className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium  dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"

                    onClick={handleCareer}
                  >
                    Submit

                    <BottomGradient />
                  </button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {modalopen === true ? (
        <div className="modal_screen">
          <div class="modal_success"> 
            <div class="modal_success_content1">
              <h1>Leave it with us</h1>
              <h1>{submittedName}</h1>
              <p>We've got your request and we'll get back to you as soon as we can.</p>
              <button onClick={() => setmodalopen(false)} className='back-to-website-btn'>Back to website</button>
            </div>
            <div className='modal_success_content2'>
              <h2>Learn more about Binary Geckos</h2>
              <div className='modal-btn-divv'>
                <Link to={"/AboutUs"} className='m-btn-link'>
                About Us
                <div className='btn-circle'>
                <FaArrowRight />
                </div>
                </Link>
                {/* <Link>
                What we do
                </Link> */}
                <Link to={"/Insight"} className='m-btn-link'>
                Insights
                <div className='btn-circle'>
                <FaArrowRight />
                </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='modal-img-divv'>
            <img src={images.website_feedback_form} alt="" />
          </div>
        </div>
        
      ) : (
        ""
      )}
      {/* </div> */}
      {loading && (
        <div className="loading_screen">
          <div className='loader-contact'>

          </div>
          {/* <img src={images.spinner} alt="Spinner" /> */}
        </div>
      )}
      <ToastContainer />
    </div>
  )
}

export default ContactForm
import React from 'react'
import "./cs_workprogress.css"

const CS_Workprogress = ({subh1,subc1,subh2,subc2,subh3,subc3,subh4,subc4}) => {
  return (
    <div className='cs_wp_main_wrapp'>
        <div className='cs_wp_base_wrapp'>
            <h1>Work <span>process</span></h1>
            <div className='cs_wp_cards_div'>
                <div className='cs_wp_card'>
                    <div className='cs_wp_card_left'>
                        <h1>{subh1}</h1>
                        <p>
                            {subc1}
                        </p>
                    </div>
                    <div className='cs_wp_card_right'>
                        <h1>01</h1>
                    </div>
                </div>
                <div className='cs_wp_card'>
                    <div className='cs_wp_card_left'>
                         <h1>{subh2}</h1>
                        <p>
                        {subc2}
                        </p>
                    </div>
                    <div className='cs_wp_card_right'>
                        <h1>02</h1>
                    </div>
                    </div>
                <div className='cs_wp_card'>
                    <div className='cs_wp_card_left'>
                            <h1>{subh3}</h1>
                            <p>
                            {subc3}
                            </p>
                        </div>
                        <div className='cs_wp_card_right'>
                            <h1>03</h1>
                        </div>
                    </div>
                <div className='cs_wp_card'>
                    <div className='cs_wp_card_left'>
                        <h1>{subh4}</h1>
                        <p>
                            {subc4}
                        </p>
                    </div>
                    <div className='cs_wp_card_right'>
                        <h1>04</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CS_Workprogress
import React from 'react';
import Slider from 'react-slick';
import "./service2_boost.css"
import images from '../../constants/images';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Btn_ani from '../homehero/Btn_ani';
import Btn_anis from './Btn_anis';
import { FaArrowRight } from "react-icons/fa6";

const Service2_Boost = () => {
    const Boostcard = [
        { imgSrc: images.service2_boost_img, title: "Retail", des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi." },
        { imgSrc: images.service2_boost_img, title: "Healthcare", des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi." },
        { imgSrc: images.service2_boost_img, title: "Food", des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi." },
        { imgSrc: images.service2_boost_img, title: "E-commerce", des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi." },
    ];
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='ser2_boost_main_wrapp'>
            {/* <div className='ser2_boost_title_div'>
                <h2>
                    Boost Your Brand
                </h2>
                <h3>We create intuitive, visually stunning interfaces that enhance user experience and drive engagement. <br /> From user research and wireframing to final design and usability testing, we ensure your product stands out. <br /> Contact us to elevate your digital experience today!</h3>
            </div> */}
            {/* <div className='ser2_boost_slider_div'>
                <h2>Related Industries</h2>
                <Slider {...settings} className='ser2_boost_slider'>
                    {Boostcard.map((item, index) => (
                        <div key={index} className='ser2_boost_slide'>
                            <img src={item.imgSrc} alt={item.title} />
                            <div className='ser2_boost_slide_content'>
                                <h2>{item.title}</h2>
                                <button className='btn_boost'>
                                    <FaArrowRight color='grey' />
                                </button>
                                <p>{item.des}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div> */}
        </div>
    )
}

export default Service2_Boost
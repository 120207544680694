import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const IndustrialDesign = () => {
    const imagesui = images.IndustrialProduct_Design;
    const hashtagall = [
        { hashtagname: "#IndustrialDesign" },
        { hashtagname: "#ProductDesign" },
        { hashtagname: "#DesignThinking" },
        { hashtagname: "#ProductDevelopment" },
        { hashtagname: "#IndustrialDesigner" },
        { hashtagname: "#Innovation" },
        { hashtagname: "#CreativeDesign" },
    ];
    // FAQ
    const accordions = [
        { 
            question: "What types of products do you design?", 
            answer: "We have a diverse team with expertise in designing a wide range of products, from consumer electronics and household goods to medical devices and industrial equipment." 
        },
        { 
            question: "What makes Binary Geckos different from other product design firms?", 
            answer: "Our collaborative approach sets us apart. We work closely with you throughout the entire process, from initial concept to production, ensuring your vision comes to life." 
        },
        { 
            question: "How involved will I be in the design process?", 
            answer: "We believe in close collaboration. We'll keep you informed of progress, present concepts for your feedback, and ensure the final product aligns with your vision." 
        },
        { 
            question: "What kind of prototypes do you create?", 
            answer: "We create various prototypes depending on the project stage. This might include low-fidelity prototypes for initial concept testing and high-fidelity prototypes for detailed user feedback and functionality testing." 
        },
        { 
            question: "How much does product design cost?", 
            answer: "Costs vary depending on the complexity of the product. We'll provide a transparent quote after discussing your project needs." ,
        },
        { 
            question: "Do you offer assistance with manufacturing?", 
            answer: "Yes, we can connect you with reliable manufacturing partners and offer support throughout the production process, ensuring a smooth transition from design to reality." ,
        },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {
                title: "Project Kickoff & Discovery", 
                op: "+", 
                content: "We kick off every project with a comprehensive discovery phase. We delve deep into understanding your vision, goals, and requirements, laying the foundation for a successful collaboration.", 
                isOpen: false
            },
            {
                title: "User Research & Market Analysis", 
                op: "+", 
                content: "We conduct extensive user research and market analysis to gain insights into user needs, preferences, and market trends. This informs our design decisions and ensures the product meets user expectations.", 
                isOpen: false
            },
            {
                title: "Concept Ideation & Brainstorming", 
                op: "+", 
                content: "In this phase, our team engages in creative brainstorming sessions to generate innovative ideas and concepts that address the project goals and user needs effectively.", 
                isOpen: false
            },
            {
                title: "Product Sketching & Prototyping",
                 op: "+", 
                 content: "We translate concepts into tangible designs through sketching and prototyping. This allows us to visualize ideas quickly and explore different design directions before moving into detailed development.",
                isOpen: false
            },
            {
                title: "User Testing & Feedback Integration", 
                op: "+", 
                content: "We conduct user testing sessions to gather feedback on prototypes, ensuring that the design meets user expectations. Feedback is integrated iteratively to refine the product design.", 
                isOpen: false
            },
            {
                title: "Engineering & Design Refinement", 
                op: "+", 
                content: "Our engineers and designers work closely to refine the product design, considering manufacturability, functionality, and user experience to create a well-balanced solution.", 
                isOpen: false
            },
            {
                title: "Material Selection & Sourcing", 
                op: "+", 
                content: "We carefully select materials based on performance, aesthetics, and sustainability. We source materials responsibly to ensure quality and meet project requirements.", 
                isOpen: false
            },
            {
                title: "Prototyping & Testing (Iteration)", 
                op: "+", 
                content: "Prototyping and testing are iterative processes where we build and test prototypes to validate design decisions, iterate based on feedback, and refine the product until it meets standards.", 
                isOpen: false
            },
            {
                title: "Production & Manufacturing Support", 
                op: "+", 
                content: "We provide support during the production phase, collaborating with manufacturers to ensure the design intent is maintained and the final product meets quality standards.", 
                isOpen: false
            }
        ])

        const toggleService = (index) => {
            setservice(service.map((service, i) => (
                i === index ? { ...service, isOpen: !service.isOpen,op: service.isOpen ? "+" : "-"  } : service
            )));
        };  

        useEffect(() => {
            window.scrollTo({top: 0,behavior: "instant"})
           }, [])
  return (
    <div>
        <Service4_banner title="Industrial/Product Design"  />
        <div className="paras4"><h1>
        Innovation sticks, creativity climbs. At Binary Geckos, product design isn't just about aesthetics; it's about crafting user-centric solutions that conquer market challenges. We're a team of passionate designers, engineers, and strategists who work together to transform your vision into groundbreaking products that users love.
          </h1>
        </div>
        <Oursevices_s4 service={service} toggleService={toggleService} title="our product development process:" hashtagall={hashtagall}/>
        <Service2_Boost />
        <Casestudy />
        <ServiceBranding title1="Ready to climb the mountain of product success?" title2="Contact Binary Geckos today!" image={imagesui}/>
        <ServiceQuestion  accordions={accordions}/>

    </div>
  )
}

export default IndustrialDesign
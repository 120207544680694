import React from 'react'
import "./Aboutus_section1.css"
import Btn_ani from '../homehero/Btn_ani'
import images from '../../constants/images'
import { Link } from 'react-router-dom'

const Aboutus_section1 = () => {
  return (
    <div className='about_main_wrapp'>
      <div className='abot_section1_mainn'>
        <h1 className='about_section1_title'>
        Binary Geckos: Scaling Innovation, Reaching Heights.
        </h1>
      </div>
      <div className="asec_contect_btn_wrapp">
        <Link to="/ContactUs" className='hh_btn_link'>
          <p>Contact Us </p>
          <Link to="/ContactUs">
            <Btn_ani />
          </Link>
        </Link>
      </div>
      <div className="imgg">
        <img src={images.insight_img} alt="meeting" className="abanner" loading='lazy'/>
      </div>
      <div className='ab_para'>
        <div className='ab_paraleft'>
        Imagine a company that's as passionate about innovation as a gecko is about scaling walls. 
        </div>
        <div className='ab_pararight'>
          We're a passionate collective of creative minds and digital strategists who thrive on pushing boundaries. We don't blend in, we climb higher - leaving a lasting mark with captivating designs, impactful marketing, and cutting-edge tech solutions.<br/><br/>
          Born from a vision to revolutionise brands, we've become a trusted global partner, fostering collaboration and exceptional results. Our focus? Helping you reach the summit of your digital goals.
        </div>
      </div>
    </div>
  )
}

export default Aboutus_section1
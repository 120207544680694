import React, { useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';
import './Project_anim.css';
import { Link } from 'react-router-dom';

export const Project_anim = ({ products, category, setCategory }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start start', 'end start'],
  });

  const springConfig = { stiffness: 300, damping: 30, bounce: 100 };

  const translateX = useSpring(useTransform(scrollYProgress, [0, 1], [0, 1000]), springConfig);
  const translateXReverse = useSpring(useTransform(scrollYProgress, [0, 1], [0, -1000]), springConfig);
  const rotateX = useSpring(useTransform(scrollYProgress, [0, 0.2], [15, 0]), springConfig);
  const opacity = useSpring(useTransform(scrollYProgress, [0, 0.2], [0.2, 1]), springConfig);
  const rotateZ = useSpring(useTransform(scrollYProgress, [0, 0.2], [20, 0]), springConfig);
  const translateY = useSpring(useTransform(scrollYProgress, [0, 0.2], [-700, 500]), springConfig);

  // Filter products based on the selected category
  const filteredProducts = category === 'All' ? products : products.filter(product => product.category.toLowerCase() === category.toLowerCase());

  // Split products into three rows for display
  const firstRow = filteredProducts.slice(0, Math.ceil(filteredProducts.length / 3));
  const secondRow = filteredProducts.slice(Math.ceil(filteredProducts.length / 3), 2 * Math.ceil(filteredProducts.length / 3));
  const thirdRow = filteredProducts.slice(2 * Math.ceil(filteredProducts.length / 3));

  return (
    <div
      ref={ref}
      className="py-40 overflow-hidden antialiased relative flex flex-col self-auto [perspective:1000px] [transform-style:preserve-3d] project_main"
    >
      <Header setCategory={setCategory} />

      <motion.div
        style={{
          rotateX,
          rotateZ,
          translateY,
          opacity,
        }}
        className="project_sli_new"
      >
        <motion.div className="flex flex-row-reverse space-x-reverse space-x-20 mb-20">
          {firstRow.map((product) => (
            <ProductCard
              product={product}
              translate={translateX}
              key={product.id}
            />
          ))}
        </motion.div>
        <motion.div className="flex flex-row mb-20 space-x-20 pro-slider-margin">
          {secondRow.map((product) => (
            <ProductCard
              product={product}
              translate={translateXReverse}
              key={product.id}
            />
          ))}
        </motion.div>
        <motion.div className="flex flex-row-reverse space-x-reverse space-x-20">
          {thirdRow.map((product) => (
            <ProductCard
              product={product}
              translate={translateX}
              key={product.id}
            />
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

// Header Component
const Header = ({ setCategory }) => {
  const [categoryLocal, setCategoryLocal] = useState('All');

  useEffect(() => {
    setCategory(categoryLocal);
  }, [categoryLocal, setCategory]);

  return (
    <div className="max-w-7xl relative mx-auto py-20 md:py-40 px-4 w-full left-0 top-0 sectn1">
      <h1 className="text-2xl md:text-7xl font-bold dark:text-white font_h1">
        See How We've Helped Others <br /> Climb the Digital Ladder
      </h1>
      <p className="max-w-5xl text-base md:text-xl mt-8 dark:text-neutral-200 font_h2">
        Discover how we empower businesses to excel in the digital age. Explore our case studies to see how we’ve transformed challenges into growth opportunities, helping clients lead and innovate in their industries.
      </p>
      <div className="absolute top-4 left-4 z-10 bg-blue-500 text-white px-4 py-2 rounded-md buttonn">
        <button className={`buttonn_button ${categoryLocal === 'All' ? 'buttonn_button_hover' : ''}`} onClick={() => setCategoryLocal('All')}>All</button>
        <button className={`buttonn_button ${categoryLocal === 'website' ? 'buttonn_button_hover' : ''}`} onClick={() => setCategoryLocal('website')}>Websites</button>
        <button className={`buttonn_button ${categoryLocal === 'Application' ? 'buttonn_button_hover' : ''}`} onClick={() => setCategoryLocal('Application')}>Applications</button>
        <button className={`buttonn_button ${categoryLocal === 'casestudy' ? 'buttonn_button_hover' : ''}`} onClick={() => setCategoryLocal('casestudy')}>Case Studies</button>
      </div>
    </div>
  );
};

// ProductCard Component
const ProductCard = ({ product, translate }) => {
  return (
    <motion.div
      style={{
        x: translate,
      }}
      whileHover={{
        y: -20,
      }}
      key={product.title}
      className="group/product h-96 w-[35rem] relative flex-shrink-0 pro-slider-hei-wid"
    >
      <Link to={product.link} target='_blank' className="block group-hover/product:shadow-2xl">
        <img
          src={product.thumbnail}
          height="600"
          width="600"
          className="object-cover absolute h-full w-full inset-0"
          alt={product.title}
        />
      </Link>
      <div className="absolute inset-0 h-full w-full opacity-0 group-hover/product:opacity-80 bg-black pointer-events-none"></div>
      <h2 className="absolute bottom-4 left-4 opacity-0 group-hover/product:opacity-100 text-white">
        {product.title}
      </h2>
    </motion.div>
  );
};

export default Project_anim;
import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const MarketingAnalysis = () => {
    const imagesui = images.MarketingAnalysis;
    const hashtagall = [
        { hashtagname: "#MarketingAnalytics" },
        { hashtagname: "#DataAnalytics" },
        { hashtagname: "#DigitalMarketing" },
        { hashtagname: "#Analytics" },
        { hashtagname: "#MarketingInsights" },
        { hashtagname: "#DataDrivenMarketing" },
        { hashtagname: "#MarketingMetrics" },
    ];

    const accordions = [
        {
            question: "Why is marketing analytics important for my business?",
            answer: "Marketing analytics helps you understand what's working and what's not in your marketing efforts. By analyzing data, you can make data-driven decisions to optimize your campaigns, improve ROI, and ultimately achieve your marketing goals."
        },
        { question: "What types of marketing data do you analyze?", answer: "We analyze a wide range of data, including website traffic, social media engagement, email marketing performance, customer behavior, and campaign-specific metrics." },
        { question: "How do you create marketing analytics dashboards?", answer: "We create user-friendly dashboards that provide you with a clear and concise overview of your key marketing metrics. These dashboards allow you to easily track progress, identify trends, and make informed decisions." },
        { question: "What is customer attribution modeling and how does it help?", answer: "Customer attribution modeling helps us understand which marketing touchpoints (website visits, ads, social media interactions) contribute most to conversions. This allows us to allocate marketing budget effectively and optimize campaigns for maximum impact." },
        { question: "How do you calculate marketing ROI?", answer: "We analyze marketing spend against revenue generated to calculate your marketing ROI. This helps you understand the effectiveness of your marketing investments and identify areas for improvement." },
        { question: "Can you help me use marketing analytics to improve my customer experience?", answer: "Absolutely! By analyzing customer behavior data, we can identify areas for improvement in your customer journey and personalize your marketing efforts to deliver a more relevant and engaging experience." },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            { title: "Marketing Goals & Analytics Strategy Definition", op: "+", content: "Define marketing goals and develop an analytics strategy to achieve them.", isOpen: false },
            { title: "Data Source Identification & Integration", op: "+", content: "Identify and integrate relevant data sources for comprehensive analysis.", isOpen: false },
            { title: "Data Cleaning & Preprocessing", op: "+", content: "Clean and preprocess data to ensure accuracy and reliability.", isOpen: false },
            { title: "Marketing Analytics Dashboard Development", op: "+", content: "Create dashboards to visualize and track key marketing metrics.", isOpen: false },
            { title: "Customer Journey & Attribution Modeling", op: "+", content: "Model customer journeys and attribute conversions to specific marketing efforts.", isOpen: false },
            { title: "Marketing Channel Performance Measurement", op: "+", content: "Measure the performance of various marketing channels.", isOpen: false },
            { title: "Customer Acquisition Cost (CAC) & Customer Lifetime Value (CLTV) Analysis", op: "+", content: "Analyze CAC and CLTV to understand customer profitability.", isOpen: false },
            { title: "Marketing ROI (Return on Investment) & Cost Optimization", op: "+", content: "Calculate marketing ROI and optimize costs for maximum efficiency.", isOpen: false },
            { title: "Data-Driven Recommendations & Marketing Strategy Refinement", op: "+", content: "Provide actionable recommendations and refine marketing strategies based on data insights.", isOpen: false }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Marketing Analytics" />
            <div className="paras4"><h1>
                Data is power, but insights are the key to unlocking success. At Binary Geckos, we're your marketing analytics gurus. We harness the power of data to provide you with clear, actionable insights that inform your marketing decisions and fuel data-driven growth.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step marketing analytics process:" hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Data: We Climb to Actionable Marketing Insights."
             title2="Data-Driven Decisions: Binary Geckos Gets You the Clarity You Need."
             image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default MarketingAnalysis
import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const D_WebDevelopment = () => {
    const imagesui = images.Web_Development;
    const hashtagall = [
        { hashtagname: "#WebDevelopment" },
        { hashtagname: "#FullStack" },
        { hashtagname: "#Coding" },
        { hashtagname: "#JavaScript" },
        { hashtagname: "#WebDesign" },
        { hashtagname: "#HTML" },
        { hashtagname: "#ReactJs" },
    ];

    // FAQ
    const accordions = [
        { question: "What kind of websites do you develop?", answer: "We develop a wide range of websites, from simple landing pages to complex e-commerce platforms. We can tailor a solution to your specific needs and goals." },
        { question: "What technologies do you use?", answer: "We leverage a variety of cutting-edge technologies to ensure your website is secure, performant, and future-proof." },
        { question: "Is SEO (Search Engine Optimization) included in your development process?", answer: "Absolutely! We believe in building websites that are not only user-friendly but also optimised for search engines." },
        { question: "Do you offer responsive web design?", answer: "Yes, all our websites are responsive, ensuring they look fantastic and function flawlessly across all devices." },
        { question: "How long does it take to develop a website?", answer: "The development timeline depends on the complexity of your project. However, we'll provide a clear timeline during the initial consultation." },
        { question: "How will I maintain my website after launch?", answer: "We offer ongoing maintenance and support plans to ensure your website remains secure, up-to-date, and functioning optimally." },
    ];

    // Our Process
    const [service, setservice] = useState([
        { title: "Plan", op: "+", content: "Strategize project goals and requirements to set a clear development roadmap.", isOpen: false },
        { title: "Build", op: "+", content: "Develop robust and scalable web solutions using the latest technologies.", isOpen: false },
        { title: "Secure", op: "+", content: "Implement comprehensive security measures to protect your website and data.", isOpen: false },
        { title: "Test", op: "+", content: "Conduct extensive testing to ensure functionality, performance, and compatibility.", isOpen: false },
        { title: "Deploy", op: "+", content: "Launch your web application with precision for a smooth and effective release.", isOpen: false },
        { title: "Optimise", op: "+", content: "Enhance performance and speed to provide the best user experience.", isOpen: false },
        { title: "Monitor", op: "+", content: "Continuously track your website's performance and health.", isOpen: false },
        { title: "Adapt", op: "+", content: "Respond to user feedback and changing requirements with agile adjustments.", isOpen: false },
        { title: "Evolve", op: "+", content: "Keep your web solution ahead of the curve through ongoing improvements and innovations.", isOpen: false },
    ]);

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <Service4_banner title="Web Development" />
            <div className="paras4">
                <h1>At Binary Geckos, web development is more than just coding. We craft beautiful, functional websites that are secure, scalable, and deliver exceptional user experiences.</h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="Our meticulous process ensures a smooth journey, from planning and building to deployment and ongoing optimization." hashtagall={hashtagall} />
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Websites Built to Climb: Secure, scalable solutions that elevate your brand." 
            title2="Gecko-Grip Development: Websites that stick with your users (and Google)." image={imagesui} />
            <ServiceQuestion accordions={accordions} />
        </div>
    );
}

export default D_WebDevelopment;

import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'
import images from '../../constants/images'

const MarketingAutomation = () => {
    const imagesui = images.marketing_automation;
    const hashtagall = [
        { hashtagname: "#MarketingAutomation" },
        { hashtagname: "#EmailMarketing" },
        { hashtagname: "#Automation" },
        { hashtagname: "#DigitalMarketing" },
        { hashtagname: "#MarketingTech" },
        { hashtagname: "#CRM" },
        { hashtagname: "#MarketingStrategy" },
    ];

    const accordions = [
        { question: "What is performance marketing and how does it differ from traditional marketing?", answer: "Performance marketing focuses on measurable results and ROI. You only pay for actions taken, such as clicks, conversions, or leads generated. Traditional marketing may not provide such direct attribution of results." },
        { question: "What performance marketing channels do you offer?", answer: "We have expertise in various channels, including Search Engine Marketing (SEM), Pay-Per-Click (PPC) advertising, Social Media Advertising, Display Advertising, and Affiliate Marketing. We'll recommend the best mix of channels to achieve your specific goals." },
        { question: "How do you optimize landing pages for conversions?", answer: "We design and A/B test landing pages to ensure they are user-friendly, informative, and strategically designed to convert visitors into leads or customers." },
        { question: "What is attribution modeling and why is it important?", answer: "Attribution modeling helps us understand the customer journey and pinpoint which touchpoints (website visits, ads, social media interactions) contribute most to conversions. This allows us to optimize campaigns and allocate budgets effectively." },
        { question: "How will I be kept informed about the performance of my campaigns?", answer: "We provide transparent reporting with key metrics like clicks, impressions, conversions, and ROI. We regularly analyze data and discuss insights with you to ensure your campaigns are on track." },
        { question: "Can you help me scale my successful performance marketing campaigns?", answer: "Absolutely! We use data-driven insights to identify what works best and scale your campaigns for maximum impact and reach." },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            { title: "Marketing Automation Platform Selection & Integration", op: "+", content: "Choose and integrate the best marketing automation platform for your needs.", isOpen: false },
            { title: "Customer Journey Mapping & Segmentation", op: "+", content: "Map out customer journeys and segment your audience for personalized marketing.", isOpen: false },
            { title: "Workflow Design & Automation Development", op: "+", content: "Design workflows and develop automation processes to streamline marketing efforts.", isOpen: false },
            { title: "Email Marketing Automation & Campaign Creation", op: "+", content: "Automate email marketing campaigns for efficient lead nurturing and communication.", isOpen: false },
            { title: "Landing Page & Lead Capture Form Creation", op: "+", content: "Create optimized landing pages and lead capture forms to boost conversions.", isOpen: false },
            { title: "Lead Nurturing & Automated Communication", op: "+", content: "Implement automated communication strategies to nurture leads effectively.", isOpen: false },
            { title: "Sales & Marketing Alignment Integration", op: "+", content: "Align sales and marketing efforts for a cohesive strategy.", isOpen: false },
            { title: "Marketing Automation Performance Tracking & Analytics", op: "+", content: "Track and analyze the performance of your marketing automation efforts.", isOpen: false },
            { title: "Ongoing Optimization & Refinement", op: "+", content: "Continuously optimize and refine automation processes for better results.", isOpen: false }
        ])

    const toggleService = (index) => {
        setservice(service.map((service, i) => (
            i === index ? { ...service, isOpen: !service.isOpen, op: service.isOpen ? "+" : "-" } : service
        )));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" })
    }, [])
    return (
        <div>
            <Service4_banner title="Marketing Automation" />
            <div className="paras4"><h1>
                Stop juggling tasks, start climbing towards success. At Binary Geckos, we're your marketing automation ninjas. We help you leverage powerful marketing automation tools to streamline workflows, personalize customer experiences, and propel your marketing efforts to new heights.
            </h1>
            </div>
            <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step marketing automation odyssey:"  hashtagall={hashtagall}/>
            <Service2_Boost />
            <Casestudy />
            <ServiceBranding title1="Beyond the Blast: We Automate Your Marketing Journey to Success."
             title2="Effortless Efficiency: Binary Geckos Gets More Done with Marketing Automation."
             image={imagesui} />
            <ServiceQuestion accordions={accordions} />

        </div>
    )
}

export default MarketingAutomation
import React from 'react'
import "./cs_phonedesign.css"
import images from '../../constants/images'

const CS_PhoneDesign = ({img}) => {
  return (
    <div className='cs_phone_design_main_wrapp'>
        <img src={img} alt="" />
    </div>
  )
}

export default CS_PhoneDesign
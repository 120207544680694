import React, { useEffect, useState } from 'react'
import Service4_banner from '../../container/Service4_banner/Service4_banner'
import "./Subservice.css"
import Oursevices_s4 from '../../container/Oursevices_s4/Oursevices_s4'
import Casestudy from '../../container/casestudy/Casestudy'
import Service2_Boost from '../../container/Service2_Boost/Service2_Boost'
import ServiceBranding from '../../container/serviceBranding/ServiceBranding'
import ServiceQuestion from '../../container/ServiceQuestion/ServiceQuestion'

const WebDevelopment = () => {
    // FAQ
    const accordions = [
        { question: "What types of websites do you develop?", 
        answer: "We create a wide range of websites, from simple brochure websites to complex e-commerce platforms and custom web applications." },
        { question: "Do I need to have a web design before development starts?",
         answer: "While a finalized design is ideal, we can collaborate with you to develop your website design alongside the development process." },
        { question: "What technologies do you use for web development?", 
        answer: "We leverage cutting-edge technologies like HTML5, CSS3, JavaScript frameworks (React, Angular), and various CMS platforms (WordPress, Drupal) to ensure your website is secure, scalable, and performs flawlessly." },
        { question: "What is a Content Management System (CMS)?", 
        answer: "A CMS allows you to easily edit and update your website content without needing coding expertise. We'll recommend the best CMS for your specific needs." },
        { question: "How important is website security?", 
        answer: "Website security is paramount. We prioritize secure coding practices and stay updated on the latest security threats to ensure your website is protected." },
        { question: "Do you offer ongoing website maintenance and support?", 
        answer: "Absolutely! We offer ongoing maintenance plans to ensure your website stays up-to-date, secure, and functions optimally." },
    ];

    // Our Process
    const [service, setservice] = useState(
        [
            {title: "Project Kickoff & Discovery", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false},
            {title: "Target Audience & User Journey Mapping", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false},
            {title: "Website Architecture & Information Planning", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false},
            {title: "Wireframing & Prototyping", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false},
            {title: "Design Integration & Brand Consistency", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false},
            {title: "Front-End Development & Functionality", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false},
            {title: "Content Management System (CMS) Integration", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false},
            {title: "Rigorous Testing & Quality Assurance", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false},
            {title: "Launch & Ongoing Support", op: "+", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.", isOpen: false}
        ])

        const toggleService = (index) => {
            setservice(service.map((service, i) => (
                i === index ? { ...service, isOpen: !service.isOpen,op: service.isOpen ? "+" : "-"  } : service
            )));
        };  

        useEffect(() => {
            window.scrollTo({top: 0,behavior: "instant"})
           }, [])
  return (
    <div>
        <Service4_banner title="Web Development:"  />
        <div className="paras4"><h1>
        We build websites that don't just exist, they thrive. At Binary Geckos, we're your web development alchemists, transforming ideas into beautiful, functional websites that empower your brand and captivate your audience.
          </h1>
        </div>
        <Oursevices_s4 service={service} toggleService={toggleService} title="our 9-step web development process:"/>
        <Service2_Boost />
        <Casestudy />
        <ServiceBranding title1="Beyond the Code: We Build Websites that Convert." title2="Crafted for Performance: Websites that Climb the Ranks."/>
        <ServiceQuestion  accordions={accordions}/>

    </div>
  )
}

export default WebDevelopment
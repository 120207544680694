import React from 'react'
import "./cs_designsystem.css"
import images from '../../constants/images'
import { FaCaretDown } from 'react-icons/fa6'

const CS_DesignSystem = () => {
  return (
    <div className='cs_ds_main_wrapp'>
        <div className='cs_ds_base_wrapp'>
            <h1>Design <span>system</span></h1>
            <div className='cs_ds_content_div'>
                <div className='cs_ds_flexrow1'>
                    <div className='cs_50'>
                        <div className="about_nl_input_wrapp">
                            <form className="news-form">
                                <div className="news_letter_form">
                                <div className="about_nl_input_box_wrapp">
                                    <img src={images.mail_usa} alt="mail" />
                                    <input
                                    placeholder="Enter your email"
                                    className="about_nl_input_box"
                                    />
                                </div>
                                <button className="about_nl_submit_btn" >Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='cs_50'>
                        <div className='cs_25_n'>
                            <button className='apply-trans-btn-usa'>
                                Apply Now
                            </button>
                        </div>
                        <div className='cs_25_n'>
                            <button className='inquiry-btn-usa'>
                                General Inquiry
                            </button>
                        </div>
                    </div>
                </div>
                <div className='cs_ds_flexrow1'>
                    <div className='d-flex-group-35'>
                        <div className="custom-dropdown">
                            <div className="dropdown-header">
                                <div className="selected-country">
                                
                                    <img src={images.us_flag}  className="country-logo" />
                                    <span className="country-name">En</span>
                                    <FaCaretDown size={20} color={"#02245b"} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className='apply-blue-btn-usa'>Apply Now</button>
                        </div>
                    </div>
                    <div>
                        <button className='apply-btn-usa'>Apply Now</button>
                    </div>
                    <div className='d-flex-group-35'>
                        <div className="input-btn-div-usa">
                            <form className="join-form">
                                <input 
                                    placeholder="email address" 
                                />
                                <button >Join Now</button>
                            </form> 
                        </div>
                    </div>
                </div>
                <div className='cs_ds_flexrow1'>
                    <div className='cs_50'>
                        <div className="contact-sec2-email-us">
                            <div className="circle-us">
                                <img src={images.msg_notif} alt="msg_notification" />
                            </div>
                            <div className="main-content">
                            <p className="email-us">Email Address</p>
                            <p className="content-us">
                               info@usaintern.club
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className='cs_50'>
                        <div className="contact-sec2-call-us">
                            <div className="circle-us">
                                <img src={images.call_logo} alt="msg_notification" />
                            </div>
                            <div className="main-content">
                            <p className="email-us">Phone Number</p>
                            <p className="content-us">
                               info@usaintern.club
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cs_ds_flexrow1'>
                    <div className='cs_50'>
                        <div className="contact-sec2-skype-us">
                            <div className="circle-us">
                                <img src={images.skype_notif} alt="msg_notification" />
                            </div>
                            <div className="main-content">
                            <p className="email-us">Phone Number</p>
                            <p className="content-us">
                               info@usaintern.club
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default CS_DesignSystem